<template>
  <!-- eslint-disable max-len -->
  <div>
    <b-container>
      <StoryHeader />
    </b-container>
    <b-container>
      <b-row class="mt-4">
        <b-col>
          <p>
            Today’s global challenges of climate change, biodiversity loss, widening social disparities, and health crises need to be addressed, now and in the future, through global strategies and locally adapted solutions. In 2015, the United Nations adopted the 2030 Agenda with its 17
            <strong>Sustainable Development Goals (SDGs)</strong>, which are a universal call to action to end poverty, protect the planet, and improve the lives and prospects of everyone, everywhere. To achieve the SDGs,
            <strong>Education for Sustainable Development (ESD)</strong> can play a crucial role. ESD can foster the competences required to grasp the complexity of the SDGs and how they interact. ESD empowers individuals to define and carry out their own contributions to achieving the aims of
            <strong>Sustainable Development (SD)</strong> at various levels, from local to global.
          </p>
          <p>
            The following sections present different models, aims, and concepts relevant to the understanding of SD and ESD. The models serve as enablers for subject teachers and other stakeholders to understand the connection of their subjects/fields of interest with SD, identify their competences within ESD, and develop their own contributions to SD and ESD.
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <h2 class="section-header">1 Understanding Sustainable Development</h2>
          <p>
            <strong>Development</strong> in this context means transformation towards a certain goal – a goal that needs to be negotiated among individuals and within society. Development is thus a normative term: it describes a societal process towards a specific target which – to be broadly accepted and implemented – must be continually negotiated within society. Norms are constantly changing and due to their embedment in social, cultural, historic, and geographic contexts, they differ between societies. Development priorities therefore vary along these contexts and require societal negotiations and political decisions. The key questions are: What type of development do we want, and how can we design transformative processes so that people can have a say?
          </p>
          <p>
            Since the Brundtland Commission published its report in 1987, the term Sustainable Development has been widely used and further developed. Many definitions focus on the three – equal – target dimensions of society, environment, and economy. Such definitions often neglect, however, the important dimensions of space and time, which can be incorporated as follows:
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1">
          &nbsp;
        </b-col>
        <b-col cols="10">
          <b-card bg-variant="light" text-variant="black">
            <blockquote class="card-blockquote">
              <p>"Sustainable Development is a global, societal, and democratic search, learning, and shaping process. In this process, trade-offs are negotiated under consideration of inter- and intra-generational sociocultural and economic justice, while simultaneously respecting the environmental limits of natural resource use at local, regional, and global levels."</p>
              <footer>
                <small>adapted from <cite title="Source Title">Herweg et al. 2017</cite></small>
              </footer>
            </blockquote>
          </b-card>
        </b-col>
        <b-col cols="1">
          &nbsp;
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <p>
            Sustainable Development is not a harmonious concept. Conflicts between economic, social, and environmental targets must be addressed by society and policy. The main challenge is to understand how different needs and targets interact and constrain each other, so that if alignment is not possible, compromises can be found. Target conflicts need to be addressed at international, national, and local levels and negotiated in fair, inclusive, and participatory processes. These positions, negotiated between policy and society, can be categorized in terms of either weak or strong sustainability (Fig. 1).
          </p>
          <b-img
              center
              src="@/assets/blog/understanding_sd/images/SD-fig1.svg"
              alt="Comparison of weak and strong sustainability"
              v-bind="defaultImgProps"
              style="width: 80%"
          ></b-img>
          <div class="caption-dark">
          Figure 1: Continuum of positions of weak and strong sustainability, from Probst and Piller, 2019; modified after Pufé, 2014
          </div>
        </b-col>
      </b-row>
      <b-row class="my-5">
        <b-col>
          <p>We will explore the <strong>continuum of positions of weak and strong Sustainable Development</strong> using three models of SD.</p>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col cols="6 align-self-center">
          <p>
            In the <strong>model of weak SD</strong> (Figure 2) the needs of present and future generations (temporal axis) are fulfilled within the three dimensions environment, society, and economy at local, regional, and global scale (spatial axis). The model assumes that the three dimensions are equal and that the economy can operate without links to society and environment, e.g. that shortages in “natural capital” can be substituted with increases in “human capital”.
          </p>
        </b-col>
        <b-col cols="6">
          <b-img
              center
              src="@/assets/blog/understanding_sd/images/SD-fig2.svg"
              alt="Model of weak sustainability"
              v-bind="defaultImgProps"
          ></b-img>
          <div class="caption-dark">
          Figure 2: Model of weak Sustainable Development
          </div>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col cols="6 align-self-center">
          <p>
            The <strong>model of strong SD</strong> (Figure 3), in contrast, assumes that the three dimensions of environment, society, and economy are complementary, that all three dimensions have their limits, and that without environment there is no society and without society there is no economy, e.g. “natural capital” is not interchangeable with “human capital”. In the model of strong SD, the economy thus supports society to maintain the performance of society and environment.
          </p>
        </b-col>
        <b-col cols="6">
          <b-img
              center
              src="@/assets/blog/understanding_sd/images/SD-fig3.svg"
              alt="Model of strong sustainability"
              v-bind="defaultImgProps"
          ></b-img>
          <div class="caption-dark">
          Figure 3: Model of strong Sustainable Development
          </div>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <p>
            The <strong>doughnut model</strong> (Figure 4) put forward by economist Kate Raworth (2012 and 2017) reflects strong sustainability and considers the safe and just space for humanity (light green ring) to lie within an ecological ceiling (outer dark green ring) and social foundations (inner dark green ring). A space is safe for humanity if we do not exceed the planetary boundaries denoted by the ecological ceiling (outer red ring; hover over the doughnut to see it); a space is just when basic needs can be met within the limits of the planet and all people achieve a minimum level of well-being (inner red ring). For example, a safe and just space for humanity requires freshwater management to maintain human well-being in terms of food security, access to water, health, and equality. This model has advantages for educational settings, as it offers concrete entry points for a variety of topics. For example, the concept of a planetary boundary links to natural science subjects, while the social and economic dimensions and the design of a safe and just space for humanity links to social sciences and humanities.
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <donut-diagram></donut-diagram>
    </b-container>
    <b-container>
      <b-row>
        <b-col>
          <p>
            Entry points for subject teachers and other stakeholders are also offered by the 2030 Agenda, with its
            <strong>17 Sustainable Development Goals (SDGs)</strong> (Figure 5) and 169 targets. Adopted by the UN member states for the period of 2015–2030, the SDGs replaced the Millennium Development Goals of 2000–2015 and signal the need and will for global partnerships and solidarity. The 2030 Agenda serves as a framework and a compass for all countries, to ensure that the future basic needs of all people are met, and that a sustainable, peaceful, prosperous, and just life is possible for all. Considering the interactions between the SDGs is important: implementing one SDG can lead to
            <strong>trade-offs or synergies</strong> with other SDGs, with positive or negative consequences.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-img
              center
              src="@/assets/blog/understanding_sd/images/SD-fig5.png"
              alt="Overview of the 17 SDGs"
              v-bind="defaultImgProps"
              style="width: 70%"
          ></b-img>
          <div class="caption-dark">
            Figure 5: Goals of the 2030 Agenda for Sustainable Development
          </div>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          Sustainable Development as a vision and guiding idea for society as a whole requires
          <strong>actions and implementation at different levels</strong>: individual and societal, as well as local, regional, national, and global (see Figure 6). Negotiating and implementing SD triggers learning processes that sensitize the involved actors. As people subsequently adapt their behaviour, changes occur in individual and societal patterns of rules and actions. If conflicts of interest arise, patterns can be adapted through political and social negotiation processes. Individuals and society thus develop and adapt their values, norms, and perceptions, which lead to actions at local, national, and international levels. The model proposed in Figure 6 also offers educators a variety of entry points for SD by considering possible actions of individuals and society at local level (e.g. business, schools, families), regional and national levels (e.g. villages, communities), or international level (e.g. UN). The model can also be used to consider bottom-up (e.g. local level) and top-down (e.g. international level) transformation processes and their effects.
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-img
              center
              src="@/assets/blog/understanding_sd/images/SD-fig6.svg"
              alt="Model for implementation of sustainable development at different levels"
              v-bind="defaultImgProps"
              style="width: 80%"
          ></b-img>
          <div class="caption-dark">
            Figure 6: Model for implementation of Sustainable Development at different levels, modified after Probst and Piller, 2019
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <h2 class="section-header">2 Understanding Education for Sustainable Development</h2>
          <p>
            Education for Sustainable Development aims at empowering students to contribute to the design of sustainable futures. It helps them to understand how the subjects they are studying relate to SD, using models and concepts such as those provided here. UNESCO, which leads and coordinates the Education 2030 Agenda, provides the following definition of ESD:
          </p>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col cols="1">
          &nbsp;
        </b-col>
        <b-col cols="10">
          <b-card bg-variant="light" text-variant="black">
            <blockquote class="card-blockquote">
              <p>“ESD aims at developing competencies that empower individuals to reflect on their own actions, taking into account their current and future social, cultural, economic and environmental impacts, from a local and a global perspective. Individuals should also be empowered to act in complex situations in a sustainable manner, which may require them to strike out in new directions; and to participate in socio-political processes, moving their societies towards Sustainable Development. ESD has to be understood as an integral part of quality education, inherent in the concept of lifelong learning: All educational institutions – from preschool to tertiary education and in non-formal and informal education – can and should consider it their responsibility to deal intensively with matters of Sustainable Development and to foster the development of sustainability competencies. ESD provides an education that matters and is truly relevant to every learner in the light of today’s challenges.”</p>
              <footer>
                <small><cite title="Source Title">UNESCO 2017, p.7</cite></small>
              </footer>
            </blockquote>
          </b-card>
        </b-col>
        <b-col cols="1">
          &nbsp;
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <p>
            Our world faces many challenges: increasing complexity and uncertainty, more individualism and social diversity, growing economic and cultural uniformity, increasing global disparities and inequalities, degradation of ecosystem services, and increasing vulnerability and exposure to natural and technological hazards. Against this backdrop, people must learn to understand the complex world they live in. They need to be able to work together, to express their opinions, to deal with moral and normative-ethical questions, and to engage constructively and responsibly for positive change. To do this, they require certain key competences to deal with today’s complex challenges. These competences are relevant for all SDGs and enable individuals to relate the different SDGs to each other, to understand their embedment in the 2030 Agenda for Sustainable Development.
          </p>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <p>
            <strong>UNESCO has defined the following key competences for sustainability (UNESCO 2017, p.10):</strong>
          </p>
          <ul>
            <li>
              <em>Systems thinking competency:</em> the abilities to recognize and understand relationships; to analyze complex systems; to think of how systems are embedded within different domains and different scales; and to deal with uncertainty.
            </li>
            <li>
              <em>Anticipatory competency:</em> the abilities to understand and evaluate multiple futures – possible, probable and desirable; to create one’s own visions for the future; to apply the precautionary principle; to assess the consequences of actions; and to deal with risks and changes.
            </li>
            <li>
              <em>Normative competency:</em> the abilities to understand and reflect on the norms and values that underlie one’s actions; and to negotiate sustainability values, principles, goals, and targets, in a context of conflicts of interests and trade-offs, uncertain knowledge and contradictions.
            </li>
            <li>
              <em>Strategic competency:</em> the abilities to collectively develop and implement innovative actions that further sustainability at the local level and further afield.
            </li>
            <li>
              <em>Collaboration competency:</em> the abilities to learn from others; to understand and respect the needs, perspectives and actions of others (empathy); to understand, relate to and be sensitive to others (empathic leadership); to deal with conflicts in a group; and to facilitate collaborative and participatory problem solving.
            </li>
            <li>
              <em>Critical thinking competency:</em> the ability to question norms, practices and opinions; to reflect on own one’s values, perceptions and actions; and to take a position in the sustainability discourse.
            </li>
            <li>
              <em>Self-awareness competency:</em> the ability to reflect on one’s own role in the local community and (global) society; to continually evaluate and further motivate one’s actions; and to deal with one’s feelings and desires.
            </li>
            <li>
              <em>Integrated problem-solving competency:</em> the overarching ability to apply different problem-solving frameworks to complex sustainability problems and develop viable, inclusive and equitable solution options that promote Sustainable Development, integrating the above-mentioned competences.
            </li>
          </ul>
          <p>
            The spider diagram (Figure 7) enables you to analyze, reflect on, and further develop communication and teaching materials according to the eight competences for sustainability.
          </p>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-img
              center
              src="@/assets/blog/understanding_sd/images/SD-fig7.svg"
              alt="Spider diagram for the rating of key competences of sustainability"
              v-bind="defaultImgProps"
              style="width: 50%"
          ></b-img>
          <div class="caption-dark">
            Figure 7: Spider diagram for the rating of key competences of sustainability for education/curricula or textbooks.
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <h2 class="section-header">3 Integrating Education for Sustainable Development in curricula</h2>
          <p>
            The scientific models presented above can help interested actors, teachers, and specialists to identify skills-based links between their topic and SD, and also to develop their own contributions to SD and ESD. ESD can be implemented in curricula using various approaches. Three suggestions are given below, based on teaching experiences in both primary and secondary schools as well as in universities.
          </p>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <ol>
            <li>
              Identify teaching content
                <ul>
                  <li>
                    Identify the connection between the content of your discipline/topic and SD (using Figures 1-6)
                  </li>
                  <li>
                    Identify the methodological connection between your discipline/topic and SD
                  </li>
                  <li>
                    Categorise your SD-related content/methods into systems, target, and transformation knowledge
                  </li>
                </ul>
            </li>
            <li>
              Develop learning opportunities on Sustainable Development
                <ul>
                  <li>
                    Develop a skills-based and disciplinary learning opportunity related to SD, on your discipline/topic
                  </li>
                  <li>
                    Define disciplinary learning aims and competences within the SD topic and identify key sustainability competences in relation to the topic
                  </li>
                </ul>
            </li>
            <li>
              Ensure state-of-the-art ESD
                <ul>
                  <li>
                    Stay up to date on scientific findings, concepts, and models on SD
                  </li>
                  <li>
                    Continuously develop learning opportunities on SD, i.e. adapting the topic, responding to students, and taking into account disciplinary advancements.
                  </li>
                  <li>
                    Adapt SD-related teaching materials to changing conditions (e.g. to government policies, or goals of the United Nations)
                  </li>
                </ul>
            </li>
          </ol>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="mt-3 bg-dark text-white pt-0">
        <b-container class="py-5 my-0">
            <b-row class="text">
                <b-col>
                  <!-- Section End Matter -->
                  <details>
                    <summary>References</summary>
                      <div>
                        <a href="https://www.education21.ch/sites/default/files/uploads/150127_fub_positionspapier_web_def.pdf" target="_blank" rel="noopener noreferrer" class="dark-link">Fachkonferenz Umweltbildung (2014). Positionspapier.</a>
                        <br>
                        <a href="https://boris.unibe.ch/105009/1/online_2017_Guidelines_University%20of%20Bern.pdf" target="_blank" rel="noopener noreferrer" class="dark-link">Herweg et al. (2017). Integrating Sustainable Development into Higher Education.</a>
                        <br>
                        Probst M., Piller F., (2019): Nachhaltige Entwicklung. In: <a href="https://www.hep-verlag.ch/geografie" target="_blank" rel="noopener noreferrer" class="dark-link">Egli HR., Hasler M., Probst M., (2019): Geografie. Wissen und verstehen. Ein Handbuch für die Sekundarstufe II. 5. Auflage. Hep-Verlag, Bern.</a>
                        <br>
                        <a href="https://www-cdn.oxfam.org/s3fs-public/file_attachments/dp-a-safe-and-just-space-for-humanity-130212-en_5.pdf" target="_blank" rel="noopener noreferrer" class="dark-link">Raworth K, (2012): A safe and just space for humanity. Can we live within the doughnut? Oxfam Discussion Papers.</a>
                        <br>
                        <a href="https://www.thelancet.com/action/showPdf?pii=S2542-5196%2817%2930028-1"  target="_blank" rel="noopener noreferrer" class="dark-link">Raworth, K. (2017) A Doughnut for the Anthropocene: humanity’s compass in the 21st century.</a>
                        <br>
                        Rieckmann, M. (2018). Chapter 2 – Learning to transform the world: key competencies in ESD. In: <a href="https://en.unesco.org/sites/default/files/issues_0.pdf" target="_blank" rel="noopener noreferrer" class="dark-link">A. Leicht, J. Heiss & W. J. Byun (Hrsg.), Issues and trends in Education for Sustainable Development (S. 39–59). Paris: UNESCO.</a>
                        <br>
                        <a href="https://sdgs.un.org/2030agenda" target="_blank" rel="noopener noreferrer" class="dark-link">United Nations General Assembly (2015): 70/1. Transforming our world: the 2030 Agenda for Sustainable Development</a>
                        <br>
                        <a href="http://unesdoc.unesco.org/images/0023/002325/232555e.pdf"  target="_blank" rel="noopener noreferrer" class="dark-link">United Nations Educational, Scientific and Cultural Organization [UNESCO] (2015): Rethinking Education. Towards a global common good</a>
                        <br>
                        <a href="https://www.education21.ch/sites/default/fi-les/uploads/pdf-d/bne/bne-dossiers/2015_UNESCO_BNE-Roadmap_de.pdf"  target="_blank" rel="noopener noreferrer" class="dark-link">United Nations Educational, Scientific and Cultural Organization [UNESCO] (2014). Roadmap zur Umsetzung des Weltaktionsprogramms «Bildung für nachhaltige Entwicklung». Bonn: Deutsche UNESCO-Kommission e.V. [DUK].</a>
                        <br>
                        <a href="https://www.unesco.ch/wp-content/uploads/2017/01/Learning-objectives.pdf" target="_blank" rel="noopener noreferrer" class="dark-link">United Nations Educational, Scientific and Cultural Organization [UNESCO] (2017). Education for Sus-tainable Development Goals. Learning Objectives. Paris: UNESCO.</a>
                      </div>
                  </details>
                  <details>
                    <summary>Further reading</summary>
                      <div>
                        <a href="https://link.springer.com/article/10.1007/s11159-010-9157-9" target="_blank" rel="noopener noreferrer" class="dark-link">de Haan, G. (2010). The development of ESD-related competencies in supportive institutional frame-works. International Review of Education, 56, 315-328.</a>
                        <br>
                        Rychen, D. S. (2003). Key competencies: Meeting important challenges in life. In: <a href="https://www.hogrefe.com/de/shop/key-competencies-for-a-successful-life-and-a-well-functioning-society-67288.html" target="_blank" rel="noopener noreferrer" class="dark-link">D. S. Rychen & L. H. Salganik (Hrsg.), Key competencies for a successful life and well-functioning society (S. 63–107). Cambridge/ MA: Hogrefe and Huber.</a>
                        <br>
                        <a href="https://en.unesco.org/globalactionprogrammeoneducation" target="_blank" rel="noopener noreferrer" class="dark-link">United Nations Educational, Scientific and Cultural Organization [UNESCO] (2019a). Global Action Programme on Education for Sustainable Development (2015-2019).</a>
                        <br>
                        <a href="https://www.oneplanetnetwork.org/sites/default/files/40_c23_frame-work_for_the_implementation_of_esd_beyond_2019.pdf" target="_blank" rel="noopener noreferrer" class="dark-link">United Nations Educational, Scientific and Cultural Organization [UNESCO] (2019b). Framework for the implementation of education for sustainable development (ESD) beyond 2019. Paris: UNE-SCO.</a>
                        <br>
                        <a href="https://arjenwals.files.wordpress.com/2016/02/8412100972_rvb_inauguratie-wals_oratieboekje_v02.pdf" target="_blank" rel="noopener noreferrer" class="dark-link">Wals, A. E. J. (2015). Beyond unreasonable doubt. Education and learning for socio-ecological sustainability in the Anthropocene.</a>
                        <br>
                        Wals, A. E. J. & Lenglet, F. (2016). Sustainability citizens: Collaborative and disruptive social learning. In: <a href="https://www.routledge.com/Sustainability-Citizenship-in-Cities-Theory-and-practice/Horne-Fien-Beza-Nelson/p/book/9781138933637" target="_blank" rel="noopener noreferrer" class="dark-link">R. Horne, J. Fien, B. B. Beza & A. Nelson (Hrsg.), Sustainability Citizenship in Cities: Theory and Practice (S. 52–67). London: Routledge.</a>
                        <br>
                        Weinert, F. E. (2001). Concept of Competence: A Conceptual Clarification. In: D. S. Rychen & L. H. Salganik (Hrsg.), Defining and Selecting Key Competencies (S. 45–65). Seattle, Toronto, Bern & Göttingen: Hogrefe und Huber.
                        <br>
                        <a href="https://link.springer.com/article/10.1007/s11625-011-0132-6" target="_blank" rel="noopener noreferrer" class="dark-link">Wiek, A., Withycombe, L. & Redman, C. L. (2011): Key competencies in sustainability: a reference framework for academic program development. Sustainability Science, 6(2), 203–218.</a>
                        <br>
                        Wiek, A., Bernstein, M. J., Foley, R. W., Cohen, M., Forrest, N., Kuzdas, C., Kay, B. & Withycombe Keeler, L. (2016): Operationalising competencies in higher education for sustainable development. In: <a href="https://www.routledge.com/Routledge-Handbook-of-Higher-Education-for-Sustainable-Development/Barth-Michelsen-Rieckmann-Thomas/p/book/9781138597549" target="_blank" rel="noopener noreferrer" class="dark-link">M. Barth, G. Michelsen, I. Thomas & M. Rieckmann (Hrsg.), Routledge Handbook of Higher Education for Sustainable Development (S. 241–260). London: Routledge.</a>
                        <br>
                        Rieckmann, M. (2019). Beiträge zu einer Bildung für nachhaltige Entwicklung zum Erreichen der Sustainable Development Goals – Perspektiven, Lernziele und Forschungsbedarfe. In: <a href="https://shop.budrich.de/produkt/reihe-schriftenreihe-oekologie-und-erziehungswissenschaft-der-kommission-bildung-fuer-nachhaltige-entwicklung-der-deutschen-gesellschaft-fuer-erziehungswissenschaft-dgfe/?lang=en" target="_blank" rel="noopener noreferrer" class="dark-link">I. Clemens, S. Hornberg, & M. Rieckmann (Hrsg.), Schriftenreihe. Ökologie und Erziehungswissenschaft der Kommission Bildung für nachhaltige Entwicklung der DGfE (S. 79-94). Opladen: Barbara Budrich.</a>
                        <br>
                        <a href="http://blog.dvpb.de/wp-content/uploads/2015/03/POLIS4_2013_Schluesselkompetenzen_fuer_eine_nachhaltige_Entwicklung.pdf" target="_blank" rel="noopener noreferrer" class="dark-link">Rieckmann, M. (2013). Schlüsselkompetenzen für eine nachhaltige Entwicklung. POLIS, 2013(4), 11–14.</a>
                        <br>
                        <a href="https://openjournals.wu.ac.at/ojs/index.php/socience/article/view/136" target="_blank" rel="noopener noreferrer" class="dark-link">Rieckmann, M. & Schank, C. (2016). Sozioökonomisch fundierte Bildung für nachhaltige Entwicklung – Kompetenzentwicklung und Werteorientierungen zwischen individueller Verantwortung und struktureller Transformation. SOCIENCE, 1(1), 65–79.</a>
                      </div>
                  </details>
                  <h5 style="margin-top: 3rem; margin-bottom: 1rem">Credits</h5>
                  <p>Text: Matthias Probst and Deborah Niggli (Authors), Laura Ebneter and Caroline Brönnimann (review)</p>
                  <p>Translation: Kaspar Hurni</p>
                  <p>Editing: Tina Hirschbuehl</p>
                  <p>Production: Manuel Abebe and Nicola Jordan</p>
                  <small>Title image: Unsplash / taypaigey</small>
                  <br>
                  <small>Icons figure 6: Noun project / b farias; Dolly Holmes; kareemov</small>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
  </div>
</template>

<script>
import DonutDiagram from './DonutDiagram.vue';
import StoryHeader from './StoryHeader.vue';

export default {
  components: {
    DonutDiagram,
    StoryHeader,
  },
  data() {
    return {
      defaultImgProps: {
        fluid: true,
      },
    };
  },

};
</script>

<style lang="scss" scoped>
.section-header {
  font-style: italic;
  font-weight: bold;
  font-size: 16pt;
  color: #505c66;
}

.card-blockquote {
  margin-bottom: 0;
}

.caption {
  color: #ddd;
  font-size: 10pt;
}

.caption-dark {
  color: #111;
  font-size: 10pt;
  text-align: center;
}

.dark-link {
  color: #ddd;
  text-decoration: underline;
  text-decoration-color: #e1a578;
}

.dark-link:hover {
  text-decoration-thickness: 1.5pt;
}

.dark-link:visited {
  color: #bbb;
}

details {
  border: solid 1px #666;
  padding: 1rem;
  margin-bottom: 1rem;
}
details div {
  margin-top: 2rem;
}

</style>
