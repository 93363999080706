import { isFunction } from './function-utils';

export function noop() {
}

export function not(callable) {
  const fn = isFunction(callable) ? callable : noop;
  return function (...args) {
    return !fn.apply(this, args);
  };
}

export function isUndef(toCheck) {
  return typeof (toCheck) === 'undefined';
}

export function isNull(toCheck) {
  return toCheck === null;
}

export function isNullOrUndef(toCheck) {
  return isNull(toCheck) || isUndef(toCheck);
}
