<template>
  <div>
    <l-wms-tile-layer
      v-if="item.wms && item.wms.tiles_url && item.showDataOnMap"
      :id="item.id"
      :tile-size="tileSize"
      :layers="layers"
      :style="style"
      :format="format"
      :layer-type="layerType"
      :base-url="tilesURL"
      :name="layerName"
      :opacity="opacity"
      :crs="crs"
      :visible="visible"
      :version="version"
      transparent
    />
    <LGeoJson
      v-if="!item.wms && item.geographic_location && item.showDataOnMap"
      :id="item.id"
      :visible="visible"
      :geojson="item.geographic_location"
      :options="popupOptions"
      @click="showGeoJsonPopupInfo"
    />
  </div>
</template>
<script>
import {
  LWMSTileLayer,
  LGeoJson,
} from 'vue2-leaflet';
import {
  CRS,
} from 'leaflet';

export default {
  name: 'GisDataMapLayer',
  components: {
    'l-wms-tile-layer': LWMSTileLayer,
    LGeoJson,
  },
  data() {
    return {
      opacity: 0.6,
      popup: null,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    extentColor: {
      // if true, show the actual data, else just show the region
      // where the data is located
      type: String,
      default: undefined,
    },
    options: {
      default: undefined,
    },
    leafletMapObject: {
      required: false,
      type: Object,
      default: null,
    },
  },
  methods: {
    showGeoJsonPopupInfo({ layer }) {
      layer.togglePopup();
    },
  },
  computed: {
    layerName() {
      return this.item.title || 'No Name';
    },
    popupOptions() {
      return {
        onEachFeature: (feature, layer) => {
          const htmlPopupContent = `<p>${this.item.title}</p>`;
          layer.bindPopup(htmlPopupContent);
          // this.$nextTick(() => popup.openPopup());
        },
      };
    },
    optionsStyle() {
      return {
        color: '#beccef',
      };
    },
    tilesURL() {
      return this.item.wms ? `${this.item.wms.tiles_url}?exceptions=application/vnd.ogc.se_inimage` : '';
    },
    tileSize() {
      return this.item.wms.tile_size || 256;
    },
    layers() {
      return this.item.wms.tile_layers;
    },
    style() {
      return this.item.wms.style || '';
    },
    format() {
      return this.item.wms.content_type || 'image/png';
    },
    layerType() {
      const layerType = this.item.wms.layer_type || 'overlay';
      console.log('layerType', layerType);
      return layerType;
    },
    crs() {
      // convention: all layers are available in this epsg
      return CRS.EPSG4326;
    },
    version() {
      return '1.3.0';
    },
  },
};
</script>
