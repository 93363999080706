export default [
  {
    heading: 'Supporting Livelihoods',
    question: 'To how many additional livelihoods does 1 artisanal gold miner contribute on average?',
    response: 'Gold miners spend their income in a variety of ways. By doing so, they contribute to the income of their rural communities.',
    detailed_response: 'Miners bring additional income to local economies. While different approaches to calculating how many people are supported additionally, it is unquestioned that knock-on effects of artisanal mining contribute to economic outcomes of their entire community.',
    answers: [
      { value: '2' },
      { value: '7', isCorrect: true },
      { value: '12' },
    ],
  },
  {
    heading: 'Women Miners',
    question: 'What share of artisanal gold miners in Ethiopia are women?',
    response: 'Around a third of artisanal gold miners are women.',
    detailed_response: 'The role of women in artisanal mining is often limited to less physically demanding tasks. Still, they make up a considerable amount of Ethiopians in artisanal mining and their role should not be overlooked.',
    answers: [
      { value: '1 in 2' },
      { value: '1 in 3', isCorrect: true },
      { value: '1 in 5' },
      { value: '1 in 6' },
    ],
  },
  {
    heading: 'Cooking Fuels',
    question: 'Which source of cooking fuel is most healthy?',
    response: 'Using electricity for cooking descreses air pollution in the household, leading to lower rates of respiratory disease.',
    detailed_response: 'Combustion of gas and biofuels leads to air pollution in households. If circumstances permit, the switch to using electricity can alleviate common issues with respiratory illnesses connected with other cooking fuels.',
    answers: [
      { value: 'biofuels' },
      { value: 'electricity', isCorrect: true },
      { value: 'gas' },
    ],
  },
  {
    heading: 'Social Health',
    question: 'Select the social health determinant:',
    response: 'Underlying factors that affect the health of people are called "health determinants". Effects on these social and environmental factors are often forgot in planning of large infrastructure projects.',
    detailed_response: '',
    answers: [
      { value: 'air quality' },
      { value: 'clean water' },
      { value: 'price of medication', isCorrect: true },
    ],
  },
  {
    heading: 'Urban Population',
    question: 'What share of the population lives in urban areas in the counties Isiolo, Samburu, and Marsabit of northern Kenya?',
    response: '23% of the population lives in urban areas.',
    detailed_response: 'Analyses of the 2009 Population and Housing Census of Kenya showed that socio-economic characteristics of the population of Kenya vary greatly between rural and urban areas. This gradient is not always clearly visible in maps or statistics, but can have great relevance in defining sustainable development priorities.',
    answers: [
      { value: '9%' },
      { value: '16%' },
      { value: '23%', isCorrect: true },
      { value: '31%' },
    ],
  },
  {
    heading: 'Underage',
    question: 'What share of the population is under the age 18 in the counties Isiolo, Samburu, and Marsabit of northern Kenya?',
    response: '55% of the population in the three counties is below the age 18.',
    detailed_response: 'Present and future sustainable development issues and potentials vary greatly with the age of the population and understanding the share of young people is crucial. A high share of young people means the population is growing, which defines e.g. education and labor market needs. Similarly, the share of e.g. old people and their needs and dependencies require their own measures.',
    answers: [
      { value: '26%' },
      { value: '34%' },
      { value: '41%' },
      { value: '55%', isCorrect: true },
    ],
  },
  {
    heading: 'Protected Lands',
    question: 'What is the share of conserved and protected land in the counties Isiolo, Samburu, and Marsabit of northern Kenya?',
    response: '24% of the land in the three counties has conservation or protection status.',
    detailed_response: 'River basins and surrounding wetlands, forests, and nature reserves are Kenya’s key natural resources and important for the countries environmental and socio-economic development. Different governance mechanisms exist for these areas, ranging from full protection with no use possibilities (e.g. forests), nature reserves with limited use possibilities (e.g. tourism, firewood collection), to conservancies with innovative governance schemes that embed wildlife and nature protection with needs of local pastoralist communities and other forms of livestock keeping.',
    answers: [
      { value: '13%' },
      { value: '24%', isCorrect: true },
      { value: '36%' },
      { value: '47%' },
    ],
  },
  {
    heading: 'Safe Water Sources',
    question: 'What is the share of the population with access to safe water sources in the counties Isiolo, Samburu, and Marsabit of northern Kenya?',
    response: '41% of people in the three counties have access to safe drinking water (national average: 56%).',
    detailed_response: 'Human health is directly affected by unsafe water for drinking and about 80% of human diseases are water related. In the semi-arid lands of northern Kenya access to safe drinking water also varies between rural and urban areas and a higher share of the population in urban areas has access to safe water. Given higher population densities in these regions, a high total number of people may still use unsafe water sources for drinking, e.g. in low-income quarters of larger towns.',
    answers: [
      { value: '22%' },
      { value: '41%', isCorrect: true },
      { value: '56%' },
      { value: '68%' },
    ],
  },
  {
    heading: 'Biofuels',
    question: 'What is the share of the population that depends on solid biofuels for cooking in the counties Isiolo, Samburu, and Marsabit of northern Kenya?',
    response: '98% of the people in the three counties live in households that use solid biofuels for cooking (national average: 82%).',
    detailed_response: 'The main type of energy used provides information on the level of economic development and how livelihoods are connected to and interact with the environment. The advantage of using biofuels is the possibility to rely on the countries own renewable resources. But overuse, particularly also considering the countries growing population, can lead to environmental degradation affecting e.g. water balance and biodiversity. A broad range of respiratory diseases are also associated with indoor air pollution and affect mainly young children and women. Women are also mostly gathering solid biofuels, limiting the time for other productive activities.',
    answers: [
      { value: '63%' },
      { value: '81%' },
      { value: '93%' },
      { value: '98%', isCorrect: true },
    ],
  },
  {
    heading: 'Non-state Conflicts',
    question: 'What is the number of non-state conflicts in the past 32 years in the counties Isiolo, Samburu, and Marsabit of northern Kenya?',
    response: 'Between 1998 and 2021, a total of 108 conflicts with an estimate of more than 850 fatalities have been recorded (not including state-based violence).',
    detailed_response: 'Cattle rustling is a traditional cultural practice that served as means to e.g. balance wealth and power among pastoralist communities. In recent years, however, these small scale livestock thefts have become more violent due to the use of modern weapons and often result in fatalities. With improved accessibility (e.g. asphalt roads) security forces can reach conflict areas faster, which reduces number and severity of conflicts. Certain causes of conflicts, however, may still need to be addressed differently to sustain more peaceful interactions between communities in the long term.',
    answers: [
      { value: '19' },
      { value: '53' },
      { value: '108', isCorrect: true },
      { value: '146' },
    ],
  },
  {
    heading: 'Possible Miners?',
    question: 'What share of the Ethiopian population lives in areas where minerals occur?',
    response: 'Almost 20% of the population lives in areas with known mineral occurrence.',
    detailed_response: 'Extractive industries can substantially provide to socio-economic development from local to national level. At the same time, if not properly governed (e.g. neglecting environmental protection and human health), it can impact people, nature, and livelihoods negatively, often with more pronounced effects for poorer or less powerful segments of the population. Understanding amounts, location, and socio-economic characteristics of affected people can thus support the definition of sustainable governance mechanism around mining activities.',
    answers: [
      { value: '9%' },
      { value: '19%', isCorrect: true },
      { value: '27%' },
      { value: '34%' },
    ],
  },
  {
    heading: 'Environmental Health',
    question: 'Which environmental health determinant causes the highest burden of disease?',
    response: 'Outdoor air pollution is attributable for more than 4 million deaths each year. ',
    detailed_response: '',
    answers: [
      { value: 'Outdoor air pollution', isCorrect: true },
      { value: 'Unsafe drinking water' },
      { value: 'Unsafe sanitation' },
      { value: 'Indoor air pollution' },
    ],
  },
  {
    heading: 'Fueling death?',
    question: 'How many deaths are attributed each year to the indoor use of solid fuels for cooking and heating?',
    response: 'Indoor air pollution is a major threat to public health.',
    detailed_response: 'Women and children are disproportionally exposed to indoor air pollutants.',
    answers: [
      { value: '2.6 million', isCorrect: true },
      { value: '1.5 million' },
      { value: '0.3 million' },
      { value: '1 million' },
    ],
  },
  {
    heading: 'Skilled health personnel',
    question: 'What proportion of births are attended by skilled health personnel in Kenya?',
    response: 'Adequate health care during birth can help to reduce maternal mortality.',
    detailed_response: 'This metric is thus an indicator to measure progress of SDG target 3.1.',
    answers: [
      { value: '70%', isCorrect: true },
      { value: '35%' },
      { value: '90%' },
      { value: '15%' },
    ],
  },
  {
    heading: 'Connected towns',
    question: 'Which two towns are connected through this 504 kilometer-long highway since 2016?',
    response: 'The LAPSSET highway in Northern Kenya connects the town of Isiolo with the Ethiopian town of Moyale.',
    detailed_response: 'The 504 kilometer-long highway was completed in 2016.',
    answers: [
      { value: 'Isiolo and Moyale', isCorrect: true },
      { value: 'Nairobi and Isiolo' },
      { value: 'Nairobi and Mombasa' },
      { value: 'Mombasa and Moyale' },
    ],
  },
  {
    heading: 'Reduced travel time',
    question: 'How much time is now needed to drive from Nairobi to Moyale?',
    response: 'The trip now only takes around 10 hours.',
    detailed_response: 'Before the highway was constructed, the drive from Nairobi to Moyale on the mostly dusty road was a three-day journey.',
    answers: [
      { value: 'Only 10 hours', isCorrect: true },
      { value: 'Only 2 days' },
      { value: 'Only 8 hours' },
      { value: 'Only 6 hours' },
    ],
  },
  {
    heading: 'Land dynamics',
    question: 'Which types of land cover have seen their area shrink between 2007 and 2019 in the Kenyan case study region?',
    response: 'Grasslands and wetlands have seen their area shrink the most.',
    detailed_response: 'At the same time, agricultural areas have been emerging around the study area in recent years',
    answers: [
      { value: 'Grassland and wetlands', isCorrect: true },
      { value: 'Agricultural land and wetlands' },
      { value: 'Grassland for pastoralism' },
      { value: 'Agricultural land' },
    ],
  },
  {
    heading: 'Exemplary mine?',
    question: 'For what is the Lega Dembi mine a prominent example?',
    response: 'Legacy mercury pollution in the Lega Dembi mine affect surrounding communities to this day.',
    detailed_response: 'Ethiopia has signed the Minamata Convention that aims to protect humans and nature from mercury pollution. However, as of 2021, it has yet to ratify the framework.',
    answers: [
      { value: 'Severe health impacts', isCorrect: true },
      { value: 'Illegal gold smuggling' },
      { value: 'Prosperity for locals' },
    ],
  },
  {
    heading: 'Resources',
    question: 'What mineral resources are present in Ethiopia?',
    response: 'Ethiopia is well-endowed with mineral resources, however silver and lithium are not among them.',
    detailed_response: 'Various mineral deposits are located in Ethiopia. For example in the Danakil Depression, salt mining is practiced by artisanal miners. In the South, more emphasis is placed on gold mining.',
    answers: [
      { value: 'Gold, potash, salt and tantalum', isCorrect: true },
      { value: 'Gold, silver, iron, and lithium' },
      { value: 'Gold, platinum and tantalum' },
    ],
  },
];
