export default [
  {
    id: 'disabled',
    name: 'Disable Background',
    visible: false,
    url: '',
  },
  {
    id: 'Default OSM',
    name: 'Default OSM',
    visible: false,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '© <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
  },
  {
    id: 'humanitarian',
    name: 'Humanitarian OSM',
    visible: true,
    url: 'https://tile-a.openstreetmap.fr/hot/{z}/{x}/{y}.png',
    attribution:
      '© <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>. Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>. <a href="https://wiki.osmfoundation.org/wiki/Terms_of_Use" target="_blank">Website and API terms</a>',
  },
  {
    id: 'OpenTopoMap',
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
];
