<template>
  <div class="mt-3">
    <b-row
      cols="1"
      cols-sm="2"
      cols-md="3"
      cols-lg="4"
      align-h="center"
      align-v="stretch"
      class="question-row"
    >
      <b-col
        v-for="(question, qIndex) in questions"
        :key="question.id"
        :style="`min-height: ${height}px`"
        class="question-column"
      >
        <FlipCard
          allowEventsOnBackside
          :reactOnHover="!question.answer"
          :ref="`card-${qIndex}`"
          :showMark="false"
          class="question-container"
        >
          <b-card
            class="m-1 bg-gradient-secondary quiz-card text-light"
            no-body
          >
            <header>{{ question.item.heading }}</header>
            <div>{{ question.item.question }}</div>
            <b-input-group class="answers" v-if="!question.answer">
              <b-input-group-prepend class="choice" is-text>
                <b-form-radio
                  :name="`question-${qIndex}`"
                  v-for="(answer, index) in question.item.answers"
                  :key="index"
                  size="sm"
                  :value="answer.value"
                  v-on:change="onQuestionChecked(qIndex, answer)"
                >
                  {{ answer.value }}
                </b-form-radio>
              </b-input-group-prepend>
            </b-input-group>
            <div v-else>
              Your answer <span class="text-body">{{ question.answer.value }}</span>
              <span v-if="question.answer.isCorrect"> is correct! </span>
              <span v-else> is not correct.</span>
            </div>
            <b-input-group-prepend class="solution" is-text v-if="question.answer">
              <b-tooltip
                :ref="`tooltip-detail-${qIndex}`"
                :target="`question-detail-${qIndex}`"
                placement="center"
                triggers="hover"
                variant="primary"
              >{{ question.item.detailed_response }}</b-tooltip>

              <div>{{ question.item.response }}
                <br/>
                <a
                  href="#"
                  @click.prevent="onToolTipClick(qIndex)"
                  :id="`question-detail-${qIndex}`"
                >{{ !question.showDetail ? 'Show' : 'Hide' }} explanation...</a>
              </div>
            </b-input-group-prepend>
          </b-card>
          <template v-slot:front>
            <b-card
              align="center"
              class="m-1 bg-gradient-secondary quiz-card text-light"
              no-body
            >
              <div class="h-100">
                <div class="shadow-lg h-100 d-flex justify-content-center align-items-center">
                  <div>
                    <h4 class="d-lg-inline">{{ question.item.heading }}</h4>
                  </div>
                </div>
              </div>
            </b-card>
          </template>
        </FlipCard>
      </b-col>
    </b-row>

    <div class="button-bar mt-2">
      <b-button
        variant="secondary"
        @click="onReset"
      >Reset
      </b-button>
    </div>
  </div>
</template>

<script>
import FlipCard from '@/components/base/FlipCard.vue';
import { shuffle, firstOrDefault, noop } from '@/common';

import questions from '../../assets/playground/quiz/questions';

export default {
  name: 'Quiz',
  props: {
    height: {
      required: false,
      default: 240,
      type: Number,
    },
  },
  components: { FlipCard },
  mounted() {
    this.init();
  },
  data() {
    return {
      gen: 0,
      questions: [],
    };
  },
  methods: {
    init() {
      this.questions = shuffle(questions).map((item, idx) => ({
        id: `q-${this.gen}${idx}`,
        answer: undefined,
        showDetail: false,
        item,
      }));
      this.gen++;
    },
    onReset() {
      this.init();
    },
    onQuestionChecked(qIndex, answer) {
      firstOrDefault(
        this.$refs[`card-${qIndex}`],
        { mark: noop },
      ).mark(answer.isCorrect === true);

      this.$set(
        this.questions,
        qIndex,
        {
          ...this.questions[qIndex],
          answer,
        },
      );
    },
    onToolTipClick(qIndex) {
      const showDetail = !this.questions[qIndex].showDetail;
      this.$set(
        this.questions,
        qIndex,
        {
          ...this.questions[qIndex],
          showDetail,
        },
      );

      const toolTip = firstOrDefault(
        this.$refs[`tooltip-detail-${qIndex}`],
        { $emit: noop },
      );
      toolTip.$emit(showDetail ? 'disable' : 'enable');
      toolTip.$emit(showDetail ? 'open' : 'close');
    },
  },
  computed: {
  },
};
</script>
<style lang="scss" scoped>
div.question-row {
  padding: 0;
  margin: 0 -8px 0 -8px;
}

.question-column {
  min-width: 240px;
  padding: 4px;
}

.question-container {
  height: calc(100% - 8px);
}

.quiz-card {
  height: 100%;
  padding: 8px;
  min-width: 185px;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
  > header {
    font-size: 1.25rem;
  }
}

.answers {
  display: flex;
  flex: 1;
  flex-direction: column;

  > .choice {
    display: flex;
    flex-direction: column;

    .input-group-text {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.solution > div.input-group-text {
  flex-direction: column;
  white-space: normal;
}

.button-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

</style>
