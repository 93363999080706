import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import metadata from './modules/metadata';
import mapdata from './modules/mapdata';
import toastMessages from './modules/toastMessages';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
  },
  modules: {
    auth,
    metadata,
    mapdata,
    toasts: toastMessages,
  },
  plugins: [
    createPersistedState(),
  ],
});
