<template>
  <b-aspect :aspect="aspect">
    <video ref="video" class="video-container"
      preload="metadata"
      muted
    >
      <source
        v-for="source in sources" :src="source.src" :type="source.type" :key="source.src"
      />
      <p>
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that supports HTML5 video.
      </p>
    </video>
  </b-aspect>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    sources: {
      type: Array,
      default() {
        return [];
      },
    },
    fps: {
      required: false,
      type: Number,
      default: 24.0,
    },
    maxStepSeconds: {
      required: false,
      type: Number,
      default: 1.7,
    },
    aspect: {
      type: String,
      required: false,
      // possible aspects:
      // [
      //     { text: '4:3 (SD)', value: '4:3' },
      //     { text: '1:1 (Square)', value: '1:1' },
      //     { text: '16:9 (HD)', value: '16:9' },
      //     { text: '1.85:1 (Widescreen)', value: '1.85:1' },
      //     { text: '2:1 (Univisium/Superscope)', value: '2:1' },
      //     { text: '21:9 (Anamorphic)', value: '21:9' },
      //     { text: '1.43:1 (IMAX)', value: '1.43:1' },
      //     { text: '3:2 (35mm Film)', value: '3:2' },
      //     { text: '3:1 (APS-P)', value: '3:1' },
      //     { text: '4/3 (Same as 4:3)', value: 4 / 3 },
      //     { text: '16/9 (Same as 16:9)', value: 16 / 9 },
      //     { text: '3 (Same as 3:1)', value: 3 },
      //     { text: '2 (Same as 2:1)', value: 2 },
      //     { text: '1.85 (Same as 1.85:1)', value: 1.85 },
      //     { text: '1.5', value: 1.5 },
      //     { text: '1 (Same as 1:1)', value: 1 }
      // ]
      default: '16:9',
    },
  },
  data() {
    return {
      intervalId: null,
      duration: null,
      video: null,
      ready: false,
      step: 1 / this.fps,
      lastStopFrame: null,
    };
  },
  mounted() {
    this.$refs.video.addEventListener('loadedmetadata', ({ target }) => {
      this.video = target;
      this.duration = this.video.duration;
      this.ready = true;
      this.$emit('metaLoaded', { duration: this.duration });
    });
  },
  beforeDestroy() {
    this.pause();
  },
  methods: {
    pause() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    playTo(stopFrameTime) {
      if (!this.ready) {
        return;
      }
      // check video direction
      const playReverse = this.video.currentTime > this.lastStopFrame;
      if (playReverse) {
        const shouldSkipBackwards = (this.video.currentTime - this.maxStepSeconds)
          > stopFrameTime;
        if (shouldSkipBackwards) {
          this.video.currentTime = stopFrameTime + this.maxStepSeconds;
        }
      } else {
        const shouldSkipForward = (this.video.currentTime + this.maxStepSeconds)
          < stopFrameTime;
        if (shouldSkipForward) {
          this.video.currentTime = stopFrameTime - this.maxStepSeconds;
        }
      }
      this.play({ reverse: playReverse, stopFrameTime });
    },
    makeAStep() {
      this.video.currentTime += this.step;
    },
    play(options) {
      if (!this.ready) {
        return;
      }
      let stop;
      if (options && options.reverse) {
        this.step *= -1;
      }
      stop = options.stopFrameTime;
      // stay in boundaries
      if (stop < 0) {
        stop = 0;
      }
      if (stop > this.duration) {
        stop = this.duration;
      }
      this.lastStopFrame = stop;
      this.pause();

      const updateIinterval = (1 / this.fps) * 1000;

      this.intervalId = setInterval(() => {
        const isNegative = Math.sign(this.step) === -1;
        const endReachd = isNegative ? (this.video.currentTime <= stop)
          : (this.video.currentTime >= stop);
        if (endReachd) {
          this.video.currentTime = stop;
          this.pause();
        } else {
          this.makeAStep();
        }
      }, updateIinterval);
    },
  },
};
</script>
<style lang="scss">
.video-container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}
</style>
