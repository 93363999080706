import { render, staticRenderFns } from "./GoldExports.vue?vue&type=template&id=0c68ab38&scoped=true&"
import script from "./GoldExports.vue?vue&type=script&lang=js&"
export * from "./GoldExports.vue?vue&type=script&lang=js&"
import style0 from "./GoldExports.vue?vue&type=style&index=0&id=0c68ab38&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c68ab38",
  null
  
)

export default component.exports