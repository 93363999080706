<template>
    <div class="fixed-top overlay" v-show="visible">
      <div class="sdg-indicator">
        <div v-if="sdgActiveStati.sdg3Active">
          <b-img
            src="@/assets/blog/kenya_story/images/SDG_03.png"
            alt="SDG-3"
            class="sdg-image"
            v-bind="imageProps"
          ></b-img>
        </div>
        <div v-else>
          <b-img
            src="@/assets/blog/kenya_story/images/SDG_03-deactivated.png"
            alt="SDG-3"
            class="sdg-image"
            v-bind="imageProps"
          ></b-img>
        </div>

        <div v-if="sdgActiveStati.sdg15Active">
          <b-img
            src="@/assets/blog/kenya_story/images/SDG_15.png"
            alt="SDG-15"
            class="sdg-image"
            v-bind="imageProps"
          ></b-img>
        </div>
        <div v-else>
          <b-img
            src="@/assets/blog/kenya_story/images/SDG_15-deactivated.png"
            alt="TODO"
            class="sdg-image"
            v-bind="imageProps"
          ></b-img>
        </div>

        <div v-if="sdgActiveStati.sdg16Active">
          <b-img
            src="@/assets/blog/kenya_story/images/SDG_16.png"
            alt="SDG-16"
            class="sdg-image"
            v-bind="imageProps"
          ></b-img>
        </div>
        <div v-else>
          <b-img
            src="@/assets/blog/kenya_story/images/SDG_16-deactivated.png"
            alt="SDG-16"
            class="sdg-image"
            v-bind="imageProps"
          ></b-img>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    visible: {
      required: false,
      default: false,
      type: Boolean,
    },
    sdgActiveStati: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      smaller: false,
      imageProps: {
        thumbnail: true,
        fluid: true,
        width: '70',
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/bootstrap';

div.overlay {
  pointer-events: none;
  z-index: 10000;
  opacity: 0.85;
}

.sdg-indicator {
  display: none;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 1rem;

  > div {
    margin-top: 1px;

    > img.sdg-image {
      padding: 1.5px;
      border-radius: 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .sdg-indicator {
    display: flex;
  }
}

@include media-breakpoint-up(lg) {
  .sdg-indicator {
    padding-right: 2rem;
  }
}
</style>
