import axiosBase from './AxiosBase';

const resource = '/gis-data';
export default {
  get() {
    return axiosBase.get(`${resource}/`);
  },
  getById(id) {
    return axiosBase.get(`${resource}/${id}/`);
  },
  // createPost(payload) {
  //   return axiosBase.post(`${resource}/`, payload);
  // }
};
