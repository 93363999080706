<template>
  <!-- eslint-disable max-len -->
  <!-- Section 1: Introduction -->
  <b-row>
    <b-col cols="8 align-self-center">
      <p>
        The open road is the stuff of legend and of myriad songs and stories. There are roads not taken and roads less travelled. But whether you are on the road or on the road again, it is evident that building a highway is about so much more than just laying concrete. The construction itself – and the finished product – have far-reaching impacts not only on the environment, but also on the economy and on society, thus affecting all three dimensions of sustainable development.       </p>
      <p>
        Our focus here is on northern Kenya, and how a section of a brand-new highway is affecting three of the UN’s Sustainable Development Goals (SDGs). We demonstrate the impacts of this highway – which is part of a massive infrastructure project – on SDGs 15 (Life on Land) and 16 (Peace, Justice and Strong Institutions) in the region. And we show how these factors are interlinked, affecting local communities in various ways, including through SDG3 (Good Health and Well-Being).      </p>
    </b-col>
    <b-col cols="4">
      <b-img
        src="@/assets/blog/kenya_story/images/SDG-logo-transparent.png"
        fluid-grow
        alt="Logo of the Sustainable Development Goals (SDGs)"
      ></b-img>
    </b-col>
  </b-row>
</template>
<script>
export default {
  components: {
  },
  name: 'introduction',
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
</style>
