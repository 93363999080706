<template>
  <div class="mt-3">
    <b-modal v-model="winModalShow" hide-footer title="Winner!">
      <div class="d-block text-center">
        <h3>Good Job, you won!</h3>
      </div>
      <b-button
        class="mt-3"
        variant="info"
        block
        @click="hideWinModal"
      >
        Close
      </b-button>
      <b-button
        class="mt-2"
        variant="primary"
        block
        @click="playAgain"
      >
        Play Again
      </b-button>
    </b-modal>

    <b-row
      cols="2"
      cols-sm="2"
      cols-md="3"
      cols-lg="4"
      align-h="center"
      align-v="stretch"
      class="memory-row"
    >
      <b-col
        v-for="card in shuffledCards"
        :key="card.id"
        :style="`min-width: ${height}px`"
        class="memory-column"
      >
        <FlipCard
          :ref="`card-${card.id}`"
          :height="height"
          :cardId="card.id"
          @touched="wasTouched"
        >
          <b-card
            align="center"
            class="text-center m-1"
            bg-variant="green"
            no-body
            :style="`height: ${height}px;`"
          >
            <b-img
              v-if="card.img"
              :src="card.img"
              :width="card.imgWidth"
              :height="card.imgHeight"
              class="card-img"
            />
            <h3 v-if="card.text" class="px-2 my-auto">
              {{ card.text }}
            </h3>
          </b-card>
          <template v-slot:front>
            <b-img class="p-1" :src="backgroundImage" fluid thumbnail />
          </template>
        </FlipCard>
      </b-col>
    </b-row>

    <div class="button-bar mt-2">
      <b-button
        v-if="gameWon"
        variant="primary"
        @click="playAgain"
      >
        Play Again
      </b-button>
      <b-button
        v-else
        variant="secondary"
        @click="playAgain"
      >Reset
      </b-button>
    </div>
  </div>
</template>

<script>
import FlipCard from '@/components/base/FlipCard.vue';

import backgroundImage from '@/assets/playground/memory/SDG/memoryBackside.png';

import pairAImg from '@/assets/playground/memory/SDG/pairA/pairA-1.png';
import pairBImg from '@/assets/playground/memory/SDG/pairB/pairB-1.png';
import pairCImg from '@/assets/playground/memory/SDG/pairC/pairC-1.png';
import pairDImg from '@/assets/playground/memory/SDG/pairD/pairD-1.png';
import pairEImg from '@/assets/playground/memory/SDG/pairE/pairE-1.png';
import pairFImg from '@/assets/playground/memory/SDG/pairF/pairF-1.png';
import pairGImg from '@/assets/playground/memory/SDG/pairG/pairG-1.png';

import { getScaledImageDimensions, shuffle, firstOrDefault } from '@/common';

const AUTO_CLOSE_CARDS = false;

export default {
  name: 'Memory',
  props: {
    height: {
      required: false,
      default: 250,
      type: Number,
    },
  },
  components: { FlipCard },
  mounted() {
    this.shuffleCards();
  },
  data() {
    return {
      pairFound: false,
      backgroundImage,
      pairs: [
        {
          s1: { text: '2030 Agenda' },
          s2: { img: pairAImg },
        },
        {
          s1: { text: 'Ensure healthy lives and promote well-being for all at all ages' },
          s2: { img: pairBImg },
        },
        {
          s1: { text: 'Protection, restoration and sustainable use of terrestrial ecosystems' },
          s2: { img: pairCImg },
        },
        {
          s1: { text: 'Peaceful and inclusive societies as a requirement for sustainable development' },
          s2: { img: pairDImg },
        },
        {
          s1: { text: 'Weak sustainability' },
          s2: { img: pairEImg },
        },
        {
          s1: { text: 'Strong sustainability' },
          s2: { img: pairFImg },
        },
        {
          s1: { text: 'Safe space for humanity between social foundation and ecological ceiling' },
          s2: { img: pairGImg },
        },
        {
          s1: { text: '17' },
          s2: { text: 'Total number of Sustainable Development Goals (SDGs)' },
        },
      ],
      shuffledCards: [],
      openCardIds: [],
      matchedCardIds: [],
      winModalShow: false,
      closeCardTimeOut: null,
    };
  },
  methods: {
    shuffleCards() {
      this.shuffledCards = shuffle(this.cards);
      this.$nextTick(() => {
        this.shuffledCards.forEach((card) => { this.getCardInstance(card.id).reset(); });
      });
    },
    wasTouched({ cardId }) {
      // do nothing if game is won or same card is chosen twice or already correctly open
      if (
        this.gameWon
        || this.openCardIds.indexOf(cardId) !== -1
        || this.matchedCardIds.indexOf(cardId) !== -1
      ) {
        return;
      }
      if (this.closeCardTimeOut) {
        clearTimeout(this.closeCardTimeOut);
      }
      // ensure no cards left open
      if (this.openCardIds.length > 1) {
        this.closeOpenCards();
      }
      this.flipCard(cardId);

      this.openCardIds.push(cardId);
      this.checkMatch();
      this.checkWin();
    },
    checkMatch() {
      if (this.openCardIds.length === 2) {
        const cardId1 = this.openCardIds[0];
        const cardId2 = this.openCardIds[1];

        const card1 = this.cards.find((card) => card.id === cardId1);
        if (card1.matches === cardId2) {
          const cardInstance1 = this.getCardInstance(cardId1);
          cardInstance1.markCard();
          const cardInstance2 = this.getCardInstance(cardId2);
          cardInstance2.markCard();
          this.matchedCardIds.push(cardId1);
          this.matchedCardIds.push(cardId2);
          this.openCardIds = [];
          this.pairFound = true;
          setTimeout(() => { this.pairFound = false; }, 1000);
        } else if (AUTO_CLOSE_CARDS) {
          this.closeCardTimeOut = setTimeout(this.closeOpenCards, 1000);
        }
      }
    },
    checkWin() {
      if (this.gameWon) { this.showWinModal(); }
    },
    closeOpenCards() {
      this.openCardIds.forEach((cardId) => {
        const vueCard = this.getCardInstance(cardId);
        vueCard.closeCard();
      });
      this.openCardIds = [];
    },
    getCardInstance(cardId) {
      return firstOrDefault(this.$refs[`card-${cardId}`]);
    },
    flipCard(cardId) {
      // ignore already open cards
      if (this.openCardIds.indexOf(cardId) !== -1) {
        return;
      }
      const vueCard = this.getCardInstance(cardId);
      vueCard.flipCard();
    },
    playAgain() {
      this.hideWinModal();
      this.openCardIds = [];
      this.matchedCardIds = [];
      this.shuffledCards = [];
      this.shuffleCards();
    },
    showWinModal() {
      this.winModalShow = true;
    },
    hideWinModal() {
      this.winModalShow = false;
    },
  },
  computed: {
    gameWon() {
      return this.matchedCardIds.length === this.cards.length;
    },
    cards() {
      if (!this.pairs) {
        return [];
      }
      const cards = [];
      this.pairs.forEach((value, index) => {
        const { s1, s2 } = value;
        cards.push({
          id: `${index}-0`, matches: `${index}-1`, img: s1.img, text: s1.text,
        });
        cards.push({
          id: `${index}-1`, matches: `${index}-0`, img: s2.img, text: s2.text,
        });
      });
      return cards.map((card) => {
        const extraAttrs = {};
        if (card.img) {
          const { width, height } = getScaledImageDimensions(
            card.img,
            { targetHeight: this.height },
          );
          extraAttrs.imgWidth = width;
          extraAttrs.imgHeight = height;
        }
        return { ...card, ...extraAttrs };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
div.memory-row {
  padding: 0;
  margin: 0 -8px 0 -8px;
}

.memory-column {
  padding: 4px;
}

.button-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flip-card-front {
  padding: 8px;

  > div.shadow-lg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .card-cover {
    font-size: 7rem;
    font-weight: bold;
    opacity: 0.1;
  }
}
</style>
