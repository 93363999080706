<template>
  <div>
    <b-container>
      <div class="video-container">
        <VideoPlayer
          ref="videoPlayer"
          :sources="sources"
          @metaLoaded="playerReady"
        />
      </div>

      <Scrollama
        :offset="0.9"
        @step-progress="stepProgressHandler"
        class="slides"
      >
        <div
          class="slide"
          v-for="(slide, index) in slides"
          :key="`${slide.title}_${index}`"
          :data-title="slide.title"
          :debug="true"
        >
          <span v-if="slide.empty" />
          <span v-else>
            <p v-html="slide.content"></p>
          </span>
        </div>
      </Scrollama>
    </b-container>
  </div>
</template>

<script>
import Scrollama from 'vue-scrollama';
import environment from '../../../environment';
import VideoPlayer from '../../base/VideoPlayer.vue';

export default {
  components: {
    Scrollama,
    VideoPlayer,
  },
  name: 'miners-video',
  data() {
    return {
      videoDuration: null,
      sources: [
        // {
        // src: `${environment.assetsURL}/blog/
        // scrolly_telling_example/ETH_overviewMap_animation25fps.webm`,
        // type: 'video/webm',
        // },
        {
          src: `${environment.assetsURL}/blog/ethiopia_story/ETH_minersMap_animation12.webm`,
          type: 'video/webm',
        },
      ],
      slides: [
        {
          content: 'Artisanal gold miners in Ethiopia are concentrated in the above-mentioned geological belts.',
        },
        {
          empty: true,
        },
        {
          content: 'In the north, miners are active in the regions of <span style="color: #a6d854; font-weight: bold;">Tigray</span> and <span style="color: #66c2a5; font-weight: bold;">Amhara</span>.',
        },
        {
          empty: true,
        },
        {
          content: 'In the west, some mining activity takes place in <span style="color: #fc8d62; font-weight: bold;">Benishangul-Gumuz</span> and in <span style="color: #8da0cb; font-weight: bold;">Oromia</span>.',
        },
        {
          empty: true,
        },
        {
          content: 'The south has the largest numbers of artisanal miners in the country, operating in both <span style="color: #8da0cb; font-weight: bold;">Oromia</span> and <span style="color: #e78ac3; font-weight: bold;">SNNPR (Southern Nations, Nationalities, and Peoples’ Region)</span>.',

        },
        {
          empty: true,
        },
        {
          content: 'In total, around 1.1 million artisanal gold miners are estimated to operate across these regions. To supplement their incomes, artisanal miners often engage in other economic activities, such as smallholder farming, herding, or other basic livelihood activities. Usually, however, these activities are less profitable than periodic or seasonal mining.',
        },
        {
          empty: true,
        },
        {
          content: 'One in every three artisanal gold miners is a <span style="color: #ffb101; font-weight: bold;">woman</span>. The women miners often carry out less physically demanding tasks, such as panning.',
        },
        {
          empty: true,
        },
        {
          content: 'On average, every artisanal gold miner contributes to the economic livelihoods of seven other people.',
        },
        {
          empty: true,
        },
        {
          content: 'Artisanal gold mining thus contributes in some way – either directly or indirectly – to the livelihoods of one in twelve Ethiopian citizens.',
        },
        {
          empty: true,
        },
      ],
    };
  },
  methods: {
    playerReady({ duration }) {
      this.player = this.$refs.videoPlayer;
      this.videoDuration = duration;
    },
    stepProgressHandler({ index, progress }) {
      this.changeFrame(index, progress);
    },
    changeFrame(index, progress) {
      if (!this.player) {
        return;
      }
      this.playbackStepFactor = this.videoDuration / this.slides.length;
      const newFrameNumber = (index + progress) * this.playbackStepFactor;
      if (this.videoDuration < this.frameNumber) {
        this.frameNumber = this.videoDuration;
      } else {
        this.frameNumber = newFrameNumber;
      }
      this.player.playTo(this.frameNumber);
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.video-container {
  position: sticky;
  top: 10px;
}
figure {
  position: absolute;
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity 800ms;
}
figure img {
  min-width: 100%;
  min-height: 100%;
}
video.active {
  visibility: visible;
  opacity: 1;
}
.slides {
  z-index: 10;
  position: relative;
}
.slide {
  height: 100vh;
  margin: 0 auto;
  max-width: 300px;
  color: white;
}
.slide p {
  background-color: rgba(0, 0, 0, .7);
  padding: 0.7rem;
}
</style>
