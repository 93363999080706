<template>
  <b-list-group-item
    class="p-1 m-0 d-flex justify-content-between align-items-center"
    :style="{'background-color': `${color}50`}"
  >

    <b-button-toolbar
      class="bg-white"
      :style="{'border-color': `${color}50`}"
    >
      <b-button-group size="sm">
        <b-button
          v-if="item.data_type.toLowerCase() === 'raster'"
          variant="outline-info"
          size="sm"
          disabled
        >
        <b-icon
          icon="grid3x3"
          title="Raster (image) data"
        ></b-icon>
        </b-button>
        <b-button
          v-if="item.data_type.toLowerCase() === 'vector'"
          variant="outline-info"
          size="sm"
          disabled
        >
          <b-icon
            icon="bezier2"
            title="Vector data"
          ></b-icon>
        </b-button>
        <b-button
          v-if="item.data_format.toLowerCase() === 'pdf'"
          variant="outline-info"
          size="sm"
          disabled
        >
          <b-icon
            icon="file-text"
            title="PDF/Document"
          ></b-icon>
        </b-button>

      </b-button-group>
    </b-button-toolbar>

    <p
      class="px-2 small mx-auto my-auto title-ellipsed"
      v-b-tooltip.hover :title="item.title"
    >
      {{ item.title }}
    </p>

    <b-button-toolbar
      class="bg-white"
      :style="{'border-color': `${color}50`}"
    >
      <b-button-group size="sm">
        <b-button
          variant="outline-info"
          title="Display available metadata"
          @click="toggleMetaDataInfo"
        >
          <b-icon icon="info" />
        </b-button>

        <b-button
          v-if="panToLocation"
          variant="outline-primary"
          title="Jump to containing area"
          @click="jumpToRegion"
        >
          <b-icon icon="box-arrow-in-down-right"></b-icon>
        </b-button>

        <b-button
          variant="outline-primary"
          title="Show/hide data on map"
          :pressed="item.showDataOnMap"
          @click="toggleShowData"
        >
          <b-icon icon="eye" />
        </b-button>

      </b-button-group>
    </b-button-toolbar>

    <b-modal
      ref="meta-viewer"
      size="xl"
      hide-footer
      centered
      :title="item ? item.title : '[unknown]'"
    >
      <meta-viewer v-if="item" :meta-data="item"></meta-viewer>
    </b-modal>

  </b-list-group-item>
</template>

<script>
import MetaViewer from '@/widgets/MetaViewer.vue';

export default {
  name: 'GisDataItem',
  components: {
    MetaViewer,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: undefined,
    },
    item: {
      type: Object,
      required: true,
    },
    panToLocation: {
      type: Function,
      default: undefined,
    },
    cardProps: {
      type: Object,
      default: () => null,
    },
    clickAction: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      mapLoaded: false,
      showDataOnMap: false,
    };
  },
  methods: {
    modalShown() {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        this.mapLoaded = true;
      }, 100);
    },
    jumpToRegion() {
      this.panToLocation(this.item);
    },
    toggleShowData() {
      this.item.showDataOnMap = !this.item.showDataOnMap;
    },
    toggleMetaDataInfo() {
      this.$refs['meta-viewer'].show();
    },
  },
  computed: {
    colorStyle() {
      return {
        'background-color': `${this.color}20`,
        'border-color': `${this.color}80`,
      };
    },
  },
};
</script>
<style type="scss" scoped>
.title-ellipsed {
  white-space: nowrap;
  width: 35rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
