<template>
  <section class="plot">
    <header class="plot-annotation">
      <b-form-radio-group
        v-model="selectedMapping"
        size="sm"
        :options="selectedMappingOptions"
        buttons
        :button-variant="'outline-primary'"
        name="radios"></b-form-radio-group>
    </header>
    <Plotly
      ref="plotly"
      class="graph"
      v-on:hover="plotHover($event)"
      v-on:unhover="plotUnhover($event)"
      v-show="loaded"
      :data="traces"
      :layout="layout"
      :display-mode-bar="false"
    ></Plotly>
    <footer class="plot-annotation">
      <section class="data-legend">
        <article class="sub-legend">
          <header class="title">{{ markerColorLegendTitle }}</header>
          <ul>
            <li v-for="(markerColor, index) in markerColorLegend" :key="index">
              <legend-circle :size="10" :color="markerColor.style"></legend-circle>
              <div>{{ markerColor.title }}</div>
            </li>
          </ul>
        </article>
        <article class="sub-legend">
          <header class="title">{{ populationLegendTitle }}</header>
          <ul>
            <li v-for="(population, index) in populationLegend" :key="index">
              <legend-circle :size="population.size"></legend-circle>
              <div>{{ population.marker }}</div>
            </li>
          </ul>
        </article>
      </section>
    </footer>
  </section>
</template>
<script>
import { Plotly } from 'vue-plotly';
import { parseCSV } from '@/common';

import LegendCircle from './LegendCircle.vue';

function calc(op, left, right) {
  return (calc.CALC_DEFS[op]) ? calc.CALC_DEFS[op](left, right) : NaN;
}
calc.CALC_DEFS = {
  '+': (a, b) => a + b,
  '-': (a, b) => a - b,
  '*': (a, b) => a * b,
  '/': (a, b) => a / b,
};

const TEXT_STYLES = {
  initial: {
    opacity: 0.5,
    font: { size: 9 },
  },
  focus: { opacity: 1 },
  blur: { opacity: 0.1 },
};

const ARROW_STYLES = {
  initial: {
    opacity: 0.2,
    standoff: 4,
    arrowwidth: 1.2,
    arrowsize: 1.2,
  },
  focus: { opacity: 0.5 },
  blur: { opacity: 0.1 },
};

const BUBBLE_STYLES = {
  initial: {
    hoverinfo: 'none',
    opacity: 1,
  },
  focus: { opacity: 1 },
  blur: { opacity: 0.4 },
};

export default {
  // fires event 'selectedMappingChange'
  components: {
    Plotly,
    LegendCircle,
  },
  props: {
    title: {
      required: false,
      default: '',
      type: String,
    },
    dataSrc: {
      required: true,
      type: String,
    },
    headerRows: {
      required: false,
      type: Number,
    },
    xAxesRange: {
      required: true,
      type: String,
    },
    groupBy: {
      required: true,
      type: String,
    },
    dataMappings: {
      required: true,
      type: Array,
      // format:
      //   { x: ColumnMapping, y: ColumnMapping, title: '[String]' }
      //   ColumnMapping = { column: '[name]', title: '[String]' calc: CalculationDef }
      //   CalculationDef = { lColumn: '[name]', op: '[/|*|-|+]', rColumn: '[name]' }
    },
    marker: {
      required: true,
      type: Object,
      // format:
      //   { column: '[name]', size: SizeDef, color: ColorDef }
      //   SizeDef = { column: '[name]', maxSize: 25, minSize: 2, maxValue: 100000 }
      //   ColorDef = { column: '[name]', legend: '[String]', indicator: IndicatorSetDef }
      //   IndicatorSetDef (HashSet<T>) where T = { style: '[RGB / Color]', title: '[String]' }
    },
  },
  data() {
    return {
      loaded: false,
      traces: [],
      layout: {},
      selectedMapping: 0,
      cvsTable: {},
    };
  },
  async mounted() {
    this.cvsTable = await parseCSV(this.dataSrc, this.headerRows, true);
    this.initMapping(this.selectedMapping);

    this.loaded = true;
  },

  computed: {
    populationLegendTitle() {
      return this?.marker?.size?.legend;
    },
    markerColorLegendTitle() {
      return this?.marker?.color?.legend;
    },
    populationLegend() {
      const populationLegend = [];
      if (this?.marker?.size?.minSize && this?.marker?.size?.maxSize) {
        const sizeRange = this.marker.size.maxSize - this.marker.size.minSize;

        if (sizeRange > 0) {
          const numLegendItems = 4;
          /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
          for (let i = 1; i <= numLegendItems; ++i) {
            populationLegend.push({
              marker: (this.marker.size.maxValue / numLegendItems) * i,
              size: ((1 / numLegendItems) * i * sizeRange) + this.marker.size.minSize,
            });
          }
        }
      }
      return populationLegend;
    },
    markerColorLegend() {
      if (this.marker?.color?.indicator) {
        return Object.values(this.marker.color.indicator);
      }
      return [];
    },
    selectedMappingOptions() {
      return this.dataMappings.map(
        (m, index) => ({ text: m.title, value: index }),
      );
    },
  },

  watch: {
    selectedMapping(newValue) { // async update
      this.initMapping(newValue);
      this.$emit('selectedMappingChange', newValue);
    },
  },

  methods: {
    initMapping(selectedMapping) {
      const dataPoints = {};
      const mapping = (this.dataMappings || [])[selectedMapping];

      if (mapping) {
        const markerColumnIdx = this.cvsTable.findColIndex(this.marker.column);
        const markerSizeColumnIdx = this.cvsTable.findColIndex(this.marker.size.column);
        const markerColorColumnIdx = this.cvsTable.findColIndex(this.marker.color.column);

        const xMapping = this.getMappingStrategy(this.cvsTable, mapping.x);
        const yMapping = this.getMappingStrategy(this.cvsTable, mapping.y);
        const groupColumnIdx = this.cvsTable.findColIndex(this.groupBy);

        this.traces = [];
        this.layout = {
          title: this.title,
          xaxis: { title: xMapping.axisTitle },
          yaxis: { title: yMapping.axisTitle },
          annotations: [],
          hovermode: 'closest',
        };

        this.cvsTable.data.forEach((row) => {
          const groupName = row[groupColumnIdx];
          if (!(groupName in dataPoints)) {
            dataPoints[groupName] = {
              x: [],
              y: [],
              text: row[markerColumnIdx],
              color: [],
              marker: [],
            };
          }

          dataPoints[groupName].x.push(xMapping.resolver(row));
          dataPoints[groupName].y.push(yMapping.resolver(row));
          dataPoints[groupName].marker.push(this.getMarkerSize(row[markerSizeColumnIdx]));
          dataPoints[groupName].color.push(this.getMarkerColor(row[markerColorColumnIdx]));
        });
      }

      Object.keys(dataPoints).forEach((groupName) => {
        const dataPoint = dataPoints[groupName];
        this.layout.annotations.push({ // text annotation
          ...TEXT_STYLES.initial,
          xref: 'x',
          yref: 'y',
          x: (dataPoint.x[1] + dataPoint.x[0]) / 2,
          y: (dataPoint.y[1] + dataPoint.y[0]) / 2,
          name: `${groupName}-text`,
          text: `<b>${dataPoint.text}</b>`,
          showarrow: false,
        });
        this.layout.annotations.push({ // arrow annotation
          ...ARROW_STYLES.initial,
          axref: 'x',
          ax: dataPoint.x[0],
          x: dataPoint.x[1],
          aref: 'x',
          ayref: 'y',
          ay: dataPoint.y[0],
          y: dataPoint.y[1],
          name: `${groupName}-arrow`,
        });
        this.traces.push({
          ...BUBBLE_STYLES.initial,
          name: groupName,
          x: dataPoint.x,
          y: dataPoint.y,
          mode: 'markers',
          type: 'scatter',
          showlegend: false,
          marker: {
            color: dataPoint.color,
            size: dataPoint.marker,
          },
        });
      });
    },

    getMappingStrategy(cvsTable, mappingDef) {
      let resolver = null;
      if (mappingDef.calc) {
        const lColumnIdx = cvsTable.findColIndex(mappingDef.calc.lColumn);
        const rColumnIdx = cvsTable.findColIndex(mappingDef.calc.rColumn);
        resolver = (row) => calc(mappingDef.calc.op,
          Number(row[lColumnIdx]),
          Number(row[rColumnIdx]));
      } else {
        const colIdx = cvsTable.findColIndex(mappingDef.column);
        resolver = (row) => Number(row[colIdx]);
      }

      return {
        axisTitle: (mappingDef.title || mappingDef.column),
        resolver,
      };
    },

    getMarkerSize(markerSizeRaw, defaultMarkerSize = 20) {
      if (markerSizeRaw && this.marker.size) {
        let markerSize = Number(markerSizeRaw);
        if (!Number.isNaN(markerSize)) {
          if (this.marker.size.maxValue) {
            markerSize /= this.marker.size.maxValue;
            if (markerSize > 1) {
              markerSize = 1;
            }
          }
          if (this.marker.size.maxSize) {
            markerSize *= this.marker.size.maxSize;
          }
          if (this.marker.size.minSize && markerSize < this.marker.size.minSize) {
            markerSize = this.marker.size.minSize;
          }
          return markerSize;
        }
      }
      return defaultMarkerSize;
    },

    getMarkerColor(markerColor, defaultMarkerColor = 'gray') {
      if (markerColor && this.marker?.color?.indicator[markerColor]) {
        return this.marker.color.indicator[markerColor].style;
      }
      return defaultMarkerColor;
    },

    plotHover(event) {
      const { name } = event.points[0].fullData;

      this.layout = {
        ...this.layout,
        annotations: this.layout.annotations.map((a) => {
          let annotation = a;
          if (a.name === `${name}-arrow`) {
            annotation = { ...a, ...ARROW_STYLES.focus };
          } else if (a.name === `${name}-text`) {
            annotation = { ...a, ...TEXT_STYLES.focus };
          } else if (a.name.endsWith('-text')) {
            annotation = { ...a, ...TEXT_STYLES.blur };
          } else if (a.name.endsWith('-arrow')) {
            annotation = { ...a, ...ARROW_STYLES.blur };
          }
          return annotation;
        }),
      };
      this.traces = this.traces.map((t) => {
        if (t.name === `${name}`) {
          return { ...t, ...BUBBLE_STYLES.focus };
        }
        return { ...t, ...BUBBLE_STYLES.blur };
      });
    },
    plotUnhover() {
      this.layout = {
        ...this.layout,
        annotations: this.layout.annotations.map((a) => {
          let annotation = a;
          if (a.name.endsWith('-text')) {
            annotation = { ...a, ...TEXT_STYLES.initial };
          } else if (a.name.endsWith('-arrow')) {
            annotation = { ...a, ...ARROW_STYLES.initial };
          }
          return annotation;
        }),
      };
      this.traces = this.traces.map((t) => ({ ...t, ...BUBBLE_STYLES.initial }));
    },
  },
};

</script>
<style lang="scss" scoped>
.plot {
  background-color: white;
  display: flex;
  flex-direction: column;

  .graph {
    flex: 1;
  }
}

footer.plot-annotation {
  margin: 0 12% 6% 12%;
}

header.plot-annotation {
  margin: 6% 12% 0 12%;
}

.data-legend {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #666666;
  border-spacing: 5px;
  border-radius: 5px;
  line-height: 18px;

  .sub-legend {
    display: flex;
    flex-direction: column;
    margin: 4px;

    > .title {
      font-weight: bold;
    }

    > ul {
      display: flex;
      flex-flow: row wrap;
      list-style: none outside none;
      padding: 0;
      margin: 0;

      > li {
        list-style-type: none;
        flex: 1;
        align-self: center;
        display: flex;
        flex-flow: row nowrap;

        div {
          align-self: center;
          margin: 4px 5px;
        }
      }
    }
  }
}

</style>
