<template>
  <!-- eslint-disable max-len -->
  <div>
    <b-container>
      <StoryHeader />
    </b-container>
    <b-container>
      <b-row class="text">
        <b-col>
          <p>
            Mined products are ubiquitous, from the ring on your finger to the processor in your phone. Even the batteries and solar panels we use to produce clean energy require minerals. Extracting such minerals delivers economic growth, but it also has negative environmental and social impacts, especially on people who live – and make a living – in and around areas with active mining.
          </p>
          <h2 class="section-header">Two sides of the same coin</h2>
          <p>
            The positive impacts of mining include the income generated by the sector, whether in the form of profits generated for those invested in the sector, revenue collected by the government, or salaries and wages earned by those who work in mining. Moreover, by spending this income within their communities, miners support the livelihoods of others who sell goods and services to them.
          </p>
          <p>
            But if poorly managed, mining can degrade and pollute the environment and disrupt the lives and livelihoods of local communities. This includes economic disruption, where people can no longer sustain their usual economic activities – or it can involve displacement, where the disruption is such that those affected have no choice but to relocate. The effects of mining may also lead to conflict between different social groups.
          </p>
        </b-col>
      </b-row>
    </b-container>
    <overview-video></overview-video>
    <b-container>
      <b-row class="text">
        <b-col>
          <p>
            Global demand for these resources has increased over the past decades and Ethiopia has been able to benefit by opening the sector to foreign and domestic private investors. To produce minerals, these investors have applied industrial methods that require substantial capital inputs.
          </p>
        </b-col>
      </b-row>
      <b-row class="text">
        <b-col>
          <details>
            <summary>Industrial mining in Ethiopia</summary>
            <div>
              <p>
                Since the turn of the century, the federal government has sought to expand industrial mining operations by attracting foreign investment. It has pursued legal and regulatory reforms and granted far more exploration and mining licences than before.
              </p>
              <p>
                Industrial mines are important for the government because they conduct proper exploration, produce at scale, and deliver foreign exchange to the economy as well as government revenue to state coffers. In addition, they offer employment opportunities, especially for people with technical and managerial skills.
              </p>
              <p>
                If done well and responsibly, industrial operations seek to minimize negative environmental and social impacts. Unfortunately, some have left negative legacies. The most prominent case is that of the Lega Dembi mine, where the use of mercury to process gold ore has exposed several communities in the surrounding landscape to devastating health impacts.
              </p>
              <b-img
                src="@/assets/blog/ethiopia_story/images/ETH_legadembi_landscape.jpg"
                alt="Lega Dembi mine in Landscape"
                v-bind="defaultImgProps"
              >
              </b-img>
              <p class="caption-dark">
                In the case of the Lega Dembi mine, the use of mercury to process gold ore has left several communities in the surrounding landscape exposed to terrible health impacts.
              </p>
            </div>
          </details>
        </b-col>
      </b-row>
      <b-row class="text">
        <b-col>
          <p>
            Ethiopia’s income from gold exports has increased substantially in the past two decades. In 2016, gold accounted for over 80% of the official value of the country’s production of minerals and metals. Still, the income from gold fluctuates considerably. Among the reasons for this is a halt in operations in the industrial Lega Dembi mine due to environmental concerns. Foreign exchange limitations have also contributed to production challenges, at times preventing the import of vital production inputs.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <gold-exports></gold-exports>
        </b-col>
      </b-row>
      <b-row class="text">
        <b-col>
          <p>
            But mining in Ethiopia is not only industrial. Prospecting individuals and small cooperatives hoping to strike gold have been around in Ethiopia for centuries. Sluicing and panning in streams, they look for even the smallest nuggets. So small, they are ideally suited for smuggling and therefore likely not fully represented in export statistics. It is on artisanal mining that we now focus.
          </p>
        </b-col>
      </b-row>
    </b-container>
    <miners-video></miners-video>
    <b-container class="my-5">
      <b-row class="text">
        <b-col>
          <p>
            Let us now turn to the procedure by which artisanal miners set about mining gold.
          </p>
        </b-col>
      </b-row>
    </b-container>
    <artisanal-video></artisanal-video>
    <b-container>
      <b-row class="text">
        <b-col>
          <p>
            In contrast to industrial mining, the benefits generated through artisanal mining largely remain with the local population. However, artisanal mining can set off a series of negative impacts on nearby communities.
          </p>
          <p>
            Artisanal mining can lead to or aggravate conflicts, particularly if the miners themselves do not belong to the downstream communities that are suffering the negative effects. These conflicts can escalate and result in violent clashes. And even if seasonal miners are part of local communities, the environmental impacts their activities cause and the profits they make from mining affect community members in different ways. For example, water pollution may force women and children to fetch clean water from further away. Water shortages may cause miners’ agricultural livelihood activities to become more cumbersome and less productive. Interlinkages in local livelihood systems mean that activities from one source of income may harm another.
          </p>
          <p>
            Choices on how to spend money made from mining matter too. Having additional income has been noted in some contexts to lead to drinking, gambling, and prostitution, which could leave some members of society, especially women and children, more vulnerable and exposed to abuse and communicable and sexually transmitted diseases. Revenue generated from artisanal mining may also contribute to funding violent conflicts.
          </p>
        </b-col>
      </b-row>
    </b-container>
      <image-break>
          <b-img
            src="@/assets/blog/ethiopia_story/images/eth_pan_temps_web.jpg"
            alt="Ethiopian artisanal miner"
            v-bind="defaultImgProps"
          ></b-img>
        <template v-slot:caption>
          <small>Photo: Dietmar Temps / Shutterstock.com</small>
        </template>
      </image-break>
    <b-container>
      <b-row  class="text">
        <b-col>
          <p>
            Governing the mining sector is usually the responsibility of the national government. What matters is how well different government authorities manage the sector and coordinate responses to negative impacts across diverse ministerial or administrative departments.
          </p>
          <p>
            Sector governance can be more complex if a country is organized in a federalist manner, as is the case in Ethiopia. It typically means that several authorities are involved in granting licenses and ensuring that conditions and responsibilities associated with granting mining rights are adhered to.
          </p>
          <p>
            Governing the artisanal mining sector can be especially difficult. Artisanal miners operate
            <em>ad hoc</em> and licence granting authorities may not recognize, or have the means to respond to, negative impacts on land and people. Different political views and concerns may mar information sharing and coordination between different national and sub-national authorities. In addition, sub-national authorities are often more limited in their technical capacity to assess and address impacts.
          </p>
          <p>
            As the sector continues to grow, it is important for the Ethiopian authorities to assess and balance the opportunities and challenges that both industrial and artisanal mining bring for the country and its people. Not least in relation to Ethiopia’s progress towards achieving the Sustainable Development Goals.
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="mt-3 bg-dark text-white pt-0">
        <b-container class="py-5 my-0">
            <b-row class="text">
                <b-col>
                  <!-- Section End Matter -->
                  <p><a href="https://ethiopian.portal.miningcadastre.com/MapPage.aspx?PageID=a16a9300-7c58-4f81-9e5c-97e13b22b0c6" target="_blank" rel="noopener noreferrer" class="dark-link">Explore all mining activity in the Ethiopian national mining cadastre</a></p>
                  <p><a href="https://www.thenewhumanitarian.org/investigation/2020/05/27/Ethiopia-Oromia-Shakiso-gold-mine-health-problems" target="_blank" rel="noopener noreferrer" class="dark-link">Learn more about the legacy effects of mercury pollution around the Lega Dembi mine</a></p>
                  <p><a href="https://eiti.org/files/documents/artisana_mining_3_0.pdf" target="_blank" rel="noopener noreferrer" class="dark-link">Learn more about Ethiopian artisanal miners in the EEITI artisanal mining report</a></p>
                  <h5 style="margin-top: 3rem; margin-bottom: 1rem">Credits</h5>
                  <p>Text: Evelyn Dietsche, Ruben Carlo Bünder, Sarah Turki and Manuel Abebe</p>
                  <p>Editing: Tina Hirschbuehl</p>
                  <p>Production: Manuel Abebe and Nicola Jordan</p>
                  <small>Title image: Dietmar Temps / Shutterstock.com</small>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
    <b-container class="py-0 my-0">
      <b-row class="no-gutters my-0 py-0">
        <!-- Links -->
        <b-col cols="6">
          <a href="/kenya-story" class="link-field">
            <div style="background: radial-gradient(circle, #8ac87d, #7cb86f)">
              <div>
                <h3>Highway to Health?</h3>
                <p>Learn about the effects of highway construction in Northern Kenya in our other case study</p>
              </div>
            </div>
          </a>
        </b-col>
        <b-col cols="6">
          <a href="/playground" class="link-field">
            <div style="background: radial-gradient(circle, #dcb446, #c6aa5d)">
              <div>
                <h3>Playground</h3>
                <p>Test your knowledge of the case study in the playground</p>
              </div>
            </div>
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// for cross-browser support
import 'intersection-observer';
// local registration in this example, can also be globally registered
import environment from '../../../environment';
import OverviewVideo from './OverviewVideo.vue';
import MinersVideo from './MinersVideo.vue';
import GoldExports from './GoldExports.vue';
import StoryHeader from './StoryHeader.vue';
/* eslint-disable */
import ArtisanalVideo from './ArtisanalVideo.vue';
/* eslint-enable */
import ImageBreak from '../kenya_story/ImageBreak.vue';

export default {
  components: {
    OverviewVideo,
    MinersVideo,
    GoldExports,
    ArtisanalVideo,
    ImageBreak,
    StoryHeader,
  },
  name: 'ethiopia-story',
  data() {
    return {
      videoDuration: null,
      defaultImgProps: {
        fluid: true,
      },
      sources: [
        // {
        // src: `${environment.assetsURL}/blog/
        // scrolly_telling_example/ETH_overviewMap_animation25fps.webm`,
        // type: 'video/webm',
        // },
        {
          src: `${environment.assetsURL}/blog/ethiopia_story/ETH_overviewMap_animation24.webm`,
          type: 'video/webm',
        },
      ],
      slides: [
        {
          title: 'A wealth of resources',
          content: 'Located on the outer reaches of the Arabian-Nubian Shield, Ethiopia is well-endowed with a broad range of mineral resources.',
        },
        {
          empty: true,
        },
        {
          empty: true,
        },
        {
          title: '',
          content: 'In the Northern Belt, gold is the dominant metallic mineral. Potash and salt mining also take place.',
        },
        {
          empty: true,
        },
        {
          title: '',
          content: 'In the Western and Southwestern Belt, there are gold deposits, but also platinum, iron, and base metals.',
        },
        {
          empty: true,
        },
        {
          title: '',
          content: 'In the Southern Belt, the main known mineral deposits are gold, nickel, and tantalum.',
        },
      ],
    };
  },
  methods: {
    playerReady({ duration }) {
      this.player = this.$refs.videoPlayer;
      this.videoDuration = duration;
    },
    stepProgressHandler({ index, progress }) {
      this.changeFrame(index, progress);
    },
    changeFrame(index, progress) {
      if (!this.player) {
        return;
      }
      this.playbackStepFactor = this.videoDuration / this.slides.length;
      const newFrameNumber = (index + progress) * this.playbackStepFactor;
      if (this.videoDuration < this.frameNumber) {
        this.frameNumber = this.videoDuration;
      } else {
        this.frameNumber = newFrameNumber;
      }
      this.player.playTo(this.frameNumber);
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.video-container {
  position: sticky;
  top: 10px;
}
figure {
  position: absolute;
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity 800ms;
}
figure img {
  min-width: 100%;
  min-height: 100%;
}
video.active {
  visibility: visible;
  opacity: 1;
}
.slides {
  z-index: 10;
  position: relative;
}
.slide {
  height: 100vh;
  margin: 0 auto;
  max-width: 300px;
  color: white;
}
details {
  background-color: whitesmoke;
  border: solid 1px darkgray;
  padding: 1rem;
}
details div {
  margin-top: 2rem;
}
.jmb .image {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*  object-fit is not supported on IE  */
  object-fit: cover;
  opacity: 0.8;
}
.jmb {
  z-index: 2;
  position: relative;
  height: 100%;
}
.text {
  width: 70%;
  margin: auto;
  margin-top: 2rem;
}
.section-header {
  font-style: italic;
  font-weight: bold;
  font-size: 16pt;
  color: #505c66;
}
.caption-dark {
  color: #111;
  font-size: 10pt;
  text-align: center;
}
.dark-link {
  color: #ddd;
  text-decoration: underline;
  text-decoration-color: #e1a578;
}

.dark-link:hover {
  text-decoration-thickness: 1.5pt;
}

.dark-link:visited {
  color: #bbb;
}

.link-field,
.link-field:hover {
  color: inherit;
  text-decoration: inherit;
}

.link-field > div {
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
}

.link-field > div:hover {
  box-shadow: inset 0 0 15rem -12rem #000;
}

.link-field > div > div > * {
  width: 80%;
  margin: auto;
  color: #fff;
}

</style>
