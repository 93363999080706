<template>
  <b-container fluid class="mt-3 h-100 d-inline-block">
    <b-row class="h-100">
      <b-col class="main-content-height overflow-auto d-inline-block" :cols="searchColumnsCount">
        <b-spinner v-if="!loaded" />

        <b-input-group prepend="Search">
          <b-form-input
            type="search"
            v-model="search"
            autocomplete="on"
          >
          </b-form-input>
          <b-input-group-append>
            <b-input-group-text>
              <b-icon icon="x" @click="search=''" />
            </b-input-group-text>
            <b-button @click="forcedDataReload" v-b-tooltip.hover title="Force Data Reload">
              <b-icon icon="arrow-counterclockwise"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <b-card
          v-for="(items, key, index) in groupedGISData"
          :key="key"
          no-body
        >
          <b-button v-b-toggle :href="`#${key}`" variant="primary" @click.prevent>
              <b-badge variant="secondary" class="float-right">
                {{ items.length }}
              </b-badge>
              {{ key }}
          </b-button>
          <b-collapse :visible="index===0" :id="key">
            <b-container
              class="m-0 p-0"
            >
              <b-list-group-item>
                <gis-data-item
                  v-for="item in items"
                  :key="item.id"
                  :panToLocation="panToLocation"
                  :item="item"
                  :selected="item.selected"
                />
              </b-list-group-item>

            </b-container>
          </b-collapse>
        </b-card>

      </b-col>

      <b-col class="main-content-height">
        <map-view
          :extra-props="{class: 'h-100'}"
          :zoom="7"
          @mapReady="mapLoaded"
          :querying="queryModeOn"
          :clickHandler="handleMapClick"
        >
          <l-control-layers
            ref="layer-control-widget"
            position="topright"
          />

          <l-tile-layer
            v-for="tileProvider in baseLayers"
            :key="tileProvider.id"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"
            :options="{
              maxNativeZoom: 17,
            }"
          ></l-tile-layer>

          <l-layer-group ref="features" name="features">
            <gis-data-map-layer
              v-for="item in filteredGISData"
              :key="`${item.id}-layer-view`"
              :visible="item.showDataOnMap"
              :item="item"
              :leafletMapObject="map"
              showLayer
            />
          </l-layer-group>
          <l-layer-group ref="info">
            <LGeoJson
              v-for="geoJsonObject in infoGeoJsonList"
              :geojson="geoJsonObject"
              :options="geoJsonInfoOptions"
              :key="geoJsonObject.id"
            />
          </l-layer-group>
          <LControl position="bottomleft">
            <b-button
              variant="light"
              size="lg"
              title="query active layers"
              @click="toggleQuery"
              :pressed="queryModeOn"
            >
              <b-icon
                scale="1.2"
                icon="question-circle-fill"
                aria-label="query active layers"
              ></b-icon>
            </b-button>
          </LControl>

          <LControl position="bottomright" @click.prevent>
            <b-button
              style="width: 100%"
              size="lg"
              variant="light"
              v-show="!showLegend"
              @click="toggleLegend"
              title="show Legend"
            >
              <b-icon scale="1.2" icon="box-arrow-left" />
            </b-button>
          </LControl>
        </map-view>
      </b-col>
    </b-row>

    <b-sidebar
      title="Legend"
      shadow
      right
      no-close-on-esc
      no-header
      :visible="showLegend"
      class="overflow-auto"
    >
      <slot name="header">
        <b-button
          style="width: 100%"
          class="p-3"
          size="sm"
          title="Close Legend"
          @click="showLegend = false"
        >
          <b-icon icon="box-arrow-right" />
        </b-button>
      </slot>

      <div v-if="layerLegendImages.length">
        <b-card
          class="m-2"
          variant="light"
          v-for="{ title, src, id } in layerLegendImages"
          :key="id"
        >
          <b-card-text>
            {{ title }}
          </b-card-text>
          <b-img
            :src="src"
          />
        </b-card>
      </div>
      <b-card
        v-else
        variant="light"
      >
        <b>No legends to display.</b>
        This can either be because no layer showed on the map (eye-icon on the right side)
        or the selected layers do not have a legend.
      </b-card>
    </b-sidebar>

  </b-container>
</template>

<script>

import MapView from '@/components/map-viewer/MapView.vue';
import GisDataItem from '@/components/gis-data/GisDataItem.vue';
import GisDataMapLayer from '@/components/gis-data/GisDataMapLayer.vue';
import {
  LTileLayer,
  LControlLayers,
  LGeoJson,
  LControl,
  LLayerGroup,
} from 'vue2-leaflet';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axiosBase from '@/api/AxiosBase';

export default {
  name: 'map-viewer',
  components: {
    GisDataMapLayer,
    MapView,
    LTileLayer,
    LControlLayers,
    GisDataItem,
    LGeoJson,
    LControl,
    LLayerGroup,
  },
  data() {
    return {
      search: '',
      checked: false,
      isOpen: false,
      layers: [],
      gisData: [],
      infoGeoJsonList: [],
      showOnlyExtent: true,
      map: undefined,
      baseLayers: [
        {
          id: 'disabled',
          name: 'Disable Background',
          visible: false,
          url: '',
        },
        {
          id: 'Default OSM',
          name: 'Default OSM',
          visible: true,
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution:
            '© <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
        },
        {
          id: 'humanitarian',
          name: 'Humanitarian OSM',
          visible: false,
          url: 'https://tile-a.openstreetmap.fr/hot/{z}/{x}/{y}.png',
          attribution:
            '© <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>. Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>. <a href="https://wiki.osmfoundation.org/wiki/Terms_of_Use" target="_blank">Website and API terms</a>',
        },
        {
          id: 'OpenTopoMap',
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
      ],
      colorMap: [],
      queryModeOn: false,
      showLegend: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadData: 'mapdata/loadData',
      addInfoToast: 'toasts/addInfoToast',
    }),
    ...mapMutations({
      disableQueryMode: 'mapdata/disableQueryMode',
    }),
    forcedDataReload() {
      this.loadData({ force: true });
    },
    mapLoaded(mapObject) {
      this.map = mapObject;
    },
    getCenter(item) {
      const { center } = item;
      if (center && center.coordinates) {
        const coordinates = [center.coordinates[1], center.coordinates[0]];
        return coordinates;
      }
      return null;
    },
    panToLocation(item) {
      const center = this.getCenter(item);
      if (center) {
        this.map.panTo(center);
      }
    },
    showLayer(layerOption) {
      this.activeLayer = layerOption;
    },
    async handleMapClick({ latlng }) {
      if (!this.queryModeOn) {
        return;
      }
      // eslint-disable-next-line no-underscore-dangle
      const zoom = this.map._zoom;
      this.infoGeoJsonList = [];

      if (this.enabledLayers.length === 0) {
        this.addInfoToast({ message: 'no active layers to query found. Try enabling more using the eye icon.' });
        return;
      }

      await await Promise.all(this.enabledLayers.map(async (layer) => {
        const data = await this.queryLayer({ layer, latlng, zoom });
        if (data) {
          data.features.forEach((d) => {
            if (!d.geometry) {
              // eslint-disable-next-line no-param-reassign
              d.geometry = {
                type: 'Point',
                coordinates: [latlng.lng, latlng.lat],
              };
            }
          });
          this.infoGeoJsonList.push(data);
        }
      }));
      if (this.infoGeoJsonList.length === 0) {
        this.addInfoToast({ message: 'No features found, maybe too far away from a queriable layer?' });
      }
    },
    async queryLayer({ layer, latlng, zoom }) {
      if (layer && layer.wms && layer.wms.feature_info_url) {
        // ugly and dangerous replacement in url, but time is of the essence now...
        let url = layer.wms.feature_info_url.replace('/api/v1', '');
        url = `${url}${latlng.lat},${latlng.lng}&zoom=${zoom}`;
        const result = await axiosBase.get(url);
        if (result.data?.features?.length) {
          return result.data;
        }
      }
      return null;
    },
    toggleQuery() {
      this.queryModeOn = !this.queryModeOn;
      if (!this.queryModeOn) {
        this.geoson = null;
      }
    },
    toggleLegend() {
      this.showLegend = !this.showLegend;
    },
  },
  computed: {
    ...mapGetters({
      loaded: 'metadata/loaded',
      fetchedGISData: 'metadata/gisData',
      filteredData: 'metadata/filteredData',
      wrappedGisData: 'mapdata/wrappedGisData',
    }),
    searchColumnsCount() {
      return 4;
    },
    filteredGISData() {
      if (!this.wrappedGisData) {
        return [];
      }
      if (!this.search) {
        return this.wrappedGisData;
      }
      return this.wrappedGisData.filter(
        (item) => (
          item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1),
      );
    },
    groupedGISData() {
      const grouped = this.filteredGISData.reduce((r, a) => {
        // eslint-disable-next-line no-param-reassign
        r[a.category] = [...r[a.category] || [], a];
        return r;
      }, {});
      const groupOrdered = Object.keys(grouped).sort(
        (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
      ).reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = grouped[key];
        return obj;
      }, {});
      return groupOrdered;
    },
    geoJsonInfoOptions() {
      return {
        color: '#7C00FF',
        onEachFeature: (feature, layer) => {
          let htmlPopupContent = '<table>';
          if (feature.properties) {
            Object.entries(feature.properties).forEach(([key, value]) => {
              htmlPopupContent += `<tr><td>${key}</td><td>${value}</td></tr>`;
            });
            htmlPopupContent += '</table>';
            const popup = layer.bindPopup(htmlPopupContent);
            this.$nextTick(() => popup.openPopup());
          }
        },
      };
    },
    enabledLayers() {
      return this.filteredGISData.filter((i) => i.showDataOnMap && i?.wms);
    },
    layerLegendImages() {
      if (this.enabledLayers && this.enabledLayers.length) {
        const imageList = [];
        this.enabledLayers.forEach((i) => {
          if (i.wms && i.wms.feature_legend_url) {
            imageList.push({
              id: i.id,
              src: i.wms.feature_legend_url,
              title: i.title,
            });
          }
        });
        return imageList;
      }
      return [];
    },
  },
};
</script>
