<template>
  <div>
    <b-img
      src="@/assets/blog/ethiopia_story/images/eth-gold-exports-chart.svg"
      alt="Gold export value in Million USD per year from 1998 to 2018"
      v-bind="defaultImgProps"
      style="pointer-events: none"
    ></b-img>
    <div class="caption-dark">
      Annual gold export volume in millions of USD for Ethiopia.
      <small><cite title="Source Title">Source: UN COMTRADE.</cite></small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'gold-exports',
  data() {
    return {
      defaultImgProps: {
        fluid: true,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
div {
  margin: auto;
  width: 80%;
}
.caption-dark {
  color: #111;
  font-size: 10pt;
  text-align: center;
}
</style>
