<template>
<div>
  <b-container>
    <h1>SDG interactions between Health, Land use, and Conflict</h1>
    <p>The matrix illustrates interactions among the three Sustainable Development Goals (SDGs) 3
    (Good Health and Well-Being), 15 (Life on Land), and 16 (Peace, Justice and Strong
    Institutions) and their targets.</p>
  </b-container>

  <b-container>
    <b-row>
      <b-col>
        <p>
          It builds on
        the <a href="https://datablog.cde.unibe.ch/index.php/2019/08/29/sdg-interactions/" target="_blank" rel="noopener noreferrer">Knowledge
        for Sustainable Development</a> collected in the context of
        the <a href="https://sustainabledevelopment.un.org/gsdr2019" target="_blank" rel="noopener noreferrer"> Global Sustainable
        Development Report 2019</a>. We then added information on the interactions between the
        three SDGs and their targets for the Ethiopian and Kenyan context based on literature and
        expert knowledge. The interactions we identified, however, are not exhaustive and may alter
        by changing geographic and temporal scope or scale and by considering additional or new
        information.
        </p>
        <p>
          This matrix serves explorative, inspirational, and educational purposes. The 2030 Agenda
          for Sustainable Development balances the three (social, environmental, and economic)
          dimensions of sustainable development. From SDG 1 (on <i>poverty</i>) to SDG 13
          (on <i>climate</i>), to SDG 5 (on <i>gender</i>), the 17 goals cover a broad range of
          critical development areas and are interdependent and interconnected with each other,
          implying systemic synergies or conflicts. A better understanding of these complex
          interactions therefore provides entry points to maximize the potential co-benefits when
          action towards one goal furthers progress on one or many others.
        </p>
      </b-col>
    </b-row>

  </b-container>

  <SDGInteractionsDiagram/>

  <b-container>
    <b-row>
      <b-col>
        <p>
          More on CDE’s work on SDG interactions:
          <br>
          <br>
          <ul>
            <li>Nilsson M, Chisholm E, Griggs D, et al (2018) Mapping interactions between the
              sustainable development goals: lessons learned and ways forward. Sustainability
              Science
              13:1489–1503. <a href="https://doi.org/10.1007/s11625-018-0604-z">https://doi.org/10.1007/s11625-018-0604-z</a></li>
            <li>Ehrensperger A, de Bremond A, Providoli I, Messerli P (2019) Land system science
              and the 2030 agenda: exploring knowledge that supports sustainability
              transformation.
              Current Opinion in Environmental Sustainability 38:68–76.
              <a href="https://doi.org/10.1016/j.cosust.2019.04.006">https://doi.org/10.1016/j.cosust.2019.04.006</a></li>
            <li>Pham-Truffert M, Rueff H, Messerli P (2019) Knowledge for Sustainable Development:
              Interactive repository of SDG interactions. In: CDEdatablog.
              <a href="https://datablog.cde.unibe.ch/index.php/2019/08/29/sdg-interactions/">
                https://datablog.cde.unibe.ch/index.php/2019/08/29/sdg-interactions/</a></li>
            <li>Independent Group of Scientist appointed by the Secretary-General (2019) Global
              Sustainable Development Report 2019: The Future is Now – Science for Achieving
              Sustainable Development. United Nations, New York.
              <a href="https://sustainabledevelopment.un.org/gsdr2019">https://sustainabledevelopment.un.org/gsdr2019</a></li>
            <li>Pham-Truffert M, Metz F, Fischer M, et al (2020) Interactions among Sustainable
              Development Goals: Knowledge for identifying multipliers and virtuous cycles.
              Sustainable Development 28:1236–1250.
              <a href="https://doi.org/10.1002/sd.2073">https://doi.org/10.1002/sd.2073</a></li>
            <li>Breu T, Bergöö M, Ebneter L, et al (2020) Where to begin? Defining national
              strategies for implementing the 2030 Agenda: the case of Switzerland. Sustain Sci.
              <a href="https://doi.org/10.1007/s11625-020-00856-0">https://doi.org/10.1007/s11625-020-00856-0</a></li>
            <li>Obrecht A, Pham-Truffert M, Spehn E, et al (2021) Achieving the SDGs with
              Biodiversity. Swiss Academies Factsheet 16 (1).
              <a href="https://doi.org/10.5281/zenodo.4457298">https://doi.org/10.5281/zenodo.4457298</a></li>
          </ul>
        </p>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>
<script>
import SDGInteractionsDiagram from './SDGInteractionsDiagram.vue';

export default {
  components: {
    SDGInteractionsDiagram,
  },
  name: 'sdg-interactions',
};
</script>
