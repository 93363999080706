<template>
  <!-- eslint-disable max-len -->
  <!-- All text content is based on Version April 28 sent by MP -->
  <div>
    <sdg-indicator
      style="margin-top: 100px;"
      :visible="indicatorVisible"
      :sdgActiveStati="SDGstatus"
    />
    <b-container class="mt-2">
      <story-header
        v-b-visible="(vis) => storyHeaderVisible = vis"
      ></story-header>

      <!-- <span v-b-visible="(vis) => indicatorVisible = !vis" /> -->
    </b-container>

    <!-- Section 1: Introduction -->
    <b-container class="mt-4">
      <b-card no-body bg-variant="transparent" border-variant="grey">
        <b-card-body>
          <h2 class="section-header">Paving the road to a brighter future?</h2>
          <span v-b-visible="(vis) => introductionVisible = vis">
            <introduction></introduction>
            <sdg-diagram></sdg-diagram>
          </span>
        </b-card-body>
      </b-card>
    </b-container>

    <!-- Section 2: Introduction Kenya/LAPSSET -->
    <span v-b-visible="(vis) => introductionLapsetVisible = vis">
      <b-container class="mt-4 mb-0">
        <h2 class="section-header">New connections</h2>
        <laps-set></laps-set>
      </b-container>
      <image-break>
        <b-img
          src="@/assets/blog/kenya_story/images/KEN_highway-photo-2.jpg"
          alt="LAPSSET highway"
          v-bind="defaultImgProps"
        >
        </b-img>
        <template v-slot:caption>
          The LAPSSET highway provides people in the area with a more direct connection and shorter travel times.
          <br>
          <small>Photo: Unsplash/antonytrivet</small>
        </template>
      </image-break>
    </span>

    <!-- Section 3: Land Use Changes-->
    <span v-b-visible="(vis) => landUseChangesVisible = vis">
      <b-container>
        <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="2">
          <b-col class="align-self-center">
            <h2 class="section-header">Ripple effects on a rural landscape</h2>
            <p>
              Construction of such a highway can have direct and indirect impacts on land.
              Direct, because a substantial land corridor is being covered with a sealed surface. And indirect, as changes in the local landscape are observed over time.
              Historically, nomadic pastoralism was the most important source of livelihood and a marker of cultural identity in this region. But there are signs that pastoralists are increasingly changing their way of life.
            </p>
            <p>
              For one, the extent of grassland is shrinking (down 5.8% between 2007 and 2019).
              At the same time, the highway has improved market access, providing an incentive to grow food for sale. We can observe an increase in agricultural land use, with new fields emerging around growing population centres in particular.
            </p>
          </b-col>
          <b-col>
            <agriculture-comparison></agriculture-comparison>
          </b-col>
        </b-row>
      </b-container>

      <!-- Nicola: Bitte Bild & Caption responsive machen, plus Caption sollte links and Bild sein -->
      <image-break>
        <b-img
          src="@/assets/blog/kenya_story/images/KEN_degradation-gully.jpg"
          alt="Gully formation in a degraded shrubland with scattered trees"
          v-bind="defaultImgProps"
        ></b-img>
        <template v-slot:caption>
          Sustained land degradation in shrubland areas contributes to the
          formation of gullies, preventing future generations from using
          the land.
          <br>
          <small>Photo: &copy; CDE / K. Hurni</small>
        </template>
      </image-break>
    </span>

    <span v-b-visible="(vis) => conflictsVisible = vis">
      <b-container>
        <!-- Section 4: Conflicts -->
        <b-row>
          <b-col class="align-self-center">
            <h2 class="section-header">Challenge to age-old customs</h2>
            <p>
              Changes in land use affect social structures. Livelihoods of pastoralist communities have historically been under pressure, exacerbated today by the gradual loss of grazing land due to climate change, overgrazing of pastures, and expansion of settlements and industrial lands.
            </p>
            <p>
              Construction of the highway may have accelerated some of these changes, triggering further intra- and inter-community conflicts. But there have been positive effects too. Fewer conflicts are being reported thanks to better access to conflict hotspots for security forces and more livelihood opportunities as alternatives to livestock keeping.
            </p>
          </b-col>
          <b-col>
            <b-img
              src="@/assets/blog/kenya_story/images/KEN_cattlehighway.jpg"
              alt="Cattle on LAPSSET highway"
              v-bind="defaultImgProps"
            ></b-img>
            <small>Photo: &copy; CETRAD / B. Kiteme</small>
          </b-col>
        </b-row>
      </b-container>
    </span>

    <span v-b-visible="(vis) => conflictsHealthNexusVisible = vis">
      <b-container>
        <!-- Section 5: Implications of conflicts on health and well-being -->
        <b-row class="mt-5">
          <b-col cols="3 align-self-center">
            <h2 class="section-header">The daily strain of conflict</h2>
            <p>
              Isiolo was described as a hotspot for violent conflicts among pastoralist communities and between pastoralists and other land users. Addressing these conflicts would promote the physical and mental well-being of directly affected communities and the wider well-being in the region.            </p>
          </b-col>
          <b-col sm="9">
            <b-img
              src="@/assets/blog/kenya_story/images/KEN_village.jpg"
              alt="Image conflict"
              v-bind="defaultImgProps"
            ></b-img>
            <small>Photo: &copy; CETRAD / B. Kiteme</small>
          </b-col>
        </b-row>
      </b-container>
      <!-- Section Break Image -->
      <image-break>
          <b-img
            src="@/assets/blog/kenya_story/images/highway_mountain.jpg"
            alt="Mountain along LAPSSET highway"
            v-bind="defaultImgProps"
          ></b-img>
        <template v-slot:caption>
          Shortly after leaving Isiolo, the new highway passes Mt. Ololokwe, whose name translates as sacred mountain, in Samburu County.
          <br>
          <small>Photo: Unsplash/kahiriken</small>
        </template>
      </image-break>
    </span>

    <span v-b-visible="(vis) => healthLandUseVisible = vis">
    <b-container class="mt-5">
      <!-- Section 6: Direct Health implications -->
      <health-implications></health-implications>
    </b-container>
    </span>
    <span v-b-visible="(vis) => healthImplicationsVisible = vis">
    <b-container class="mt-5">
        <!-- Section 7: Indirect health implications -->
        <h2 class="section-header">Highway to health</h2>
        <p>
          Beyond direct access to health facilities, the highway is indirectly triggering other health-related changes, both positive and negative. At household level, these include positive developments in terms of access to water, sanitation, and sustainable energy. At community level, a rapid increase in population density can have negative impacts.        </p>
        <indirect-health-implications-aggregated></indirect-health-implications-aggregated>
      </b-container>
    </span>

    <span v-b-visible="(vis) => outlookVisible = vis">
      <b-container class="mt-5">
        <!-- Section 8: Outlook -->
        <b-row>
          <b-col>
            <p>
              To conclude, these are just a few examples of how a large infrastructure project – in this case, construction of a highway in northern Kenya – can trigger environmental, economic, and social changes that have the potential to affect human health. As we have seen, the three SDGs of our focus – 15, 16, and 3 – interact through various pathways. These interactions are not only limited to the context of highway construction, but also apply in many other settings.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </span>
    <b-container fluid class="mt-3 bg-dark text-white pt-0">
        <b-container class="py-5 my-0">
            <b-row>
                <b-col>
                    <!-- Section End Matter -->
                    <p>
                      <a
                        href="/assets/blog/kenya_story/COFER_Academy-2030_case-study-report_SwissTPH.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="dark-link"
                      >
                        Read the full case study report
                      </a> to find out more about the highway.</p>
                    <p><a href="https://www.lapsset.go.ke/" target="_blank" rel="noopener noreferrer" class="dark-link">Explore the website of the LAPSSET Development Authority</a></p>
                    <h5 style="margin-top: 3rem; margin-bottom: 1rem">Credits</h5>
                    <p>Text: Dominik Dietler, Priska Ammann, Mirko Winkler, Jasmina Saric</p>
                    <p>Editing: Tina Hirschbuehl</p>
                    <p>Production: Manuel Abebe and Nicola Jordan</p>
                    <small>Title image: Timelapse made using Google Street View Imagery</small>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
    <b-container class="py-0 my-0">
      <b-row class="no-gutters my-0 py-0">
        <!-- Links -->
        <b-col cols="6">
          <a href="/ethiopia-story" class="link-field">
            <div style="background: radial-gradient(circle, #8ac87d, #7cb86f)">
              <div>
                <h3>Streams of Opportunities?</h3>
                <p>Learn about Ethiopian gold miners in our other case study</p>
              </div>
            </div>
          </a>
        </b-col>
        <b-col cols="6">
          <a href="/playground" class="link-field">
            <div style="background: radial-gradient(circle, #dcb446, #c6aa5d)">
              <div>
                <h3>Playground</h3>
                <p>Test your knowledge of the case study in the playground</p>
              </div>
            </div>
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SdgIndicator from '@/components/base/SdgIndicator.vue';
import StoryHeader from './StoryHeader.vue';
import Introduction from './Introduction.vue';
import SdgDiagram from './SDGDiagram.vue';
import LapsSet from './LapsSet.vue';
import HealthImplications from './HealthImplications.vue';
import ImageBreak from './ImageBreak.vue';
import IndirectHealthImplicationsAggregated from './IndirectHealthImplicationsAggregated.vue';
import AgricultureComparison from './AgricultureComparison.vue';

export default {
  components: {
    StoryHeader,
    Introduction,
    SdgDiagram,
    LapsSet,
    HealthImplications,
    SdgIndicator,
    ImageBreak,
    IndirectHealthImplicationsAggregated,
    AgricultureComparison,
  },
  name: 'story-kenya',
  data() {
    return {
      indicatorVisible: true,
      storyHeaderVisible: false,
      introductionVisible: false,
      introductionLapsetVisible: false,
      landUseChangesVisible: false,
      conflictsVisible: false,
      conflictsHealthNexusVisible: false,
      healthLandUseVisible: false,
      healthImplicationsVisible: false,
      outlookVisible: false,
      defaultImgProps: {
        fluid: true,
      },
    };
  },
  computed: {
    SDGstatus() {
      const headerSDGs = {
        sdg3Active: false,
        sdg15Active: false,
        sdg16Active: false,
      };
      const introSDGs = {
        sdg3Active: true,
        sdg15Active: true,
        sdg16Active: true,
      };
      const introductionLapsetSDGs = {
        sdg3Active: true,
        sdg15Active: true,
        sdg16Active: true,
      };
      const landUseChangesSDGs = {
        sdg3Active: false,
        sdg15Active: true,
        sdg16Active: false,
      };
      const conflictsSDGs = {
        sdg3Active: false,
        sdg15Active: true,
        sdg16Active: true,
      };
      const conflictsHealthNexusSGDs = {
        sdg3Active: true,
        sdg15Active: false,
        sdg16Active: true,
      };
      const healthLandUseSDGs = {
        sdg3Active: true,
        sdg15Active: true,
        sdg16Active: false,
      };
      const healthSDGs = {
        sdg3Active: true,
        sdg15Active: false,
        sdg16Active: false,
      };
      const outlookVisible = {
        sdg3Active: true,
        sdg15Active: true,
        sdg16Active: true,
      };
      if (this.storyHeaderVisible) {
        return headerSDGs;
      }
      if (this.introductionVisible) {
        return introSDGs;
      }
      if (this.introductionLapsetVisible) {
        return introductionLapsetSDGs;
      }
      if (this.landUseChangesVisible) {
        return landUseChangesSDGs;
      }
      if (this.landUseChangesVisible) {
        return landUseChangesSDGs;
      }
      if (this.conflictsVisible) {
        return conflictsSDGs;
      }
      if (this.conflictsHealthNexusVisible) {
        return conflictsHealthNexusSGDs;
      }
      if (this.healthLandUseVisible) {
        return healthLandUseSDGs;
      }
      if (this.healthImplicationsVisible) {
        return healthSDGs;
      }
      if (this.outlookVisible) {
        return outlookVisible;
      }
      return healthSDGs;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  font-style: italic;
  font-weight: bold;
  font-size: 16pt;
  color: #505c66;
}

.row {
  margin-top: 1rem;
}

.caption {
  color: #ddd;
  font-size: 10pt;
}

.caption-meta {
  color: #ddd;
  font-size: 8pt;
}

.viewport-height-90 {
  height: 90vh;
}

.viewport-height-90 img {
  height: 90vh;
}

.viewport-height-70 {
  height: 70vh;
}

.viewport-height-70 img {
  height: 70vh;
  width: auto;
}

.dark-link {
  color: #ddd;
  text-decoration: underline;
  text-decoration-color: #e1a578;
}

.dark-link:hover {
  text-decoration-thickness: 1.5pt;
}

.dark-link:visited {
  color: #bbb;
}

.link-field,
.link-field:hover {
  color: inherit;
  text-decoration: inherit;
}

.link-field > div {
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
}

.link-field > div:hover {
  box-shadow: inset 0 0 15rem -12rem #000;
}

.link-field > div > div > * {
  width: 80%;
  margin: auto;
  color: #fff;
}

</style>
