<template>
  <!-- eslint-disable max-len -->
  <b-row>
    <b-col cols="4 align-self-center">
      <p v-if="selectedMapping === 0">
        Universal access to clean water and improved sanitation are key for reducing the burden of water-borne diseases, such as diarrhoea, for household members and for the whole community.
        <br>
        Access to both water and sanitation infrastructure improved between 2009 and 2019 in the areas transected by the highway. But while these improvements are promising, overall access to improved sanitation facilities is still comparably low in the whole area. In 2019, only 51.5% of the households in the sub-counties that were impacted by the LAPSSET corridor had access to an improved sanitation facility, compared with the national averages of 75.4% for rural areas and 93.5% for urban areas.
      </p>
      <p v-if="selectedMapping === 1">
        Gaining access to electricity improves many aspects of household life. It can increase security in the neighbourhood, generate additional livelihood opportunities, and allow students to study after dusk. Above all, increased electrification of health facilities allows for more reliable provision of health services around the clock.
        <br>
        Electricity can also be used as a clean energy source for cooking to replace traditional fuels that emit harmful air pollutants, such as coal, charcoal, wood, or crop residues. These traditional fuels can cause a high burden of respiratory diseases, particularly among women and children, who spend disproportionally more time around the house than men.
        <br>
        During construction of the highway, from 2009 to 2019, the share of households connected to the electricity grid was substantially higher in areas along the highway corridor than in other areas. Similar improvements in access to clean cooking fuels were observed, although solid cooking fuels are still used in around 90 % of households in the region.
      </p>
      <p v-if="selectedMapping === 2">
        Finally, rapid population growth can affect health and well-being indirectly, as fast expanding settlements put additional strain on public infrastructure, already weak and overburdened, and on health care. A highway and associated livelihood opportunities have the potential to trigger such population growth. However, although population density and household sizes increased substantially in the whole region between 2009 and 2019, no differences were seen between the areas that are transected by the LAPSSET corridor and areas located further away.      </p>
    </b-col>
    <b-col cols="8">
      <bubble-chart
        title="Access to Infrastructure (2009 to 2019)"
        :group-by="'ID'"
        :x-axes-range="'Year'"
        :data-src="`${assetsURL}blog/kenya_story/data/census_data_LAPSSET_agg.csv`"
        :header-rows="1"
        :data-mappings="mappings"
        :marker="marker"
        v-on:selectedMappingChange="changeSelectedMapping($event)"
      />
    </b-col>
  </b-row>
</template>
<script>
import BubbleChart from '../../../widgets/BubbleChart.vue';
import environment from '../../../environment';

export default {
  components: {
    BubbleChart,
  },
  name: 'indirect-health-implications-aggregated',
  data() {
    return {
      selectedMapping: 0,
      assetsURL: environment.assetsURL,
      mappings: [
        {
          title: 'Clean water and improved sanitation',
          x: { column: 'Perc_san', title: 'Access to improved sanitation facilities (% of people)' },
          y: { column: 'Perc_wat', title: 'Access to safe water sources (% of people)' },
        },
        {
          title: 'Access to electricity',
          x: { column: 'Perc_fuel', title: 'Use of clean fuels for cooking (% of people)' },
          y: { column: 'Perc_elec', title: 'Access to electricity for lighting (% of people)' },
        },
        {
          title: 'Growth of settlements',
          x: { title: 'Population per household', calc: { lColumn: 'Population', op: '/', rColumn: 'HH' } },
          y: { column: 'Pop_dens', title: 'Population density (people/km2)' },
        },
      ],
      marker: {
        column: 'FIRST_sc_n',
        size: {
          legend: 'Population',
          column: 'Population',
          maxSize: 20,
          minSize: 5,
          maxValue: 550000,
        },
        color: {
          column: 'LAPSSET',
          legend: 'LAPSSET corridor',
          indicator: {
            0: {
              style: '#ff747a',
              title: 'Outside LAPSSET',
            },
            1: {
              style: '#17bac1',
              title: 'Within LAPSSET corridor',
            },
          },
        },
      },
    };
  },
  methods: {
    changeSelectedMapping(newMapping) {
      this.selectedMapping = newMapping;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
