<template>
  <b-container fluid="lg">
    <b-row class="mb-2">
      <b-col class="page-description">
        <b-container fluid>
          <b-row class="mt-5 mb-3">
            <b-col cols="4" class="text-center">
              <b-img
                src="@/assets/academy-2030-logo.png"
                alt="Academy 2030 Logo"
                fluid
              />
            </b-col>
            <b-col cols="8 align-self-end">
              <h5 class="mb-0">
                Knowledge, data, and education on sustainable
                development and the 2030 Agenda in Ethiopia and Kenya
              </h5>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row
      v-for="row in landingRows"
      :key="row.id"
      :style="`height: ${height}`"
      no-gutters
      cols="2"
      class="flex-nowrap"
      align-v="stretch"
    >
      <b-col
        cols="1" lg="3"
        class="p-3 static"
        :class="row.class"
      >
        <div class="h-100 d-flex justify-content-center align-items-center">
          <div class="d-none d-lg-inline">
            <h3 class="text-center font-weight-bold">{{ row.title }}</h3>
            <h5 class="text-center">{{ row.info }}</h5>
          </div>
          <div class="d-lg-none">
            <h3 class="vertical-text font-weight-bold">{{ row.title }}</h3>
          </div>
        </div>
      </b-col>
      <b-col
        cols="11" lg="9"
      >
        <b-row :cols="row.columns" no-gutters>
          <b-col
            v-for="card in row.cards"
            :key="`${row.id}-${card.title}`"
          >
            <FlipCard reactOnHover>
              <template #front>
                <div
                  class="h-100 static"
                  :style="height"
                  :class="row.class"
                >
                  <div
                    class="shadow-lg h-100 navigation-card"
                  >
                    <div>
                      <b-img
                        fluid-grow
                        :src="card.image"
                        class="p-2"
                      />
                      <h4 class="d-none d-lg-inline">{{ card.title }}</h4>
                      <h4 class="d-lg-none vertical-text">{{ card.title }}</h4>
                    </div>
                  </div>
                </div>
              </template>
              <b-link :to="card.linkTo">
                <div
                  :style="height"
                  :class="row.class"
                  class="shadow-lg h-100 d-flex navigation-card"
                >
                  <div class="p-2">
                    <p class="text-center">{{ card.text }}</p>
                  </div>
                </div>
              </b-link>
            </FlipCard>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import FlipCard from '@/components/base/FlipCard.vue';
import { paths } from '@/router';
import landingETH from '@/assets/landing/landingETH.jpg';
import landingInteractions from '@/assets/landing/landingInteractions.jpg';
import landingKEN from '@/assets/landing/landingKEN.jpg';
import landingMapViewer from '@/assets/landing/landingMapViewer.jpg';
import landingPlay from '@/assets/landing/landingPlay.jpg';
import landingResources from '@/assets/landing/landingResources.jpg';
import landingSD from '@/assets/landing/landingSD.jpg';
import landingSearch from '@/assets/landing/landingSearch.jpg';

export default {
  components: { FlipCard },
  data() {
    return {
      height: 'min-height: 20rem',
      isShow: true,
      landingRows: [
        {
          id: 'learning',
          class: 'learning',
          info: 'Learn more about Sustainable Development and the 2030 Agenda',
          title: 'Learning',
          columns: 3,
          cards: [
            {
              title: 'Education for Sustainable Development',
              image: landingSD,
              text: 'Sustainable Development and the role of education in achieving the 2030 Agenda',
              linkTo: paths.understandingSD,
            },
            {
              title: 'Highway to Health?',
              image: landingKEN,
              text: 'Learn about highway construction in Northern Kenya and how it affects local communities',
              linkTo: paths.storyKenya,
            },
            {
              title: 'Streams of Opportunity?',
              image: landingETH,
              text: 'Learn about artisanal mining in Ethiopia and the opportunities and challenges it presents',
              linkTo: paths.storyEthiopia,
            },
          ],
        },
        {
          id: 'understanding',
          class: 'understanding',
          info: 'Develop an understanding of the 2030 Agenda and how its different goals interact',
          title: 'Understanding',
          columns: 2,
          cards: [
            {
              title: 'SDG Interactions',
              image: landingInteractions,
              text: 'Understand how Health, Land use & Conflict interact towards achieving the 2030 Agenda',
              linkTo: paths.sdgInteractions,
            },
            {
              title: 'Playground',
              image: landingPlay,
              text: 'Explore our Playground and test your knowledge of Sustainable Development!',
              linkTo: paths.playground,
            },
          ],
        },
        {
          id: 'applying',
          class: 'applying',
          info: 'Dive deeper and start applying a Sustainable Development perspective',
          title: 'Applying',
          columns: 3,
          cards: [
            {
              title: 'Map Viewer',
              image: landingMapViewer,
              text: 'Access our map viewer and start applying Sustainable Development thinking (login required)',
              linkTo: paths.mapViewer,
            },
            {
              title: 'Metadata Search',
              image: landingSearch,
              text: 'Search our data with targeted keywords (login required)',
              linkTo: paths.metaData,
            },
            {
              title: 'Resources',
              image: landingResources,
              text: 'Explore further resources and literature to deepen your understanding of Sustainable Development and the 2030 Agenda',
              linkTo: paths.resources,
            },
          ],
        },
      ],
    };
  },
  methods: {
    toggleCol() {
      this.isShow = !this.isShow;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../css/style_overrides';

$card-inner-margin: 8px;

.learning {
  background-color: lighten(map-get($theme-colors, "primary"), 20%);
}
.static.learning {
  background-color: lighten(map-get($theme-colors, "primary"), 10%);
}
.understanding {
  background-color: darken(map-get($theme-colors, "secondary"), 10%);
}
.static.understanding {
  background-color: darken(map-get($theme-colors, "secondary"), 20%);
}
.applying {
  background-color: darken(map-get($theme-colors, "success"), 10%);
}
.static.applying {
  background-color: darken(map-get($theme-colors, "success"), 20%);
  color: var(--light);
}
.pointer {
  cursor: pointer;
}
.vertical-text {
  writing-mode: vertical-rl;
  font-variant: small-caps;
  white-space: break-spaces;
  text-align: center;
  max-height: calc(20rem - #{$card-inner-margin * 2});
}
.navigation-card {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: $card-inner-margin;
  text-align: center;
}
</style>
