<template>
<!-- eslint-disable max-len -->
  <div>
    <b-container class="meta-search">
      <b-row>
        <h2>Metadata Search</h2>
      </b-row>
      <b-row>
        <p>
          Search selected articles, grey literature, and datasets on sustainable development and the 2030 Agenda with a focus on “Good Health and Well-Being”, “Life on Land”, and “Peace, Justice, and Strong Institutions” (SDGs 3, 15, and 16) in Ethiopia and Kenya.
        </p>
        <p>
          Click 'More' to use pre-defined filters or search our database by entering keywords of interest, e.g. ‘land cover’, ‘maternal health’, or ‘governance’.
        </p>
      </b-row>
      <b-row class="search-bar">
        <b-input-group class="search-group" @keydown="onSearchBoxKeyDown($event)">
          <b-input-group-prepend is-text>
            <a href="#" ref="searchButton" @click.prevent="onSearch">
              <b-icon icon="search"></b-icon>
            </a>
          </b-input-group-prepend>

          <vue-bootstrap-typeahead
            :data="filteredDataPreview"
            class="w-100"
            :input-class="(!loaded) ? 'search-data-loading' : ''"
            v-model="searchTerm"
            :serializer="serializeMeta"
            placeholder="Enter your search..."
            @hit="onPreviewSelect($event)"
          ></vue-bootstrap-typeahead>

          <template #append>
            <b-dropdown text="More" variant="primary">
              <b-dropdown-item @click="filterOpen = !filterOpen">Filter</b-dropdown-item>
              <b-dropdown-item @click="helpOpen = !helpOpen">Help</b-dropdown-item>
            </b-dropdown>
            <b-button @click="forcedDataReload" v-b-tooltip.hover title="Force Data Reload">
              <b-icon icon="arrow-counterclockwise"></b-icon>
            </b-button>
          </template>
        </b-input-group>
        <div v-if="!loaded" class="search-data-loading">
          Please wait while caching search data...
        </div>
      </b-row>
      <b-row v-if="filterOpen" class="search-filters">
        <div class="filter-frame">
          <b-form-group class="filter" label="Category">
            <b-form-checkbox-group
              v-model="selectedCategories"
              :options="categories"
              size="sm"
              name="search-filter-category"
              switches
            ></b-form-checkbox-group>
          </b-form-group>
          <b-form-group class="filter" label="SDG">
            <b-form-checkbox-group
              v-model="selectedSDGs"
              :options="sdgs"
              size="sm"
              name="search-filter-category"
              switches
            ></b-form-checkbox-group>
          </b-form-group>
          <div class="filter-close">
            <a
              href="#"
              @click.prevent="filterOpen = false;"
            >
              <b-icon icon="chevron-up" font-scale="1"></b-icon>
            </a>
          </div>
        </div>
      </b-row>
      <b-row v-if="helpOpen" class="search-help">
        <em class="help-text">
          The search allows quotation marks to group multiple terms as a single expression.
          Otherwise, words are separated and all occurrences of all expressions are returned.
          Category and SDG filters are applied additionally to the search expression. Only
          items corresponding to the selected filters are returned.
        </em>
      </b-row>
    </b-container>
    <div class="search-results" v-if="filteredData.data">
      <b-container v-if="filteredData.data.length">
        <b-row class="result-header">
          About {{ filteredData.data.length }} result/s
          <span v-if="filteredData.duration > 0">
            &nbsp;({{ filteredData.duration / 1000 }} seconds)
          </span>
        </b-row>
        <b-row class="search-results-list">
          <template v-for="(result, index) in filteredData.data">
            <div
              :key="index"
              v-if="index >= currentIndexStart && index <= currentIndexEnd"
              class="search-entry"
            >
              <a
                v-if="result.web_link || result.dataset"
                :href="result.web_link || result.dataset"
                target="_blank"
                class="external-link"
              >{{ result.web_link }}</a>
              <a
                @click.prevent="onModalOpen(result)"
                href="#"
                v-html="$options.filters.highlight(result.title, filteredData.expressions)"
                class="title"></a>

              <div class="description">
                <div
                  v-if="!searchOpen[index]"
                  v-html="$options.filters.highlight(
                    shorten(result.description, 250), filteredData.expressions)"
                  @click="onToggleSearchOpen(index)"
                ></div>
                <div
                  v-else
                  v-html="$options.filters.highlight(
                    result.description, filteredData.expressions)"
                  @click="onToggleSearchOpen(index)"
                ></div>
              </div>
              <div class="result-meta">
                <div class="meta-publisher">
                  <span v-if="result.date">{{ asDate(result.date) }}</span>
                  <span v-if="result.contributor">&nbsp;- Source: {{ result.contributor }}</span>
                  <span v-if="result.publisher">&nbsp;published by {{ result.publisher }}</span>
                </div>
                <div class="meta-tags" v-if="result.subject || result.category">
                  <b-badge
                    v-for="(elem, index) in asSeparatedList(result.subject, result.category)"
                    :key="`tag-${index}`"
                    :variant="elem.isCategory ? 'primary' : 'light'"
                    pill
                  >{{ elem.tag }}</b-badge>
                  <b-badge
                    v-for="(elem, index) in asPrefixedSdgs('SDG ', result.sdg)"
                    :key="`SDG-${index}`"
                    variant="secondary"
                    pill
                  >{{ elem }}</b-badge>
                </div>
              </div>
            </div>
          </template>
          <div class="search-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredData.data.length"
              :per-page="numRows"
              first-number
              last-number
            ></b-pagination>
          </div>
        </b-row>
      </b-container>
      <b-container class="no-results" v-else>
        <b-row class="search-results-list">
          <div>No results found for '{{ appliedSearchTerm }}'.</div>
        </b-row>
      </b-container>
    </div>

    <b-modal
      ref="meta-viewer"
      size="xl"
      hide-footer
      centered
      :title="viewerContent ? viewerContent.title : '[unknown]'"
    >
      <meta-viewer v-if="viewerContent" :meta-data="viewerContent"></meta-viewer>
    </b-modal>
  </div>
</template>
<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

import {
  debounce, htmlEncode, isEmptyArray,
  distinct, asComparator,
} from '@/common';
import { mapActions, mapGetters } from 'vuex';
import MetaViewer from '../widgets/MetaViewer.vue';

const DATE_FORMAT = new Intl.DateTimeFormat(
  'default',
  { year: 'numeric', month: 'long', day: 'numeric' },
);

export default {
  components: {
    MetaViewer,
    VueBootstrapTypeahead,
  },
  data() {
    return {
      filterOpen: false,
      helpOpen: false,
      selectedCategories: [],
      selectedSDGs: [],
      appliedSearchTerm: '',
      searchTerm: '',
      searchOpen: [],
      currentPage: 1,
      numRows: 10,
      viewerContent: null,
    };
  },
  computed: {
    ...mapGetters({
      loaded: 'metadata/loaded',
      filteredData: 'metadata/filteredData',
      filteredDataPreview: 'metadata/filteredDataPreview',
      categories: 'metadata/categories',
      sdgs: 'metadata/sdgs',
    }),

    /**
     * @returns {number} Returns the first allowed index.
     */
    currentIndexStart() {
      return (this.currentPage - 1) * this.numRows;
    },

    /**
     * @returns {number} Returns the end index within index boundary.
     */
    currentIndexEnd() {
      return (this.currentPage * this.numRows) - 1;
    },
  },
  methods: {
    ...mapActions({
      loadData: 'metadata/loadData',
      applyFilter: 'metadata/applyFilter',
      applyPreviewFilter: 'metadata/applyPreviewFilter',
      applyCategoryFilter: 'metadata/applyCategoryFilter',
      applySDGFilter: 'metadata/applySDGFilter',
    }),
    serializeMeta(preview) {
      return this.shorten(preview, 50);
    },

    shorten(toShorten, maxLength) {
      return (toShorten && toShorten.length > maxLength)
        ? `${toShorten.substring(0, maxLength - 3)}...`
        : toShorten;
    },
    asDate(toFormat) {
      return DATE_FORMAT.format(new Date(String(toFormat)));
    },
    asSeparatedList(toFormat, category) {
      const categoryLower = category.toLowerCase();
      return distinct([...toFormat.split(', '), categoryLower].map((s) => s.trim().toLowerCase()))
        .sort()
        .map((tag) => ({ tag, isCategory: tag === categoryLower }));
    },
    asPrefixedSdgs(prefix, arr) {
      return [...arr].sort(asComparator((sdg) => sdg.name)).map((s) => prefix + s.name);
    },
    searchFor(term) {
      if (term && term.length > 1) {
        this.appliedSearchTerm = term;
        this.applyFilter(term);
      }
    },

    onPreviewSelect(title) {
      this.searchFor(`"${title}"`);
    },
    onSearch() {
      this.searchFor(this.searchTerm);
    },
    onSearchBoxKeyDown(event) {
      if (event.key === 'Enter') {
        this.searchFor(this.searchTerm);
        this.$refs.searchButton.focus();
      }
    },
    onToggleSearchOpen(index) {
      this.$set(this.searchOpen, index, !this.searchOpen[index]);
    },
    onModalOpen(result) {
      this.viewerContent = result;
      this.$refs['meta-viewer'].show();
    },
    forcedDataReload() {
      this.loadData({ force: true });
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    searchTerm: debounce(async function (val) {
      if (val && val.length > 2) {
        this.applyPreviewFilter(val);
      }
    }, 500),
    selectedCategories(val) {
      this.applyCategoryFilter(val);
    },
    selectedSDGs(val) {
      this.applySDGFilter(val);
    },
  },
  filters: {
    highlight(text, searchExpressions, tag = 'b') {
      if (!isEmptyArray(searchExpressions)) {
        return searchExpressions.reduce(
          (prev, currentExpr) => prev.replace(
            currentExpr,
            (match) => `<${tag}>${htmlEncode(match)}</${tag}>`,
          ),
          String(text),
        );
      }
      return text;
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin-top: 1rem;
}

.result-header {
  font-size: small;
}

.meta-search {
  margin-bottom: 2rem;
}

/deep/ .search-data-loading {
  opacity: 0.5;
  cursor: progress;
}

.search-bar, .search-filters, .search-help {
  display: flex;
  justify-content: center;
}

.search-help {
  .help-text {
    width: 80%;
  }
}

.search-filters {
  > .filter-frame {
    border: 1px solid #ced4da;
    padding: 0.375rem 0 0 0.75rem;
    background-color: #fff;
    width: 80%;
    display: flex;

    .filter:not(:first-child) {
      margin-left: 2rem;
    }

    > .filter-close {
      align-self: flex-end;
      margin-left: auto;
      padding: 0 0.375rem 0.15rem 0;
    }
  }
}
.search-bar {
  .search-group {
    display: flex;
    flex-wrap: nowrap;
    width: 80%;
  }
}

.search-results {
  background-color: white;
  padding: 1rem 0 1rem 0;
}

.search-results-list {
  margin-bottom: 1rem;

  .search-entry {
    margin-bottom: 1rem;
    width: 100%;

    .external-link, .result-meta {
      font-size: 0.75rem;
    }

    .description {
      cursor: pointer;
    }

    .title {
      display: block;
      font-size: 1.1rem;
    }

    .meta-tags {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-top: 5px;

      > span:not(:first-child) {
        margin-left: 5px;
      }
    }
  }

  .search-pagination {
    margin-top: 2rem;
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
</style>
