export function getImageDimensions(imgSrc) {
  const img = new Image();
  img.src = imgSrc;
  return { width: img.width, height: img.height };
}

export function getScaledImageDimensions(imgSrc, { targetWidth, targetHeight }) {
  const { width, height } = getImageDimensions(imgSrc);

  if (targetHeight) {
    return { width: Math.round((targetHeight / height) * width), height: targetHeight };
  }

  if (targetWidth) {
    return { width: targetWidth, height: Math.round((targetWidth / width) * height) };
  }
  return { width, height };
}
