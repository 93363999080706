<template>
  <b-container>
    <h1>Profile</h1>
    <b-card v-if="userData">
      <b-card-body>
        <h2>Profile of {{userData.username}}</h2>
        <b-list-group>
          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Email</h5>
            </div>
            <p class="mb-1">
              {{userData.email}}
            </p>
          </b-list-group-item>
          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Username</h5>
            </div>
            <p class="mb-1">
              {{userData.username}}
            </p>
          </b-list-group-item>
          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">First Name</h5>
            </div>
            <p class="mb-1">
              {{userData.first_name}}
            </p>
          </b-list-group-item>
          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Last Name</h5>
            </div>
            <p class="mb-1">
              {{userData.last_name}}
            </p>
          </b-list-group-item>
        </b-list-group>
        <p>
        </p>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  mounted() {
    this.loadProfile();
  },
  methods: {
    ...mapActions({
      getProfile: 'auth/getProfile',
    }),
    async loadProfile() {
      if (!this.userData) {
        await this.getProfile();
      }
    },
  },
  computed: {
    userData() {
      return this.$store.getters['auth/user'];
    },
  },
};
</script>
