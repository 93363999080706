import APIActions from '@/api/APIActions';
import { handleErrors } from '../../api/APIErrorHandler';

const AuthActions = APIActions.auth;

export default {
  namespaced: true,
  state: {
    user: null,
    userRegistration: null,
    loggingIn: false,
  },
  actions: {
    async logIn({ commit, dispatch }, { payload, errorFields }) {
      try {
        await AuthActions.login(payload);
        const { data } = await AuthActions.getProfile();
        const user = data;
        await commit('setUser', user);
        return [user, null];
      } catch (errorResponse) {
        await commit('setUser', {});
        const errors = handleErrors(errorResponse, errorFields);
        errors.forEach((errMessage) => {
          dispatch('toasts/addErrorToast', errMessage, { root: true });
        });
        return [errorResponse, errors];
      }
    },
    async submitRegistration({ commit, dispatch }, { payload, errorFields }) {
      try {
        const { data } = await AuthActions.register(payload);
        const responseData = data;
        await commit('setUserRegistration', responseData);
        return [responseData, null];
      } catch (responseData) {
        const errors = handleErrors(responseData, errorFields);
        errors.forEach((errMessage) => {
          dispatch('toasts/addErrorToast', errMessage, { root: true });
        });
        return [responseData, errors];
      }
    },
    async verifyRegistration({ dispatch }, { payload, errorFields }) {
      try {
        const { data } = await AuthActions.verifyRegistration(payload);
        const responseData = data;
        return [responseData, null];
      } catch (responseData) {
        const errors = handleErrors(responseData, errorFields);
        errors.forEach((errMessage) => {
          dispatch('toasts/addErrorToast', errMessage, { root: true });
        });
        return [responseData, errors];
      }
    },
    async logOut({ commit, dispatch }) {
      try {
        await AuthActions.logout();
        commit('logOut');
        return [null];
      } catch (errorResponse) {
        const errors = handleErrors(errorResponse);
        errors.forEach((errMessage) => {
          dispatch('toasts/addErrorToast', errMessage, { root: true });
        });
        return [errorResponse, errors];
      }
    },
    async getProfile({ commit, dispatch }, { ignoreErrors }) {
      try {
        const { data } = await AuthActions.getProfile();
        const responseData = data;
        await commit('setUser', responseData);
        return [responseData, null];
      } catch (responseData) {
        await commit('setUser', null);
        if (ignoreErrors) {
          return [];
        }
        const errors = handleErrors(responseData);
        errors.forEach((errMessage) => {
          dispatch('toasts/addErrorToast', errMessage, { root: true });
        });
        return [responseData, errors];
      }
    },
  },
  mutations: {
    setUser(state, data) {
      state.userRegistration = null;
      state.user = data;
    },
    logOut(state) {
      state.user = null;
    },
    setUserRegistration(state, registrationData) {
      state.userRegistration = registrationData;
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
    userName: (state) => state.user.username,
    user: (state) => state.user,
  },
};
