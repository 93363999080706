export function debounce(fn, delay) {
  let timeoutID = null;
  return function (...args) {
    const context = this;
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    timeoutID = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}
