<template>
  <div
    class="flip-container"
    @click="clickAction"
    @mouseover="hoverAction(true)"
    @mouseleave="hoverAction(false)"
  >
    <div class="card-content" :class="{ 'flipped': isFlipped }">
      <div
        :class="[ 'front', { 'card-front-is-hidden': enableBackSideEvents } ]"
        :style="`height: ${height}px`">
        <slot name="front"></slot>
      </div>
      <div class="back">
        <b-overlay
          :variant="backSideMark"
          :class="[ 'back-overlay', { 'overlay-is-clickable': enableBackSideEvents } ]"
          :style="`height: ${height}px`"
          opacity="0.2"
          :show="isMarked"
        >
          <slot></slot>
          <template #overlay>
            <div>
              <b-icon-check v-if="showMark" color="green" font-scale="10"/>
            </div>
          </template>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { isString } from '@/common';

export default {
  props: {
    showMark: {
      required: false,
      default: true,
    },
    height: {
      required: false,
      default: '100%',
    },
    cardId: {
      required: false,
      default: null,
    },
    reactOnHover: {
      default: false,
      type: Boolean,
    },
    reactOnClick: {
      default: false,
      type: Boolean,
    },
    allowEventsOnBackside: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isFlipped: false,
      isMarked: false,
      backSideMark: 'primary',
    };
  },
  computed: {
    enableBackSideEvents() {
      return this.allowEventsOnBackside && this.isFlipped;
    },
  },
  methods: {
    hoverAction(shouldShowBackside) {
      if (this.reactOnHover) {
        this.isFlipped = shouldShowBackside;
      }
    },
    clickAction() {
      this.touched();
      if (this.reactOnClick) {
        this.isFlipped = !(this.isFlipped);
      }
    },
    touched() {
      this.$emit('touched', { cardId: this.cardId });
    },
    closeCard() {
      this.isFlipped = false;
    },
    flipCard() {
      this.isFlipped = !this.isFlipped;
    },
    markCard() {
      this.mark(true);
    },
    mark(useCheckMark) {
      this.isFlipped = true;
      this.isMarked = true;

      if (isString(useCheckMark) && useCheckMark) {
        this.backSideMark = useCheckMark;
      } else {
        this.backSideMark = (useCheckMark ? 'primary' : 'warning');
      }
    },
    reset() {
      this.isFlipped = false;
      this.isMarked = false;
    },
  },
};
</script>

<style scoped lang="scss">
.flip-container {
  perspective: 40rem;
  height: 100%;
}

.card-content {
  height: 100%;
  display: flex;
  transform-style: preserve-3d;
  transition: 0.8s transform;
  flex-grow: 1;
}

.card-content.flipped {
  transform: rotateY(-180deg);
}

.card-content.flipped .front.card-front-is-hidden {
  visibility: hidden;
  transition: visibility 0.1s linear 0.8s;
}

.overlay-is-clickable /deep/ .b-overlay {
  pointer-events: none;
}

.front, .back {
  height: 100%;
  min-width: 100%;
  // mix-blend-mode: multiply; // Preview both faces
  backface-visibility: hidden;
}

.back {
  height: 100%;
  transform: rotateY(-180deg) translate(100%, 0);

  .back-overlay {
    height: 100%;
  }

  .back-overlay  /deep/ .b-overlay {
    margin: 0 4px 0 4px;
  }
}
</style>
