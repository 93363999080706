<template>
  <!-- Header -->
  <b-card
    overlay
    img-src="@/assets/blog/ethiopia_story/images/eth_miner_temps_web.jpg"
    text-variant="light"
  >
    <b-card-body class="text-center mt-5">
      <b-card-title class="title">
        Streams of Opportunity?
      </b-card-title>
      <b-card-sub-title class="subtitle mt-3" sub-title-text-variant="light">
        Ethiopia has a long history of mining, both artisanal and industrial.
        Both types of operation carry risks – not only to those directly involved,
        but also to communities further downstream. What challenges need addressing,
        to ensure that mining contributes to sustainable development?
      </b-card-sub-title>
    </b-card-body>
  </b-card>
</template>
<script>
import environment from '../../../environment';

export default {
  name: 'story-header',
  components: {},
  data() {
    return {
      assetsURL: environment.assetsURL,
      defaultImgProps: {
        fluid: true,
      },
    };
  },
  methods: {},
};
</script>
<style type="scss" scoped>
.title {
  font-size: 5rem;
}
.subtitle {
  font-size: 2rem;
  color: white;
  font-style: italic;
}
</style>
