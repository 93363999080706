<template>
  <div>
    <b-container>

        <a href="https://myriamtr.github.io/sdg-nexus-interactions/">Full screen version.</a>

    </b-container>
    <iframe
      loading="lazy"
      style="border: 100px; overflow: auto"
      src="https://myriamtr.github.io/sdg-nexus-interactions/"
      scrolling="yes"
      width="100%"
      height="1200">
    </iframe>
  </div>
</template>
<script>
export default {
  components: {},
  name: 'sdg-interactions-diagram',
};
</script>
