<template>
  <!-- Header -->
  <b-aspect aspect="16:9">
    <b-jumbotron class="jmb">
      <div class="video">
        <video preload muted autoplay loop controls>
          <source
            :src="`${assetsURL}/blog/kenya_story/data/ken_title.mp4`"
            type="video/mp4"
          />
        </video>
      </div>
      <template #header>Highway to Health?</template>
      <template #lead>
        Cars, noise, dust – highways may at first sight not be very appealing
        to people living nearby. But they do have the potential to improve health
        and well-being. Join us as we delve into the changes roads can bring about.
      </template>
    </b-jumbotron>
  </b-aspect>
</template>
<script>
import environment from '../../../environment';

export default {
  name: 'story-header',
  components: {},
  data() {
    return {
      assetsURL: environment.assetsURL,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.jmb video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*  object-fit is not supported on IE  */
  object-fit: cover;
  opacity: 0.8;
}

.jmb {
  z-index: 2;
  position: relative;
  height: 100%;
}
</style>
