<template>
  <!-- eslint-disable max-len -->
  <!-- All text content is based on Version April 28 sent by MP -->
  <b-row>
    <b-col cols="6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 900 500"
        id="sdg-svg"
      >
        <g
          id="SDG3"
          @click="setSDGText('sdg3')"
          :class="{ active: activeSDGText === 'sdg3' }"
        >
          <rect
            id="_Path_"
            data-name=" Path "
            class="cls-1"
            x="650"
            y="249.99"
            width="250"
            height="250.01"
          />
          <path
            id="_Path_2"
            data-name=" Path 2"
            class="cls-2"
            d="M735.68,372c8.55,0,12.27-4.53,12.27-11.52v-7.43c0-4.61-2-7.59-6.25-8.55a8.06,8.06,0,0,0,5.79-8.18V332c0-7.14-3.42-11.52-11.22-11.52-8.78,0-11.83,5.2-11.83,12.64v4.46h7.74V332.4c0-3.12,1-4.9,3.64-4.9s3.64,1.78,3.64,4.53v5.43c0,2.68-1.78,4.09-4.53,4.09h-3.2V348h3.57c3,0,4.16,1.41,4.16,4.09v8.33c0,2.67-1.19,4.53-3.79,4.53-2.9,0-3.94-1.93-3.94-4.91v-7.21H724v6.62c0,7.66,3,12.56,11.67,12.56"
            transform="translate(-50 -50)"
          />
          <path
            id="_Path_3"
            data-name=" Path 3"
            class="cls-2"
            d="M908.81,456.25c0-7-4.5-12.75-11.34-12.75-3.42,0-7.55,3.75-9.79,6-2.25-2.3-6.09-6.05-9.5-6.05-6.83,0-11.63,5.71-11.63,12.75a12.86,12.86,0,0,0,3.63,9h0l17.53,17.56,17.5-17.54h0A12.81,12.81,0,0,0,908.81,456.25Z"
            transform="translate(-50 -50)"
          />
          <g id="_Group_" data-name=" Group ">
            <path
              id="_Path_4"
              data-name=" Path 4"
              class="cls-2"
              d="M834.2,511.47H834a2.88,2.88,0,0,1-2.66-2.39l-8.9-51.85-13.48,25a2.88,2.88,0,0,1-5.38-1l-5.73-40L787.4,464.79a2.87,2.87,0,0,1-2.63,1.71H744.06a2.88,2.88,0,0,1,0-5.75H782.9l13.78-30.94a2.87,2.87,0,0,1,5.47.75l5.91,41.25,13.29-24.66a2.88,2.88,0,0,1,5.37.88L835,496l30-99.79a2.88,2.88,0,0,1,5.35-.42l15.81,32.77a2.88,2.88,0,0,1-5.14,2.6l0-.1L868.39,405,837,509.42A2.9,2.9,0,0,1,834.2,511.47Z"
              transform="translate(-50 -50)"
            />
          </g>
          <g id="_Group_2" data-name=" Group 2">
            <path
              id="_Compound_Path_"
              data-name=" Compound Path "
              class="cls-2"
              d="M776.56,330.44h4.88v10.92H779v-2.23a3.1,3.1,0,0,1-3.28,2.47c-3,0-4.32-2.35-4.32-5.52V326.2c0-3.2,1.54-5.52,5.16-5.52,3.79,0,4.91,2.11,4.91,5.12v1.81h-3.13v-2.08c0-1.26-.49-2-1.72-2s-1.78.88-1.78,2.11v11c0,1.23.51,2.1,1.69,2.1s1.69-.63,1.69-2v-3.61h-1.63Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_2"
              data-name=" Compound Path 2"
              class="cls-2"
              d="M783.7,336.08V326.2c0-3.2,1.57-5.52,5.18-5.52s5.22,2.32,5.22,5.52v9.88c0,3.17-1.57,5.52-5.22,5.52S783.7,339.25,783.7,336.08Zm7,.54v-11c0-1.23-.54-2.11-1.78-2.11s-1.74.88-1.74,2.11v11c0,1.24.54,2.12,1.74,2.12S790.66,337.86,790.66,336.62Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_3"
              data-name=" Compound Path 3"
              class="cls-2"
              d="M796.36,336.08V326.2c0-3.2,1.57-5.52,5.18-5.52s5.21,2.32,5.21,5.52v9.88c0,3.17-1.57,5.52-5.21,5.52S796.36,339.25,796.36,336.08Zm7,.54v-11c0-1.23-.54-2.11-1.78-2.11s-1.74.88-1.74,2.11v11c0,1.24.54,2.12,1.74,2.12S803.32,337.86,803.32,336.62Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_4"
              data-name=" Compound Path 4"
              class="cls-2"
              d="M819.29,326.41v9.46c0,3.14-1.29,5.49-5,5.49H809V320.92h5.3C818,320.92,819.29,323.25,819.29,326.41Zm-5.39,12.14c1.42,0,2-.84,2-2.08V325.78a1.79,1.79,0,0,0-2-2h-1.45v14.82Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_5"
              data-name=" Compound Path 5"
              class="cls-2"
              d="M833,332.35h-3.31v9h-3.44V320.92h3.44v8.65H833v-8.65h3.46v20.44H833Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_6"
              data-name=" Compound Path 6"
              class="cls-2"
              d="M838.7,320.92h8.2v2.81h-4.74v5.84h3.4v2.78h-3.42v6.2h4.75v2.81h-8.2Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_7"
              data-name=" Compound Path 7"
              class="cls-2"
              d="M848.5,341.36l3.86-20.44h3.92l3.88,20.44h-3.52l-.64-3.74h-3.64l-.61,3.74Zm4.31-6.48h2.75l-1.36-8.2h0Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_8"
              data-name=" Compound Path 8"
              class="cls-2"
              d="M861.88,320.92h3.44v17.63h4.1v2.81h-7.54Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_9"
              data-name=" Compound Path 9"
              class="cls-2"
              d="M868.93,320.92h9.14v2.81h-2.84v17.63h-3.46V323.73h-2.84Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_10"
              data-name=" Compound Path 10"
              class="cls-2"
              d="M886.72,332.35H883.4v9H880V320.92h3.43v8.65h3.32v-8.65h3.46v20.44h-3.46Z"
              transform="translate(-50 -50)"
            />
          </g>
          <g id="_Group_3" data-name=" Group 3">
            <path
              id="_Compound_Path_11"
              data-name=" Compound Path 11"
              class="cls-2"
              d="M770.86,371.32l3.86-20.43h3.91l3.89,20.43H779l-.63-3.75h-3.65l-.61,3.75Zm4.31-6.48h2.75l-1.36-8.2h0Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_12"
              data-name=" Compound Path 12"
              class="cls-2"
              d="M787.13,358.48v12.84h-2.88V350.89h3.38l3.7,11.72V350.89h2.87v20.43h-3Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_13"
              data-name=" Compound Path 13"
              class="cls-2"
              d="M806.72,356.37v9.46c0,3.14-1.29,5.49-5,5.49h-5.31V350.89h5.31C805.43,350.89,806.72,353.21,806.72,356.37Zm-5.39,12.15c1.41,0,2-.84,2-2.08V355.75a1.79,1.79,0,0,0-2-2.05h-1.45v14.83Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_14"
              data-name=" Compound Path 14"
              class="cls-2"
              d="M819.86,358.69l-1.93,12.63h-3.22L812,350.89h3.31l1.6,12.95h0l1.72-12.95h2.8l1.9,12.95h0l1.57-12.95h2.89l-2.65,20.43h-3.25l-2-12.63Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_15"
              data-name=" Compound Path 15"
              class="cls-2"
              d="M829.6,350.89h8.19v2.8H833v5.85h3.41v2.77H833v6.21h4.75v2.8H829.6Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_16"
              data-name=" Compound Path 16"
              class="cls-2"
              d="M839.94,350.89h3.43v17.63h4.1v2.8h-7.53Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_17"
              data-name=" Compound Path 17"
              class="cls-2"
              d="M849.34,350.89h3.43v17.63h4.11v2.8h-7.54Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_18"
              data-name=" Compound Path 18"
              class="cls-2"
              d="M856.42,359.25h4.83V362h-4.83Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_19"
              data-name=" Compound Path 19"
              class="cls-2"
              d="M863.29,350.89h5c3.43,0,4.58,1.59,4.58,4.5v2.08c0,1.71-.64,2.8-2.05,3.24a3.11,3.11,0,0,1,2.56,3.41v2.71c0,2.9-1.3,4.5-4.73,4.5h-5.34Zm3.44,8.61h1.2a1.45,1.45,0,0,0,1.63-1.66v-2.5a1.44,1.44,0,0,0-1.66-1.66h-1.17Zm0,2.62v6.39h1.5a1.48,1.48,0,0,0,1.69-1.66v-3.07c0-1.09-.52-1.66-1.72-1.66Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_20"
              data-name=" Compound Path 20"
              class="cls-2"
              d="M875.38,350.89h8.2v2.8h-4.75v5.85h3.4v2.77h-3.42v6.21h4.75v2.8h-8.18Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_21"
              data-name=" Compound Path 21"
              class="cls-2"
              d="M885.72,350.89h3.43v20.43h-3.43Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_22"
              data-name=" Compound Path 22"
              class="cls-2"
              d="M894.34,358.48v12.84h-2.9V350.89h3.38l3.71,11.72V350.89h2.86v20.43h-3Z"
              transform="translate(-50 -50)"
            />
            <path
              id="_Compound_Path_23"
              data-name=" Compound Path 23"
              class="cls-2"
              d="M908.83,360.41h4.89v10.91h-2.47v-2.23a3.09,3.09,0,0,1-3.29,2.47c-3,0-4.31-2.35-4.31-5.52v-9.88c0-3.19,1.54-5.52,5.16-5.52,3.8,0,4.91,2.12,4.91,5.13v1.81h-3.14V355.5c0-1.27-.48-2-1.72-2s-1.77.87-1.77,2.11v11c0,1.24.5,2.11,1.69,2.11s1.68-.63,1.68-2v-3.61h-1.63Z"
              transform="translate(-50 -50)"
            />
          </g>
        </g>
        <g
          id="SDG15"
          @click="setSDGText('sdg15')"
          :class="{ active: activeSDGText === 'sdg15' }"
        >
          <rect class="cls-3" x="325" width="250" height="250" />
          <polygon
            class="cls-2"
            points="404.88 20.94 404.88 41.35 412.42 41.35 412.42 38.57 408.33 38.57 408.33 20.94 404.88 20.94"
          />
          <rect
            class="cls-2"
            x="414.31"
            y="20.94"
            width="3.43"
            height="20.41"
          />
          <polygon
            class="cls-2"
            points="419.99 41.35 423.42 41.35 423.42 32.34 426.86 32.34 426.86 29.57 423.42 29.57 423.42 23.72 428.03 23.72 428.03 20.94 419.99 20.94 419.99 41.35"
          />
          <polygon
            class="cls-2"
            points="429.85 41.35 438.04 41.35 438.04 38.57 433.28 38.57 433.28 32.34 436.69 32.34 436.69 29.57 433.28 29.57 433.28 23.72 438.04 23.72 438.04 20.94 429.85 20.94 429.85 41.35"
          />
          <polygon
            class="cls-2"
            points="343.31 30.07 343.31 38.9 343.39 38.9 349.32 32.74 349.4 32.74 349.4 71.33 357.87 71.33 357.87 20.94 351.11 20.94 343.31 30.07"
          />
          <path
            class="cls-2"
            d="M428,86.9c-3.34,0-5.79,1.2-7.13,3.53h-.08V77.84h14.58v-6.9H413.08V99.33h7.73V98.19c0-3,1.34-4.57,3.72-4.57,2.6,0,3.65,1.77,3.65,4.51v12.2c0,2.67-1,4.55-3.58,4.55-2.89,0-3.93-1.94-3.93-4.91v-6.32h-7.74v5.71c0,7.68,3.05,12.58,11.66,12.58s12.05-4.55,12.05-11.55V98.07c0-8-4.1-11.17-8.63-11.17"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M454.87,116.09v-9.87c0-3.18,1.57-5.48,5.18-5.48s5.18,2.29,5.18,5.48v9.87c0,3.16-1.56,5.48-5.18,5.48s-5.18-2.32-5.18-5.48m6.94.53V105.69c0-1.23-.53-2.09-1.76-2.09s-1.76.86-1.76,2.09v10.93c0,1.23.55,2.11,1.76,2.11s1.76-.88,1.76-2.11"
            transform="translate(-50 -50)"
          />
          <polygon
            class="cls-2"
            points="420.06 58.55 420.06 71.33 417.18 71.33 417.18 50.98 420.55 50.98 424.24 62.67 424.24 50.98 427.08 50.98 427.08 71.33 424.09 71.33 420.06 58.55"
          />
          <polygon
            class="cls-2"
            points="434.51 50.98 437.93 50.98 437.93 68.55 442.03 68.55 442.03 71.33 434.51 71.33 434.51 50.98"
          />
          <path
            class="cls-2"
            d="M493,121.33,496.87,101h3.9l3.88,20.35h-3.52l-.63-3.72h-3.63l-.6,3.72Zm4.29-6.44h2.73l-1.35-8.19h0Z"
            transform="translate(-50 -50)"
          />
          <polygon
            class="cls-2"
            points="458.93 58.55 458.93 71.33 456.06 71.33 456.06 50.98 459.41 50.98 463.1 62.67 463.1 50.98 465.96 50.98 465.96 71.33 462.96 71.33 458.93 58.55"
          />
          <path
            class="cls-2"
            d="M528.43,106.44v9.44c0,3.13-1.3,5.45-5,5.45h-5.28V101h5.28c3.67,0,5,2.3,5,5.46m-5.38,12.11c1.4,0,2-.86,2-2.09V105.83c0-1.21-.55-2.07-2-2.07H521.6v14.79Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M479.79,219a.56.56,0,0,1,.56.58h0v18.18a.54.54,0,0,1-.54.54h-7.38a.55.55,0,0,1-.55-.54h0V219.6a.56.56,0,0,1,.55-.58h7.36Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M560.69,246.14H439.3a.61.61,0,0,0-.56.59v7.41a.56.56,0,0,0,.56.54H560.69a.57.57,0,0,0,.57-.54v-7.41a.62.62,0,0,0-.57-.59"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M560.69,260.91H439.3a.54.54,0,0,0-.55.54v7.46a.56.56,0,0,0,.56.54H560.69a.57.57,0,0,0,.57-.54v-7.46a.55.55,0,0,0-.57-.54"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M537.6,198.89a17.9,17.9,0,0,0-10.5-3.95,13.9,13.9,0,0,0-9.75,4.14.94.94,0,0,0-.34.75v.65a1.11,1.11,0,0,0,.35.86,1,1,0,0,0,.86.2.38.38,0,0,0,.23-.08,1.38,1.38,0,0,0,.61-.37,9.83,9.83,0,0,1,4.86-1,18.38,18.38,0,0,1,12.5,5.23l.11.12a1.5,1.5,0,0,0,1.06.41,1.43,1.43,0,0,0,1-.4l.1-.13a18.56,18.56,0,0,1,12.54-5.23,9.79,9.79,0,0,1,4.87,1,1.91,1.91,0,0,0,.67.42,1.7,1.7,0,0,0,.71,0,1.09,1.09,0,0,0,.76-1v-.57a1.1,1.1,0,0,0-.31-.74,14,14,0,0,0-9.87-4.24,17.86,17.86,0,0,0-10.49,4"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M530.06,150.51a1,1,0,0,0-.52.62l-.13.42a1,1,0,0,0,.16.92,1,1,0,0,0,.75.38.43.43,0,0,0,.16,0,1.39,1.39,0,0,0,.57-.12,2.56,2.56,0,0,1,.77-.11c.53,0,5.43.18,8.67,5.7l0,.06a1.15,1.15,0,0,0,.76.6.82.82,0,0,0,.41.09,1.73,1.73,0,0,0,.61-.16l.07,0a12.46,12.46,0,0,1,5.42-1.36,8.25,8.25,0,0,1,5.53,1.8,3.28,3.28,0,0,0,.39.46l.39.16.19,0h.11a1.48,1.48,0,0,0,.49-.1,1.19,1.19,0,0,0,.51-.64l.1-.34a.92.92,0,0,0,0-.29.9.9,0,0,0-.14-.52,9.13,9.13,0,0,0-8.25-5,12.73,12.73,0,0,0-4.35.84c-1.11-1.49-3.94-4.59-8.19-4.59a10.12,10.12,0,0,0-4.56,1.16"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M504.41,169.17l0,.54a1,1,0,0,0,1.1,1,.71.71,0,0,0,.21,0l.19-.05a1.16,1.16,0,0,0,.52-.4,9.15,9.15,0,0,1,4.63-1,15,15,0,0,1,9.58,3.64l.09.07a1.51,1.51,0,0,0,1,.38h.09a1.42,1.42,0,0,0,.94-.47l.09-.07a14.52,14.52,0,0,1,10.63-5,7.17,7.17,0,0,1,3.17.6,1.85,1.85,0,0,0,.63.31,1.38,1.38,0,0,0,.7,0,1,1,0,0,0,.7-1l0-.48a1.06,1.06,0,0,0-.37-.72,11.74,11.74,0,0,0-7.75-3.07c-4.27,0-7.68,2.54-9.1,3.77a15,15,0,0,0-8.09-2.69,11.62,11.62,0,0,0-8.62,4,1.11,1.11,0,0,0-.28.77"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M443,178.92a13.15,13.15,0,0,1,13.16-13.17h0l.84,0a17.79,17.79,0,1,1,33.74,9.14,19.89,19.89,0,0,1-4.31,39.32l-26.57-.17h0a14.08,14.08,0,0,1-10.25-23.73A13.21,13.21,0,0,1,443,178.92Z"
            transform="translate(-50 -50)"
          />
        </g>
        <g
          id="SDG16"
          @click="setSDGText('sdg16')"
          :class="{ active: activeSDGText === 'sdg16' }"
        >
          <rect class="cls-4" y="249.99" width="250" height="250.01" />
          <polygon
            class="cls-2"
            points="19.23 280.11 19.23 288.95 19.31 288.95 25.26 282.78 25.33 282.78 25.33 321.37 33.81 321.37 33.81 270.96 27.05 270.96 19.23 280.11"
          />
          <path
            class="cls-2"
            d="M113.47,359.25v-7.44c0-8.25-3.26-12.41-9.36-12.41a7.24,7.24,0,0,0-7,4.09h-.07V332.63c0-3.05,1-5.2,4-5.2s3.94,1.78,3.94,4.9v3.35h7.73V333c0-7.43-2.45-12.63-11.82-12.63-8.92,0-12.27,5.72-12.27,13.6v24.54c0,7.8,3.43,13.45,12.34,13.45s12.51-4.9,12.51-12.71m-8.41.75c0,3.2-1.18,5.05-4.08,5.05s-3.94-1.93-3.94-5.05v-9.23c.14-2.82,1.34-4.61,4-4.61,2.9,0,4,1.79,4,5.06Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M211.49,422.73s-2.1,2.79-1.5,5.51,3.72,4.34,3.72,4.34,2.1-2.79,1.5-5.51-3.72-4.34-3.72-4.34"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M208.06,438.69s2.11-2.8,1.5-5.52-3.72-4.34-3.72-4.34-2.1,2.79-1.5,5.52,3.73,4.34,3.73,4.34"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M216.71,435.7s2.22,2.25,4.75,2,4.4-2.82,4.4-2.82-2.22-2.25-4.75-2-4.4,2.83-4.4,2.83"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M220.35,423.73c-2.06,2.08-1.77,5.78-1.77,5.78s3.63.31,5.69-1.77S226,422,226,422s-3.63-.32-5.69,1.77"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M215.81,444c2.52.3,4.89-1.86,4.89-1.86s-1.72-2.66-4.24-2.95-4.9,1.87-4.9,1.87,1.72,2.64,4.24,2.94"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M218.06,487.8H199.22a2.67,2.67,0,1,0,0,5.33h18.84a2.67,2.67,0,0,0,0-5.33"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M218.06,517.18H199.22a2.67,2.67,0,1,0,0,5.33h18.84a2.67,2.67,0,0,0,0-5.33"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M133.32,481.33A12,12,0,0,0,132,481s.48.15,1.31.34"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M217.36,495H199.9a1.52,1.52,0,0,0-1.51,1.52v5.28L187,503l-16.65-.38V492.49a46.52,46.52,0,0,0,6.62-1.35c16-4.52,19.53-17.77,19.18-26.94-.35-9.34,2.64-13,6.93-14.58a20.08,20.08,0,0,1,3.56-.94,28.23,28.23,0,0,0,1.54,8.14c.38,0,.75-.05,1.14-.1a27,27,0,0,1-1.58-8.2,19.43,19.43,0,0,1,3.81-.11,23.76,23.76,0,0,1-3.62-2.58c.72-4.42,3.35-7.14,6.07-9.91a32.62,32.62,0,0,0,4.25-5l-1-.61a32.12,32.12,0,0,1-4.11,4.81c-2.64,2.71-5.32,5.47-6.22,9.87a38,38,0,0,1-2.76-2.77c-3.95-4.43-9-1.18-16.29,5.58s-16.39,7-25.31-2.14c-11.75-12-22-12-29.06-11.29,4.82,2.05,6.5,6.55,8.94,18,2.55,12,9,17.42,17.8,18.17-4.9,1.73-5.14,4.5-11.71,9-5.12,3.54-12.34,2.42-15.25,1.75,5.37,1.68,11.73,7.31,17.42,9.26a41.74,41.74,0,0,0,10.37,2.12v9.74l-29.83-.68v6.79L187,507.27l11.4,1.25v5.28a1.52,1.52,0,0,0,1.52,1.52h17.46a1.53,1.53,0,0,0,1.52-1.52V496.51A1.53,1.53,0,0,0,217.36,495Zm-53.51-2.13c1.25,0,2.49,0,3.74-.1v9.84l-3.74-.08Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M129.65,507.44a2.43,2.43,0,0,1-2.23,2.6h-1.34a2.44,2.44,0,0,1-2.24-2.6v-4.7a2.43,2.43,0,0,1,2.24-2.59h1.34a2.43,2.43,0,0,1,2.23,2.59Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M129.79,320.93h5.09c3.46,0,4.73,1.72,4.73,4.58v4.31c0,2.86-1.27,4.61-4.73,4.61h-1.66v6.93h-3.43Zm3.43,2.8v8h1.27a1.48,1.48,0,0,0,1.69-1.69v-4.7c0-1.12-.51-1.66-1.69-1.66Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M141.12,320.93h8.2v2.8h-4.77v5.85H148v2.77h-3.41v6.21h4.77v2.8h-8.2Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M150.64,341.36l3.86-20.43h3.92l3.88,20.43h-3.52l-.63-3.75H154.5l-.6,3.75Zm4.3-6.48h2.76l-1.36-8.2h0Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M163.45,336.09v-9.88c0-3.2,1.5-5.52,5.12-5.52,3.8,0,4.85,2.11,4.85,5.13v2.41h-3.13v-2.69c0-1.27-.42-2-1.66-2s-1.75.88-1.75,2.11v11c0,1.24.51,2.11,1.75,2.11s1.66-.78,1.66-2V333h3.14v3.53c0,2.92-1.21,5.09-4.86,5.09S163.45,339.25,163.45,336.09Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M175.29,320.93h8.2v2.8h-4.75v5.85h3.41v2.77h-3.42v6.21h4.75v2.8h-8.19Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M189,338.35,187.26,343h-1.93l.42-4.88H189Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M203.56,320.93v15.78c0,3-1.29,4.88-4.73,4.88s-4.52-1.95-4.52-4.55V333h3.25v4.13c0,1,.37,1.63,1.27,1.63s1.29-.6,1.29-1.66V320.93Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M215.62,320.93v15.46c0,3.16-1.36,5.21-4.85,5.21s-5.13-2.05-5.13-5.21V320.93h3.44v15.78c0,1.21.48,2,1.69,2s1.69-.8,1.69-2V320.93Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M217.55,337v-2.75h3.19V337c0,1.15.51,1.81,1.69,1.81S224,338.1,224,337v-.78a3.69,3.69,0,0,0-1.54-2.9l-2-2c-2-1.93-2.86-3.11-2.86-5.46v-.69c0-2.53,1.48-4.5,4.82-4.5s4.79,1.68,4.79,4.64V327h-3.13v-1.75c0-1.18-.54-1.75-1.63-1.75a1.51,1.51,0,0,0-1.63,1.69v.42c0,1.14.63,1.81,1.63,2.77l2.2,2.17c1.87,1.9,2.77,3,2.77,5.3v1c0,2.8-1.5,4.79-5,4.79S217.55,339.65,217.55,337Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M229,320.93h9.13v2.8h-2.82v17.63H231.8V323.73H229Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M239.85,320.93h3.44v20.43h-3.44Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M245.42,336.09v-9.88c0-3.2,1.51-5.52,5.13-5.52,3.8,0,4.85,2.11,4.85,5.13v2.41h-3.13v-2.69c0-1.27-.43-2-1.66-2s-1.75.88-1.75,2.11v11c0,1.24.5,2.11,1.75,2.11s1.66-.78,1.66-2V333h3.13v3.53c0,2.92-1.2,5.09-4.85,5.09S245.42,339.25,245.42,336.09Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M257.42,320.93h8.2v2.8h-4.77v5.85h3.41v2.77h-3.41v6.21h4.77v2.8h-8.2Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M129.37,371.33l3.85-20.43h3.92L141,371.33H137.5l-.63-3.73h-3.65l-.6,3.73Zm4.3-6.48h2.76l-1.36-8.19h0Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M145.64,358.49v12.84h-2.89V350.9h3.37l3.71,11.72V350.9h2.86v20.43h-3Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M165.23,356.38v9.47c0,3.13-1.29,5.48-5,5.48h-5.32V350.9h5.31C164,350.9,165.23,353.21,165.23,356.38Zm-5.4,12.15c1.42,0,2-.84,2-2.08v-10.7a1.79,1.79,0,0,0-2-2.05h-1.44v14.83Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M170.78,366.93v-2.75H174V367c0,1.14.51,1.8,1.69,1.8s1.56-.72,1.56-1.8v-.78a3.71,3.71,0,0,0-1.53-2.89l-2-2c-2-1.93-2.86-3.1-2.86-5.45v-.69c0-2.54,1.47-4.51,4.82-4.51s4.79,1.69,4.79,4.64V357h-3.14v-1.77c0-1.18-.54-1.76-1.62-1.76a1.51,1.51,0,0,0-1.63,1.69v.43c0,1.14.63,1.8,1.63,2.77l2.2,2.17c1.86,1.9,2.77,3,2.77,5.3v1c0,2.8-1.5,4.8-5,4.8S170.78,369.61,170.78,366.93Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M181.89,350.9H191v2.81H188.2v17.63h-3.47V353.71h-2.84Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M196.36,362v9.38h-3.43V350.9h5c3.46,0,4.73,1.72,4.73,4.58v2.62c0,2.2-.78,3.5-2.75,3.95l3.29,9.28h-3.61Zm0-8.25v6.36h1.15a1.48,1.48,0,0,0,1.68-1.68v-3c0-1.12-.5-1.66-1.68-1.66Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M204.83,366.06v-9.89c0-3.19,1.57-5.51,5.19-5.51s5.21,2.32,5.21,5.51v9.89c0,3.16-1.57,5.51-5.21,5.51S204.83,369.22,204.83,366.06Zm7,.54v-11c0-1.23-.54-2.11-1.77-2.11s-1.75.88-1.75,2.11v11c0,1.23.54,2.11,1.75,2.11S211.79,367.83,211.79,366.6Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M220.38,358.49v12.84h-2.89V350.9h3.38l3.7,11.72V350.9h2.86v20.43h-3Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M234.88,360.42h4.88v10.91h-2.47V369.1a3.09,3.09,0,0,1-3.29,2.47c-2.95,0-4.31-2.35-4.31-5.51v-9.89c0-3.19,1.54-5.51,5.16-5.51,3.8,0,4.91,2.11,4.91,5.12v1.81h-3.14v-2.08c0-1.27-.48-2-1.71-2s-1.78.87-1.78,2.11v11c0,1.24.51,2.11,1.69,2.11s1.68-.63,1.68-2v-3.61h-1.62Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M129.94,381.19h3.44v20.44h-3.44Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M138.56,388.79v12.84h-2.89V381.19H139l3.71,11.73V381.19h2.86v20.44h-3Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M147.69,397.22v-2.75h3.2v2.8c0,1.15.51,1.81,1.69,1.81s1.56-.72,1.56-1.81v-.78a3.69,3.69,0,0,0-1.53-2.89l-2-2c-2-1.93-2.87-3.1-2.87-5.46v-.69c0-2.53,1.48-4.5,4.83-4.5s4.79,1.69,4.79,4.64v1.66H154.2v-1.76c0-1.18-.55-1.75-1.63-1.75a1.51,1.51,0,0,0-1.63,1.69v.42c0,1.14.63,1.81,1.63,2.77l2.2,2.17c1.87,1.9,2.77,3,2.77,5.31v1c0,2.8-1.5,4.79-5,4.79S147.69,399.91,147.69,397.22Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M158.81,381.19H168V384h-2.84v17.63h-3.47V384h-2.83Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M169.84,381.19h3.44v20.44h-3.44Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M175.17,381.19h9.14V384h-2.84v17.63H178V384h-2.84Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M196.18,381.19v15.46c0,3.17-1.35,5.22-4.85,5.22s-5.12-2.05-5.12-5.22V381.19h3.43V397c0,1.21.48,2,1.69,2s1.69-.81,1.69-2V381.19Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M198.05,381.19h9.13V384h-2.83v17.63h-3.47V384h-2.83Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M209.08,381.19h3.44v20.44h-3.44Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M214.81,396.35v-9.88c0-3.2,1.56-5.52,5.18-5.52s5.22,2.32,5.22,5.52v9.88c0,3.17-1.57,5.52-5.22,5.52S214.81,399.52,214.81,396.35Zm7,.54v-11c0-1.23-.55-2.11-1.78-2.11s-1.75.88-1.75,2.11v11c0,1.24.54,2.11,1.75,2.11s1.78-.87,1.78-2.11Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M230.36,388.79v12.84h-2.9V381.19h3.37l3.71,11.73V381.19h2.87v20.44h-3Z"
            transform="translate(-50 -50)"
          />
          <path
            class="cls-2"
            d="M239.49,397.22v-2.75h3.19v2.8c0,1.15.52,1.81,1.69,1.81s1.57-.72,1.57-1.81v-.78a3.68,3.68,0,0,0-1.54-2.89l-2-2c-2-1.93-2.86-3.1-2.86-5.46v-.69c0-2.53,1.48-4.5,4.82-4.5s4.79,1.69,4.79,4.64v1.66H246v-1.76c0-1.18-.54-1.75-1.63-1.75a1.51,1.51,0,0,0-1.63,1.69v.42c0,1.14.64,1.81,1.63,2.77l2.2,2.17c1.87,1.9,2.78,3,2.78,5.31v1c0,2.8-1.51,4.79-5,4.79S239.49,399.91,239.49,397.22Z"
            transform="translate(-50 -50)"
          />
        </g>
        <g id="ECOHEALTH">
          <path
            class="cls-5"
            d="M657,166.13c56.47,13.81,134.2,57.57,161.44,102.13"
            transform="translate(-50 -50)"
          />
          <polygon
            class="cls-6"
            points="609.95 139.81 575 111.67 616.84 95.47 609.95 139.81"
          />
          <polygon
            class="cls-6"
            points="744 220.12 778.33 249 786.18 204.82 744 220.12"
          />
        </g>
        <g id="RESOURCE-CONFLICTS">
          <path
            class="cls-5"
            d="M342.89,167.07C286.14,180.87,207.52,224.69,180,269.29"
            transform="translate(-50 -50)"
          />
          <polygon
            class="cls-6"
            points="283.05 96.39 324.88 112.62 289.91 140.74 283.05 96.39"
          />
          <polygon
            class="cls-6"
            points="112.29 205.8 120 250 154.43 221.22 112.29 205.8"
          />
        </g>
        <g id="PEACE-AND-HEALTH">
          <path
            class="cls-5"
            d="M329.84,437.3c76.73,18.6,263.67,18,340.22-.27"
            transform="translate(-50 -50)"
          />
          <polygon
            class="cls-6"
            points="278.17 409.91 249.98 375 294.3 368.04 278.17 409.91"
          />
          <polygon
            class="cls-6"
            points="621.63 409.67 650 374.92 605.71 367.72 621.63 409.67"
          />
        </g>
      </svg>
    </b-col>
    <b-col cols="6 align-self-center" id="sdg-svg-text">
      <div id="sdg-default" v-show="activeSDGText === 'sdg-default'">
        <p><em>Click on an SDG icon in the diagram to learn more</em></p>
      </div>
      <p id="sdg3" v-show="activeSDGText === 'sdg3'">
        SDG 3 aims to “ensure healthy lives and promote well-being for all
        at all ages”. It strongly builds on the preceding health-related
        Millennium Development Goals. Among others, the ambition of SDG 3 is
        to promote universal health coverage for all, reduce maternal and
        child mortality and it sets targets for the reduction of the burden
        from communicable and non-communicable diseases.
      </p>
      <p id="sdg15" v-show="activeSDGText === 'sdg15'">
        The protection, restoration and sustainable use of terrestrial
        ecosystems is at the core of SDG 15. It calls for halting
        deforestation, restoring damaged ecosystems and combatting
        desertification. Furthermore, it sets targets for the protection of
        valuable ecosystem services as well as endangered species of fauna
        and flora.
      </p>
      <p id="sdg16" v-show="activeSDGText === 'sdg16'">
        SDG 16 advocates for peaceful and inclusive societies as a
        requirement for sustainable development. It aims at reducing of all
        forms of violence, ending torture and combating all forms of
        organised crime. As a foundation for peace and justice, SDG 16 calls
        for promoting strong, inclusive and transparent institutions,
        decision-making processes and justice systems.
      </p>
    </b-col>
  </b-row>
</template>
<script>

export default {
  components: {
  },
  name: 'sdg-diagram',
  data() {
    return {
      activeSDGText: 'sdg-default',
    };
  },
  methods: {
    setSDGText(textId) {
      this.activeSDGText = textId;
    },
  },
};
</script>
<style lang="scss" scoped>
#sdg-default {
  background-color: none;
  border: 2px solid #444;
  border-radius: 8px;
  display: inline;
  animation: pulse 3s infinite;
  animation-timing-function: ease;
}

@keyframes pulse {
  0% {
    padding: 8px;
  }
  50% {
    padding: 12px 12px 12px 8px;
  }
  100% {
    padding: 8px;
  }
}

#sdg-default p {
  display: inline;
  margin-bottom: 0px;
}

/* SVG Styles */

.cls-1 {
  fill: #4c9f38;
  opacity: 0.7;
  transition: opacity 0.3s;
}
.cls-2 {
  fill: #fff;
}

.cls-3 {
  fill: #56c02b;
  opacity: 0.7;
  transition: opacity 0.3s;
}
.cls-4 {
  fill: #00689d;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.cls-5 {
  fill: none;
  stroke: #9d9d9c;
  stroke-miterlimit: 10;
  stroke-width: 15px;
}

.cls-6 {
  fill: #9d9d9c;
}

.cls-1:hover, .cls-3:hover, .cls-4:hover {
  opacity: 1;
}
.active .cls-1,
.active .cls-2,
.active .cls-3,
.active .cls-4,
.active .cls-5,
.active .cls-6 {
  opacity: 1.0;
}

</style>
