import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

export const paths = {
  landing: '/',
  login: '/auth/login',
  logout: '/auth/logout',
  register: '/auth/register',
  verification: '/auth/verify/:user_id/:timestamp/:signature',
  profile: '/auth/profile',
  about: '/about',
  terms: '/terms',
  understandingSD: '/understanding-sd',
  storyKenya: '/kenya-story',
  storyEthiopia: '/ethiopia-story',
  sdgInteractions: '/sdg-interactions',
  playground: '/playground',
  mapViewer: '/map-viewer',
  metaData: '/meta-data',
  resources: '/resources',
};

const routes = [
  {
    path: paths.landing,
    name: 'landing-page',
    component: () => import(/* webpackChunkName: "landing" */ '../views/Landing.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'Home',
    },
  },
  {
    path: paths.storyKenya,
    name: 'Kenya Story',
    component: () => import(/* webpackChunkName: "story-kenya" */ '../components/posts/kenya_story/StoryKenya.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'Highway to health?',
    },
  },
  {
    path: paths.storyEthiopia,
    name: 'Mining in Ethiopia',
    component: () => import(/* webpackChunkName: "story-ethiopia" */ '../components/posts/ethiopia_story/EthiopiaStory.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'Mining in Ethiopia',
    },
  },
  {
    path: paths.understandingSD,
    name: 'Understanding Sustainable Development',
    component: () => import(/* webpackChunkName: "understanding-sdg" */ '../components/posts/understanding_SD/UnderstandingSD.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'Understanding Sustainable Development',
    },
  },
  {
    path: paths.sdgInteractions,
    name: 'SDG Interactions',
    component: () => import(/* webpackChunkName: "sdg-interactions" */ '../components/posts/SDGInteractions/SDG_Interactions.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'SDG Interactions',
    },
  },
  {
    path: paths.about,
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'About',
    },
  },
  {
    path: paths.terms,
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'Terms',
    },
  },
  {
    path: paths.playground,
    name: 'PlaygroundPage',
    component: () => import(/* webpackChunkName: "playground" */ '../views/PlayGround.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'Playground',
    },
  },
  {
    path: paths.mapViewer,
    name: 'Map Viewer',
    component: () => import(/* webpackChunkName: "map-viewer" */ '../views/MapViewer.vue'),
    meta: {
      requiresAuth: true,
      verbose: 'Map Viewer',
    },
  },
  {
    path: paths.metaData,
    name: 'Metadata Search',
    component: () => import(/* webpackChunkName: "meta-data" */ '../views/Metadata.vue'),
    meta: {
      requiresAuth: true,
      verbose: 'Metadata Search',
    },
  },
  {
    path: paths.resources,
    name: 'Resources',
    component: () => import(/* webpackChunkName: "meta-data" */ '../views/Resources.vue'),
    meta: {
      requiresAuth: true,
      verbose: 'Resources',
    },
  },
  {
    path: paths.login,
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'Login',
    },
  },
  {
    path: paths.register,
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'Login',
    },
  },
  {
    path: paths.verification,
    name: 'verify-email',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Verification.vue'),
    props: true,
    meta: {
      requiresAuth: false,
      verbose: 'Verification',
    },
  },
  {
    path: paths.profile,
    name: 'profile',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Profile.vue'),
    meta: {
      requiresAuth: false,
      verbose: 'Profile',
    },
  },
  {
    path: paths.logout,
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/auth/Logout.vue'),
    meta: {
      requiresAuth: true,
      verbose: 'Logout',
    },
  },
];

const router = new VueRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // if (!auth.loggedIn()) {
    if (!store.getters['auth/isAuthenticated']) {
      next({
        path: paths.login,
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
