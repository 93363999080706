import {
  createRegExpFrom, distinct, asComparator,
  asArray, asCaseInsensitiveComparator, isNullOrUndef,
  isEmptyArray,
} from '@/common';
import APIActions from '@/api/APIActions';

import { handleErrors } from '../../api/APIErrorHandler';

const GISDataActions = APIActions.GISData;
const filterableProperties = [
  'web_link', 'title', 'category',
  'subject', 'description', 'style_file_name',
  'dataset_name', 'creator', 'contributor',
  'publisher',
];

export default {
  namespaced: true,
  state: {
    gisData: null,
    filter: null,
    categoryFilter: null,
    sdgFilter: null,
    previewFilter: null,
    loading: false,
  },
  actions: {
    async loadData({ commit, state, dispatch }, payload) {
      await dispatch('resetFilter');

      let responseData = null;
      if (state.gisData === null || (payload && payload.force)) {
        await commit('setLoading', true);
        try {
          const response = await GISDataActions.get();
          responseData = (response) ? response.data : null;
          await commit('setGisData', responseData);
        } catch (errorResponse) {
          const errors = handleErrors(responseData, payload.errorFields);
          errors.forEach((errMessage) => {
            dispatch('toasts/addErrorToast', errMessage, { root: true });
          });
        } finally {
          await commit('setLoading', false);
        }
      } else {
        responseData = state.gisData;
      }
      return responseData;
    },
    async resetFilter({ commit }) {
      await commit('setPreviewFilter', null);
      await commit('setFilter', null);
      await commit('setCategoryFilter', null);
      await commit('setSDGFilter', null);
    },
    applyPreviewFilter({ commit }, payload) {
      commit('setPreviewFilter', payload);
    },
    applyFilter({ commit }, payload) {
      commit('setFilter', payload);
    },
    applyCategoryFilter({ commit }, payload) {
      commit('setCategoryFilter', payload);
    },
    applySDGFilter({ commit }, payload) {
      commit('setSDGFilter', payload);
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.gisData = (loading) ? null : state.gisData;
      state.loading = loading;
    },
    setGisData(state, data) {
      // (optional, if needed) modify DTOs to accumulate data
      state.gisData = data;
    },
    setPreviewFilter(state, filter) {
      state.previewFilter = filter;
    },
    setFilter(state, filter) {
      if (!isNullOrUndef(filter)) {
        let filterExtract = String(filter);
        const toApply = [];

        // extract all "" terms
        asArray(filterExtract.matchAll(/(")(.*?)\1/gi)).forEach(
          (match) => {
            toApply.push(match[2]);
            filterExtract = filterExtract.replace(match[0], '');
          },
        );

        // split by white space (' ') terms
        filterExtract.split(' ').forEach((f) => toApply.push(f));

        // remove too short expressions
        state.filter = distinct(
          toApply.filter((f) => f.length > 1).sort(asComparator((f) => f.length, true)),
        );
      } else {
        state.filter = null;
      }
    },
    setCategoryFilter(state, filter) {
      if (!isEmptyArray(filter)) {
        state.categoryFilter = [...filter];
      } else {
        state.categoryFilter = null;
      }
    },
    setSDGFilter(state, filter) {
      if (!isEmptyArray(filter)) {
        state.sdgFilter = [...filter];
      } else {
        state.sdgFilter = null;
      }
    },
  },
  getters: {
    gisData: (state) => state.gisData,
    loading: (state) => state.loading,
    loaded: (state) => state.gisData !== null,

    categories: (state) => {
      if (state.gisData) {
        return distinct(state.gisData.map((d) => d.category)).sort(asCaseInsensitiveComparator());
      }
      return [];
    },
    sdgs: (state) => {
      if (state.gisData) {
        return distinct(
          state.gisData.reduce(
            (prev, current) => [...prev, ...current.sdg.map((sdg) => sdg.name)],
            [],
          ),
        ).sort(asComparator());
      }
      return [];
    },

    filteredDataPreview: (state) => {
      if (state.previewFilter && state.previewFilter.length > 2 && state.gisData) {
        const regex = createRegExpFrom(state.previewFilter);

        return distinct(
          state.gisData
            .map((d) => d.title || '')
            .filter((d) => regex.test(d)),
        ).sort();
      }
      return [];
    },

    filteredData: (state) => {
      if (!state.gisData) {
        return {};
      }

      const startTime = Date.now();
      let data = null;
      let expressions = [];
      let filteredData = state.gisData;

      const hasFilters = state.filter || state.categoryFilter || state.sdgFilter;

      if (hasFilters) {
        // 1. filter by category
        if (state.categoryFilter) {
          const indexedFilter = { };
          state.categoryFilter.forEach(
            (item) => { indexedFilter[item] = item; },
          );
          filteredData = filteredData.filter((d) => indexedFilter[d.category]);
        }

        // 2. filter by SDG
        if (state.sdgFilter) {
          const indexedFilter = { };
          state.sdgFilter.forEach(
            (item) => { indexedFilter[item] = item; },
          );
          filteredData = filteredData.filter(
            (d) => d.sdg.some((sdg) => indexedFilter[sdg.name]),
          );
        }

        // 3. filter by search expression
        if (state.filter) {
          const searchResult = {};
          expressions = state.filter.map((f) => createRegExpFrom(f));
          expressions.forEach((expr) => {
            filteredData.forEach((gisEntry) => {
              if (!searchResult[gisEntry.id]
                && filterableProperties.some((prop) => expr.test(gisEntry[prop]))) {
                searchResult[gisEntry.id] = gisEntry;
              }
            });
          });
          filteredData = searchResult;
        }
      }
      data = Object.values(filteredData).sort(asComparator((result) => result.title));
      return { expressions, data, duration: Date.now() - startTime };
    },
  },
};
