import axios from 'axios';
import environment from '../environment';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.withCredentials = true;

export default axios.create({
  baseURL: environment.apiBaseURL,
});
