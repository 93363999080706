<template>
  <!-- Header -->
  <b-card
    overlay
    img-src="@/assets/blog/understanding_sd/images/eth_school-children-2.jpg"
    text-variant="light"
  >
    <b-card-body class="text-center mt-2">
      <b-card-title class="title">
        Education for Sustainable Development
      </b-card-title>
      <b-card-sub-title class="subtitle mt-3" sub-title-text-variant="light">
        Enhancing competences to understand and address sustainability problems
      </b-card-sub-title>
    </b-card-body>
  </b-card>
</template>
<script>
import environment from '../../../environment';

export default {
  name: 'story-header',
  components: {},
  data() {
    return {
      assetsURL: environment.assetsURL,
      defaultImgProps: {
        fluid: true,
      },
    };
  },
  methods: {},
};
</script>
<style type="scss" scoped>
.title {
  font-size: 5rem;
}
.subtitle {
  font-size: 2rem;
  font-style: italic;
}
</style>
