<template>
  <!-- eslint-disable max-len -->
  <div>
    <b-row>
    <b-col>
      <p>
        In the northern part of Kenya, a new, <span style='color: #dd982d; font-weight: bold'>504 kilometre-long highway</span> was completed in 2016. The highway connects the towns of Isiolo, north of Mt. Kenya with Moyale, on the border with Ethiopia. The highway is part of the Lamu Port, South Sudan, Ethiopia Transport Corridor (LAPSSET) Project, an inter-regional transport network in Kenya that connects the coastal port in Lamu with South Sudan in the northwest and Ethiopia in the north, transecting major towns in Kenya. Before the highway was constructed, the drive from Nairobi to Moyale was a three-day journey on a mostly dusty road. Today, it can be done in under 10 hours.
      </p>
      <span>
        <input
           id="checkbox"
           v-model="toggleLAPSSET"
           type="checkbox"
           style="margin-right: 5px"
          >
          <label for="checkbox">Show the LAPSSET corridor on the map</label>
        <br>
        <input
          id="checkbox2"
          v-model="toggleStudyArea"
          type="checkbox"
          style="margin-right: 5px"
        >
        <label for="checkbox2">
          Show the study area on the map
        </label>
      </span>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <!-- some content otherwise column is empty -->
      <!-- Overview Map -->
      <l-map
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        :max-bounds="maxBounds"
        @ready="enableZoomOnClick"
        ref="overviewmap"
        style="height: 500px; width: 100%"
      >
        <l-geo-json
          v-if="toggleLAPSSET"
          :geojson="geojsonLAPSSET"
          :options="geojsonOptions"
          :options-style="styleFunctionLAPSSET"
        />
        <l-geo-json
          v-if="toggleStudyArea"
          :geojson="geojsonStudyArea"
          :options="geojsonOptions"
          :options-style="styleFunctionStudyArea"
        />
        <l-geo-json
          v-if="geojsonHighway"
          :geojson="geojsonHighway"
          :options="geojsonOptions"
          :options-style="styleFunctionHighway"
        />
        <l-tile-layer
          v-for="tileProvider in baseLayers"
          :key="tileProvider.id"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"
          :options="{
            maxNativeZoom: 17,
          }"
        ></l-tile-layer>
      </l-map>
    </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  LMap, LTileLayer, LGeoJson,
} from 'vue2-leaflet';
import { latLng, latLngBounds } from './map/leaflet-utils';
import baseLayers from './map/osm-layer-settings';
import environment from '../../../environment';

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  name: 'laps-set',
  data() {
    return {
      center: latLng(0, 38),
      zoom: 6,
      mapOptions: {
        minZoom: 5,
        maxZoom: 10,
        scrollWheelZoom: false,
      },
      maxBounds: latLngBounds([
        [-5, 33.5],
        [11.5, 42.5],
      ]),
      geojsonOptions: {},
      baseLayers,
      geojsonHighway: null,
      geojsonLAPSSET: null,
      geojsonStudyArea: null,
      toggleLAPSSET: false,
      toggleStudyArea: false,
    };
  },
  async mounted() {
    const responseLAPSSET = await fetch(`${environment.assetsURL}blog/kenya_story/data/LAPSSET_KEN-ETH.geojson`);
    if (responseLAPSSET.ok) {
      this.geojsonLAPSSET = await responseLAPSSET.json();
    } else {
      console.error(`HTTP-Error: ${responseLAPSSET.status}`);
    }

    const responseHighway = await fetch(`${environment.assetsURL}blog/kenya_story/data/KEN_LAPSSET_highway.geojson`);
    if (responseHighway.ok) {
      this.geojsonHighway = await responseHighway.json();
    } else {
      console.error(`HTTP-Error: ${responseHighway.status}`);
    }

    const responseStudyArea = await fetch(`${environment.assetsURL}blog/kenya_story/data/KEN_studyarea.geojson`);
    if (responseStudyArea.ok) {
      this.geojsonStudyArea = await responseStudyArea.json();
    } else {
      console.error(`HTTP-Error: ${responseStudyArea.status}`);
    }
  },
  methods: {
    enableZoomOnClick() {
      this.map = this.$refs.overviewmap.mapObject;
      this.map.on('focus', () => this.map.scrollWheelZoom.enable());
      this.map.on('blur', () => this.map.scrollWheelZoom.disable());
    },
    styleFunctionHighway() {
      return {
        weight: 7,
        opacity: 1,
        color: '#dd982d',
      };
    },
    styleFunctionLAPSSET() {
      return {
        fillColor: '#cdcdcd',
        weight: 2,
        opacity: 1,
        color: '#9a9a9a',
        dashArray: '3',
        fillOpacity: 0.3,
      };
    },
    styleFunctionStudyArea() {
      return {
        fillColor: '#dcdcdc',
        weight: 3,
        opacity: 1,
        color: '#232323',
        dashArray: '3',
        fillOpacity: 0,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
