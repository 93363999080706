<template>
  <b-container>
    <h2>Log out of your account</h2>
    <b-button @click.prevent="logout">
      Log Out
    </b-button>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import { paths } from '@/router';

export default {
  data() {
    return {
      username: '',
      password: '',
      errors: {},
    };
  },
  methods: {
    ...mapActions({ logOut: 'auth/logOut' }),
    logout() {
      this.logOut();
      this.$router.push(paths.landing);
    },
  },
};
</script>
