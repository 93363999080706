import { not, isNullOrUndef } from './globals';

export function shuffle(arr) {
  return arr.map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
}

export function firstOrDefault(arr, objDefault) {
  return (arr && arr.find(not(isNullOrUndef))) || objDefault;
}

export function distinct(arr, identityResolver = (x) => x) {
  const table = { };
  return arr.filter((elem) => {
    const identity = identityResolver(elem);
    if (!table[identity]) {
      table[identity] = elem;
      return true;
    }
    return false;
  });
}

export function isArray(toCheck) {
  return (typeof (toCheck) === 'object' && toCheck instanceof Array);
}

export function isEmptyArray(toCheck) {
  return (isNullOrUndef(toCheck) || !toCheck.length);
}

export function asArray(toConvert) {
  if (isNullOrUndef(toConvert)) {
    return [];
  }
  if (toConvert instanceof Array) {
    return toConvert;
  }
  if (typeof (toConvert) === 'object' && ('length' in toConvert || 'next' in toConvert)) {
    return [...toConvert];
  }
  return [toConvert];
}

export function asComparator(propResolver = (x) => x, sortDesc = false) {
  const inversion = (sortDesc) ? -1 : 1;
  return (a, b) => {
    const aVal = propResolver(a);
    const bVal = propResolver(b);
    if (aVal < bVal) {
      return -1 * inversion; // a is less than b by some ordering criterion
    }
    if (aVal > bVal) {
      return 1 * inversion; // a is greater than b by the ordering criterion
    }
    return 0; // a must be equal to b
  };
}
