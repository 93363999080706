<template>
  <!-- eslint-disable max-len -->
  <!-- All text content is based on Version April 28 sent by MP -->
  <b-row>
    <b-col cols="1">&nbsp;</b-col>
    <b-col cols="6">
      <svg class="donut-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1000px"
          height="1000px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
        <g class="donutOuterGlow"
          @mouseover="setDonutText('donut-outer-glow-text')"
          :class="{ active: activeDonutText === 'donut-outer-glow-text' }">
          <defs>
            <radialGradient id="donutOuterGlow2" cx="500.001" cy="500" r="500" gradientUnits="userSpaceOnUse">
              <stop  offset="0.6" style="stop-color:#FF1E1E"/>
              <stop  offset="1" style="stop-color:#FF1E1E;stop-opacity:0"/>
            </radialGradient>
          </defs>
          <path class="donutOuterGlow1" fill="url(#donutOuterGlow2)" opacity="0.4" d="M500,0C223.858,0,0.001,223.857,0.001,500
            S223.858,1000,500,1000c276.143,0,500.001-223.857,500.001-500S776.143,0,500,0z M500,697.999c-109.352,0-198-88.647-198-197.999
            c0-109.353,88.647-198,198-198c109.353,0,198,88.647,198,198C698,609.352,609.354,697.999,500,697.999z"/>
        </g>
        <g class="donutOuterBorder"
          @mouseover="setDonutText('donut-outer-border-text')"
          :class="{ active: activeDonutText === 'donut-outer-border-text' }">
          <path fill="#4BB45A" d="M500,131.882c-203.306,0-368.117,164.812-368.117,368.118c0,203.306,164.812,368.118,368.117,368.118
            c203.307,0,368.119-164.813,368.119-368.118C868.119,296.694,703.307,131.882,500,131.882z M500,837.989
            c-186.667,0-337.989-151.323-337.989-337.989c0-186.667,151.322-337.99,337.989-337.99c186.668,0,337.991,151.323,337.991,337.99
            C837.991,686.666,686.668,837.989,500,837.989z"/>
        </g>
        <g class="donutRingFill"
          @mouseover="setDonutText('donut-ring-fill-text')"
          :class="{ active: activeDonutText === 'donut-ring-fill-text' }">
            <path fill="#F0E696" d="M500,162.01c-186.667,0-337.989,151.323-337.989,337.99c0,186.666,151.322,337.989,337.989,337.989
            c186.668,0,337.991-151.323,337.991-337.989C837.991,313.333,686.668,162.01,500,162.01z M500,728.127
            c-125.991,0-228.127-102.136-228.127-228.127c0-125.992,102.136-228.128,228.127-228.128
            c125.992,0,228.127,102.136,228.127,228.128C728.128,625.991,625.992,728.127,500,728.127z"/>
        </g>
        <g class="donutInnerBorder"
          @mouseover="setDonutText('donut-inner-border-text')"
          :class="{ active: activeDonutText === 'donut-inner-border-text' }">
          <path class="donutInnerBorder1" fill="#4BB45A" d="M500,271.872c-125.991,0-228.127,102.136-228.127,228.128
            c0,125.991,102.136,228.127,228.127,228.127c125.992,0,228.127-102.136,228.127-228.127
            C728.128,374.008,625.992,271.872,500,271.872z M500,697.999c-109.352,0-198-88.647-198-197.999c0-109.353,88.647-198,198-198
            c109.353,0,198,88.647,198,198C698,609.352,609.354,697.999,500,697.999z"/>
        </g>
        <g class="donutInnerGlow"
          @mouseover="setDonutText('donut-inner-glow-text')"
          :class="{ active: activeDonutText === 'donut-inner-glow-text' }">
          <defs>
            <radialGradient id="donutInnerGlow2" cx="500.0005" cy="499.9995" r="197.9995" gradientUnits="userSpaceOnUse">
              <stop  offset="0" style="stop-color:#FFFFFF;stop-opacity:0"/>
              <stop  offset="0.6005" style="stop-color:#FF1E1E;stop-opacity:0"/>
              <stop  offset="1" style="stop-color:#FF1E1E"/>
            </radialGradient>
          </defs>
          <circle class="donutInnerGlow1" fill="url(#donutInnerGlow2)" opacity="0.4" cx="500" cy="500" r="198"/>
        </g>
        <g class="donutInnerArrow"
          @mouseover="setDonutText('donut-inner-glow-text')"
          :class="{ active: activeDonutText === 'donut-inner-glow-text' }"
        >
          <path d="M525.799,403.684L500,500l96.316-25.798l-17.581-17.581l77.828-77.828c-10.25-13.22-22.136-25.106-35.355-35.356
            l-77.828,77.828L525.799,403.684z"/>
        </g>
        <g class="donutOuterArrow"
          @mouseover="setDonutText('donut-outer-glow-text')"
          :class="{ active: activeDonutText === 'donut-outer-glow-text' }"
        >
          <path d="M819.073,110.41l17.581,17.581l-94.634,94.634c12.566,10.975,24.381,22.789,35.355,35.355l94.634-94.634l17.58,17.581
            l25.799-96.315L819.073,110.41z"/>
        </g>
        <g class="donutText">
          <g>
            <path fill="#FFFFFF" d="M792.141,208.517c3.751,3.751,3.751,8.299,0.407,11.642c-3.395,3.395-7.942,2.818-11.201-0.441
              c-3.428-3.428-3.802-8.181-0.424-11.558C784.436,204.646,788.984,205.36,792.141,208.517z M784.028,216.934
              c2.241,2.241,4.871,2.766,6.602,1.035c1.748-1.748,1.087-4.412-1.137-6.635c-2.054-2.054-4.803-2.835-6.585-1.053
              C781.159,212.03,781.806,214.711,784.028,216.934z"/>
            <path fill="#FFFFFF" d="M802.256,210.078l-15.105-7.773l2.835-2.834l6.229,3.446c1.748,0.967,3.412,1.917,5.108,3.071l0.051-0.051
              c-1.086-1.662-2.087-3.377-3.003-5.074l-3.43-6.349l2.75-2.749l7.586,15.292L802.256,210.078z"/>
            <path fill="#FFFFFF" d="M812.319,190.341l-4.209,4.209l2.716,2.716l4.701-4.701l2.121,2.121l-7.298,7.298l-11.439-11.439
              l7.061-7.061l2.122,2.122l-4.464,4.464l2.377,2.376l4.209-4.209L812.319,190.341z"/>
            <path fill="#FFFFFF" d="M808.126,181.636c0.695-0.967,1.832-2.309,3.207-3.684c1.697-1.697,3.141-2.63,4.6-2.8
              c1.223-0.136,2.394,0.29,3.445,1.342c1.459,1.459,1.426,3.496,0.799,4.836l0.051,0.05c1.119-0.475,2.324-0.152,3.666,0.611
              c1.646,0.934,3.496,2.071,4.157,2.292l-2.647,2.647c-0.509-0.136-1.714-0.798-3.463-1.867c-1.73-1.12-2.613-1.019-3.682,0.017
              l-0.781,0.781l4.497,4.498l-2.563,2.563L808.126,181.636z M815.61,183.995l1.018-1.019c1.291-1.29,1.41-2.698,0.408-3.7
              c-1.053-1.052-2.291-0.866-3.496,0.306c-0.628,0.628-0.934,1.036-1.086,1.256L815.61,183.995z"/>
            <path fill="#FFFFFF" d="M826.896,180.109c1.053-0.34,2.479-1.052,3.582-2.155c1.188-1.188,1.323-2.309,0.576-3.056
              c-0.713-0.712-1.664-0.577-3.53,0.306c-2.562,1.239-4.854,1.426-6.518-0.237c-1.952-1.952-1.815-5.075,0.884-7.774
              c1.289-1.29,2.511-1.968,3.496-2.341l1.511,2.664c-0.68,0.237-1.817,0.73-2.937,1.85c-1.121,1.12-1.154,2.173-0.561,2.767
              c0.73,0.73,1.697,0.407,3.734-0.51c2.766-1.272,4.77-1.171,6.381,0.441c1.918,1.918,2.07,5.024-1.068,8.164
              c-1.308,1.308-2.938,2.257-3.938,2.546L826.896,180.109z"/>
            <path fill="#FFFFFF" d="M829.797,159.658l4.396,4.396l4.261-4.26l-4.396-4.396l2.579-2.58l11.439,11.439l-2.579,2.58l-4.787-4.786
              l-4.26,4.26l4.786,4.786l-2.597,2.597L827.2,162.255L829.797,159.658z"/>
            <path fill="#FFFFFF" d="M854.966,145.691c3.751,3.751,3.75,8.299,0.408,11.642c-3.396,3.395-7.943,2.818-11.203-0.44
              c-3.428-3.428-3.801-8.181-0.424-11.558C847.261,141.821,851.81,142.535,854.966,145.691z M846.853,154.11
              c2.241,2.241,4.871,2.766,6.603,1.035c1.749-1.749,1.087-4.412-1.137-6.636c-2.054-2.054-4.804-2.834-6.585-1.053
              C843.985,149.205,844.63,151.887,846.853,154.11z"/>
            <path fill="#FFFFFF" d="M867.134,133.523c3.751,3.751,3.75,8.299,0.406,11.643c-3.394,3.394-7.941,2.817-11.201-0.441
              c-3.428-3.428-3.801-8.181-0.424-11.558C859.429,129.653,863.978,130.367,867.134,133.523z M859.021,141.942
              c2.241,2.241,4.871,2.766,6.603,1.035c1.748-1.748,1.087-4.412-1.137-6.636c-2.054-2.054-4.804-2.834-6.585-1.053
              C856.153,137.037,856.798,139.719,859.021,141.942z"/>
            <path fill="#FFFFFF" d="M867.132,126.667l-3.071,3.072l-2.173-2.172l8.792-8.792l2.173,2.172l-3.123,3.123l9.268,9.267
              l-2.598,2.597L867.132,126.667z"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M535.69,471.314c1.051-0.339,2.479-1.052,3.58-2.154c1.189-1.188,1.324-2.309,0.577-3.056
              c-0.712-0.712-1.663-0.577-3.53,0.305c-2.563,1.24-4.854,1.426-6.518-0.237c-1.951-1.952-1.816-5.074,0.883-7.773
              c1.29-1.29,2.512-1.969,3.497-2.342l1.511,2.665c-0.68,0.237-1.816,0.729-2.937,1.849c-1.121,1.121-1.153,2.173-0.56,2.767
              c0.729,0.73,1.697,0.407,3.732-0.509c2.768-1.273,4.77-1.171,6.382,0.441c1.918,1.918,2.071,5.024-1.069,8.164
              c-1.307,1.307-2.936,2.257-3.937,2.545L535.69,471.314z"/>
            <path fill="#FFFFFF" d="M538.591,450.864l4.396,4.396l4.26-4.26l-4.396-4.396l2.58-2.58l11.439,11.439l-2.58,2.58l-4.786-4.786
              l-4.26,4.26l4.786,4.786l-2.598,2.597l-11.439-11.439L538.591,450.864z"/>
            <path fill="#FFFFFF" d="M563.76,436.897c3.751,3.751,3.75,8.3,0.407,11.643c-3.395,3.395-7.943,2.818-11.202-0.44
              c-3.429-3.428-3.802-8.181-0.424-11.559C556.054,433.027,560.604,433.741,563.76,436.897z M555.646,445.316
              c2.241,2.241,4.871,2.766,6.603,1.035c1.748-1.749,1.086-4.412-1.137-6.636c-2.055-2.054-4.804-2.834-6.586-1.053
              C552.778,440.411,553.423,443.093,555.646,445.316z"/>
            <path fill="#FFFFFF" d="M560.008,429.753c0.696-0.967,1.833-2.309,3.208-3.684c1.696-1.697,3.139-2.63,4.6-2.8
              c1.222-0.135,2.393,0.29,3.445,1.342c1.459,1.459,1.425,3.496,0.798,4.836l0.05,0.05c1.12-0.475,2.326-0.152,3.666,0.611
              c1.646,0.934,3.497,2.071,4.158,2.292l-2.647,2.647c-0.509-0.136-1.714-0.798-3.462-1.867c-1.73-1.12-2.614-1.019-3.684,0.017
              l-0.781,0.781l4.498,4.498l-2.563,2.563L560.008,429.753z M567.491,432.112l1.02-1.019c1.289-1.29,1.408-2.698,0.407-3.7
              c-1.052-1.052-2.292-0.866-3.496,0.306c-0.628,0.628-0.933,1.036-1.087,1.256L567.491,432.112z"/>
            <path fill="#FFFFFF" d="M574.296,419.503l-3.071,3.072l-2.173-2.172l8.792-8.792l2.173,2.172l-3.123,3.123l9.268,9.267
              l-2.598,2.597L574.296,419.503z"/>
            <path fill="#FFFFFF" d="M579.218,410.237l6.992-6.992l2.121,2.122l-4.395,4.395l2.613,2.614l4.107-4.107l2.104,2.104l-4.106,4.107
              l4.6,4.6l-2.597,2.597L579.218,410.237z"/>
            <path fill="#FFFFFF" d="M598.192,408.27l2.121,3.751l-2.682,2.681l-7.943-14.936l3.395-3.395l14.986,7.893l-2.783,2.783
              l-3.819-2.053L598.192,408.27z M599.159,403.432l-3.141-1.714c-0.882-0.475-1.934-1.12-2.783-1.629l-0.033,0.034
              c0.509,0.849,1.205,1.885,1.68,2.732l1.748,3.106L599.159,403.432z"/>
            <path fill="#FFFFFF" d="M598.156,391.298l2.597-2.597l9.268,9.267l4.549-4.549l2.172,2.172l-7.146,7.146L598.156,391.298z"/>
            <path fill="#FFFFFF" d="M606.828,382.626l2.597-2.597l9.268,9.267l4.549-4.549l2.172,2.172l-7.146,7.146L606.828,382.626z"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M391.729,323.042c1.107-0.061,2.672-0.388,4.024-1.177c1.456-0.849,1.874-1.901,1.34-2.816
              c-0.509-0.874-1.466-0.985-3.502-0.605c-2.802,0.547-5.073,0.144-6.262-1.895c-1.395-2.392-0.466-5.386,2.841-7.315
              c1.581-0.922,2.939-1.269,3.989-1.379l0.785,2.971c-0.719,0.057-1.948,0.244-3.321,1.045c-1.373,0.8-1.674,1.813-1.25,2.541
              c0.521,0.894,1.542,0.829,3.752,0.459c3.009-0.529,4.926,0.082,6.078,2.057c1.371,2.351,0.726,5.402-3.122,7.646
              c-1.602,0.934-3.425,1.44-4.47,1.464L391.729,323.042z"/>
            <path fill="#FFFFFF" d="M414.229,306.029c2.337,4.782,0.854,9.098-3.408,11.181c-4.327,2.115-8.456,0.085-10.486-4.069
              c-2.136-4.371-0.941-9.002,3.364-11.106C408.179,299.846,412.263,302.005,414.229,306.029z M403.787,311.374
              c1.396,2.856,3.721,4.212,5.928,3.134c2.229-1.089,2.469-3.833,1.084-6.668c-1.28-2.618-3.634-4.255-5.906-3.145
              C402.664,305.785,402.402,308.54,403.787,311.374z"/>
            <path fill="#FFFFFF" d="M430.722,307.871c-0.502,0.562-1.778,1.458-3.612,2.188c-5.212,2.073-9.186-0.103-10.894-4.398
              c-2.046-5.145,0.484-9.467,5.047-11.282c1.767-0.703,3.251-0.878,3.98-0.806l0.383,2.982c-0.809-0.015-1.877,0.099-3.085,0.58
              c-2.707,1.077-4.16,3.545-2.826,6.901c1.201,3.02,3.747,4.21,6.789,3c1.029-0.409,2.081-1.086,2.645-1.622L430.722,307.871z"/>
            <path fill="#FFFFFF" d="M431.278,291.095l5.214,15.365l-3.488,1.184l-5.214-15.365L431.278,291.095z"/>
            <path fill="#FFFFFF" d="M441.811,300.441l0.006,4.323l-3.665,1.022l0.417-16.965l4.639-1.294l9.208,14.28l-3.804,1.061
              l-2.325-3.676L441.811,300.441z M445.04,296.69l-1.899-3.045c-0.537-0.85-1.139-1.932-1.63-2.795l-0.046,0.013
              c0.027,0.992,0.125,2.24,0.122,3.216l-0.002,3.575L445.04,296.69z"/>
            <path fill="#FFFFFF" d="M451.004,285.564l3.607-0.745l2.658,12.872l6.318-1.305l0.623,3.018l-9.925,2.049L451.004,285.564z"/>
            <path fill="#FFFFFF" d="M468.896,282.245l9.851-1.182l0.359,2.989l-6.193,0.743l0.442,3.682l5.786-0.694l0.355,2.965l-5.786,0.694
              l0.777,6.479l-3.658,0.439L468.896,282.245z"/>
            <path fill="#FFFFFF" d="M497.595,287.924c0.252,5.314-2.816,8.69-7.554,8.916c-4.81,0.229-7.796-3.269-8.016-7.886
              c-0.231-4.858,2.698-8.637,7.483-8.864C494.487,279.854,497.382,283.452,497.595,287.924z M485.894,288.698
              c0.151,3.174,1.749,5.34,4.201,5.223c2.477-0.118,3.784-2.542,3.634-5.692c-0.139-2.91-1.652-5.344-4.177-5.224
              C487.074,283.123,485.744,285.548,485.894,288.698z"/>
            <path fill="#FFFFFF" d="M504.264,280.127l-0.302,9.336c-0.091,2.792,0.922,4.245,2.799,4.306c1.926,0.062,3.027-1.251,3.12-4.115
              l0.302-9.336l3.658,0.118l-0.294,9.096c-0.162,5.005-2.766,7.306-7.001,7.168c-4.091-0.132-6.424-2.472-6.257-7.646l0.292-9.048
              L504.264,280.127z"/>
            <path fill="#FFFFFF" d="M516.18,296.859l1.811-16.126l4.258,0.478l2.687,6.286c0.767,1.806,1.498,3.923,2.012,5.822l0.071,0.008
              c0-2.156,0.173-4.342,0.444-6.758l0.528-4.713l3.35,0.376l-1.81,16.126l-3.828-0.43l-2.747-6.607
              c-0.764-1.83-1.583-4.03-2.16-6.009l-0.074,0.016c-0.144,2.14-0.351,4.418-0.646,7.05l-0.545,4.857L516.18,296.859z"/>
            <path fill="#FFFFFF" d="M535.431,283.069c1.365,0.045,3.114,0.263,4.934,0.618c3.025,0.59,4.882,1.516,6.192,2.974
              c1.414,1.552,2.072,3.716,1.523,6.529c-0.595,3.049-2.115,4.936-3.905,5.937c-1.95,1.067-4.632,1.231-7.751,0.623
              c-1.868-0.364-3.168-0.74-4.043-1.033L535.431,283.069z M536.513,296.774c0.294,0.131,0.79,0.228,1.239,0.315
              c3.257,0.66,5.734-0.722,6.477-4.527c0.669-3.304-0.929-5.431-4.025-6.035c-0.803-0.157-1.337-0.188-1.658-0.176L536.513,296.774z
              "/>
            <path fill="#FFFFFF" d="M552.854,298.96l-2.223,3.708l-3.667-1.013l9.101-14.322l4.642,1.282l0.531,16.982l-3.807-1.051
              l-0.098-4.348L552.854,298.96z M557.553,297.41l-0.059-3.588c-0.021-1.005,0.021-2.243,0.044-3.235l-0.046-0.013
              c-0.488,0.864-1.048,1.984-1.553,2.818l-1.845,3.063L557.553,297.41z"/>
            <path fill="#FFFFFF" d="M568.388,294.225l-4.123-1.414l1-2.916l11.801,4.047l-1,2.916l-4.191-1.438l-4.266,12.438l-3.486-1.195
              L568.388,294.225z"/>
            <path fill="#FFFFFF" d="M582.828,296.289l-6.007,15.077l-3.422-1.363l6.006-15.077L582.828,296.289z"/>
            <path fill="#FFFFFF" d="M596.566,311.192c-2.253,4.819-6.541,6.375-10.836,4.367c-4.361-2.039-5.373-6.523-3.416-10.71
              c2.059-4.405,6.41-6.382,10.75-4.354C597.578,302.604,598.462,307.136,596.566,311.192z M585.855,306.424
              c-1.346,2.878-0.941,5.538,1.282,6.578c2.246,1.05,4.531-0.486,5.867-3.342c1.233-2.638,1.028-5.497-1.261-6.567
              C589.498,302.042,587.19,303.568,585.855,306.424z"/>
            <path fill="#FFFFFF" d="M594.896,319.698l8.053-14.09l3.721,2.127l-0.024,6.836c-0.012,1.962-0.18,4.196-0.46,6.143l0.063,0.036
              c0.854-1.98,1.879-3.918,3.085-6.03l2.354-4.118l2.926,1.673l-8.053,14.09l-3.344-1.912l0.096-7.155
              c0.024-1.983,0.144-4.328,0.397-6.373l-0.074-0.015c-0.979,1.908-2.073,3.918-3.388,6.218l-2.425,4.244L594.896,319.698z"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M397.441,164.293l-5.668,1.809l1.167,3.657l6.332-2.021l0.912,2.857l-9.829,3.136l-4.916-15.406
              l9.509-3.034l0.912,2.857l-6.012,1.918l1.021,3.2l5.668-1.809L397.441,164.293z"/>
            <path fill="#FFFFFF" d="M413.753,166.32c-0.559,0.503-1.92,1.253-3.817,1.777c-5.39,1.49-9.093-1.097-10.32-5.539
              c-1.47-5.32,1.505-9.33,6.224-10.634c1.828-0.505,3.318-0.519,4.032-0.367l0.057,2.997c-0.8-0.103-1.872-0.105-3.121,0.24
              c-2.799,0.773-4.506,3.063-3.547,6.533c0.863,3.123,3.257,4.578,6.403,3.708c1.064-0.294,2.18-0.852,2.797-1.321L413.753,166.32z"
              />
            <path fill="#FFFFFF" d="M427.918,155.002c1.19,5.169-1.222,9.026-5.831,10.087c-4.678,1.077-8.229-1.825-9.263-6.316
              c-1.088-4.725,1.116-8.952,5.771-10.024C423.438,147.634,426.916,150.651,427.918,155.002z M416.575,157.836
              c0.711,3.088,2.662,4.929,5.048,4.38c2.409-0.555,3.262-3.165,2.556-6.229c-0.651-2.83-2.568-4.951-5.024-4.385
              C416.745,152.156,415.869,154.772,416.575,157.836z"/>
            <path fill="#FFFFFF" d="M429.018,146.765l3.609-0.67l2.391,12.88l6.322-1.174l0.561,3.02l-9.931,1.844L429.018,146.765z"/>
            <path fill="#FFFFFF" d="M456.442,150.313c0.762,5.25-1.959,8.896-6.64,9.575c-4.752,0.69-8.052-2.494-8.714-7.056
              c-0.696-4.799,1.848-8.832,6.575-9.518C452.582,142.602,455.801,145.895,456.442,150.313z M444.903,152.207
              c0.455,3.136,2.249,5.132,4.672,4.78c2.447-0.355,3.511-2.887,3.059-5.999c-0.417-2.875-2.154-5.146-4.648-4.784
              C445.539,146.559,444.451,149.094,444.903,152.207z"/>
            <path fill="#FFFFFF" d="M473.207,156.215c-1.086,0.491-3.162,1.221-5.289,1.426c-2.939,0.284-5.137-0.252-6.757-1.543
              c-1.615-1.243-2.63-3.268-2.835-5.636c-0.492-5.354,3.108-8.789,8.389-9.298c2.079-0.201,3.719,0.051,4.544,0.357l-0.483,2.989
              c-0.921-0.297-2.05-0.502-3.818-0.332c-3.035,0.293-5.163,2.234-4.826,5.723c0.32,3.321,2.588,5.081,5.575,4.792
              c0.836-0.081,1.496-0.241,1.769-0.412l-0.325-3.369l-2.485,0.24l-0.274-2.844l5.998-0.578L473.207,156.215z"/>
            <path fill="#FFFFFF" d="M478.656,140.446l1.018,16.146l-3.666,0.231l-1.018-16.146L478.656,140.446z"/>
            <path fill="#FFFFFF" d="M494.56,155.542c-0.661,0.358-2.16,0.768-4.126,0.833c-5.589,0.185-8.583-3.198-8.735-7.804
              c-0.183-5.517,3.65-8.717,8.543-8.879c1.895-0.063,3.347,0.274,4.006,0.588l-0.648,2.927c-0.754-0.287-1.794-0.541-3.09-0.498
              c-2.902,0.096-5.099,1.921-4.98,5.52c0.107,3.238,2.094,5.214,5.356,5.106c1.104-0.037,2.319-0.317,3.029-0.629L494.56,155.542z"
              />
            <path fill="#FFFFFF" d="M500.849,151.851l-1.192,4.141l-3.792-0.036l5.099-16.13l4.8,0.046l4.862,16.225l-3.937-0.038
              l-1.208-4.164L500.849,151.851z M504.979,149.153l-0.976-3.442c-0.279-0.963-0.556-2.166-0.786-3.128l-0.049,0
              c-0.249,0.958-0.5,2.179-0.773,3.113l-0.993,3.423L504.979,149.153z"/>
            <path fill="#FFFFFF" d="M513.443,140.05l3.668,0.18l-0.642,13.088l6.424,0.315l-0.15,3.068l-10.092-0.495L513.443,140.05z"/>
            <path fill="#FFFFFF" d="M541.398,157.939c-0.702,0.267-2.243,0.475-4.201,0.278c-5.563-0.558-8.082-4.309-7.622-8.894
              c0.551-5.492,4.774-8.156,9.646-7.667c1.887,0.189,3.281,0.715,3.893,1.114l-1.03,2.815c-0.709-0.385-1.707-0.774-2.997-0.904
              c-2.89-0.29-5.309,1.228-5.668,4.81c-0.324,3.224,1.383,5.445,4.631,5.771c1.099,0.11,2.34-0.006,3.085-0.221L541.398,157.939z"/>
            <path fill="#FFFFFF" d="M554.053,153.251l-5.896-0.825l-0.532,3.804l6.585,0.921l-0.415,2.972l-10.223-1.43l2.242-16.023
              l9.889,1.384l-0.415,2.972l-6.253-0.875l-0.466,3.329l5.896,0.825L554.053,153.251z"/>
            <path fill="#FFFFFF" d="M562.45,145.194l-2.695,15.951l-3.621-0.612l2.695-15.951L562.45,145.194z"/>
            <path fill="#FFFFFF" d="M565.911,145.792l3.602,0.709l-2.532,12.855l6.31,1.243l-0.594,3.014l-9.912-1.953L565.911,145.792z"/>
            <path fill="#FFFFFF" d="M581.836,149.122l-3.569,15.776l-3.581-0.81l3.568-15.776L581.836,149.122z"/>
            <path fill="#FFFFFF" d="M581.22,165.531l4.093-15.653l4.134,1.081l1.751,6.586c0.498,1.892,0.917,4.085,1.152,6.033l0.069,0.018
              c0.309-2.127,0.791-4.259,1.404-6.605l1.197-4.575l3.251,0.85l-4.094,15.653l-3.715-0.972l-1.766-6.913
              c-0.492-1.915-0.985-4.203-1.271-6.238l-0.076,0.005c-0.447,2.091-0.979,4.31-1.646,6.864l-1.232,4.714L581.22,165.531z"/>
            <path fill="#FFFFFF" d="M610.155,173.074c-1.191,0.03-3.388-0.105-5.426-0.743c-2.817-0.882-4.634-2.23-5.623-4.049
              c-1.004-1.773-1.152-4.032-0.419-6.292c1.629-5.123,6.28-6.886,11.342-5.301c1.993,0.624,3.405,1.494,4.047,2.097l-1.608,2.565
              c-0.732-0.632-1.692-1.26-3.388-1.791c-2.908-0.911-5.624,0.05-6.671,3.394c-0.997,3.184,0.408,5.686,3.271,6.582
              c0.802,0.251,1.472,0.36,1.79,0.309l1.011-3.229l-2.382-0.746l0.854-2.726l5.749,1.8L610.155,173.074z"/>
          </g>
          <g>
            <path d="M237.503,365.259l4.955,2.522l2.123-4.17l3.279,1.669l-2.123,4.17l7.664,3.901c2.103,1.07,3.499,1.063,4.351-0.613
              c0.381-0.749,0.564-1.374,0.657-1.82l3.387,1.634c-0.077,0.769-0.479,2-1.187,3.39c-0.816,1.604-2.041,2.686-3.349,3.052
              c-1.47,0.419-3.256,0.048-5.537-1.113l-8.163-4.155l-1.27,2.495l-3.279-1.669l1.27-2.495l-3.921-1.996L237.503,365.259z"/>
            <path d="M239.054,354.972l2.543-4.259l9.961,5.947l0.041-0.069c-0.482-0.987-0.725-2.063-0.673-3.244
              c0.012-1.111,0.332-2.271,0.967-3.336c1.702-2.851,5.375-4.711,10.733-1.512l9.858,5.886l-2.522,4.225l-9.377-5.599
              c-2.438-1.456-4.792-1.65-6.187,0.686c-0.984,1.649-0.583,3.473,0.421,4.678c0.262,0.342,0.688,0.644,1.168,0.931l9.995,5.968
              l-2.543,4.259L239.054,354.972z"/>
            <path d="M270.316,342.763c2.939,1.94,5.776,0.571,7.583-1.973c1.32-1.859,2.009-3.381,2.41-4.912l3.188,1.382
              c-0.418,1.763-1.338,3.956-2.959,6.238c-3.66,5.152-8.977,5.938-13.835,2.486c-4.402-3.127-6.644-8.742-3.031-13.829
              c3.66-5.152,9.104-3.836,12.592-1.358c0.75,0.533,1.291,1.015,1.603,1.335L270.316,342.763z M272.469,333.792
              c-1.444-1.075-4.32-2.137-6.22,0.537c-1.76,2.478-0.251,5.12,1.263,6.441L272.469,333.792z"/>
            <path d="M288.489,325.045c1.248-0.401,3.18-1.531,4.333-2.861c1.415-1.632,1.378-2.934,0.411-3.772
              c-0.998-0.865-2.035-0.706-4.242,0.558c-3.486,2.007-6.106,1.854-7.803,0.33c-2.599-2.253-2.765-6.155,0.196-9.57
              c1.415-1.632,3.069-2.686,4.256-3.14l2.001,3.005c-0.913,0.32-2.418,1.08-3.545,2.38c-1.153,1.33-1.147,2.605-0.24,3.392
              c0.937,0.812,1.993,0.51,4.283-0.788c3.272-1.822,5.707-1.882,7.91-0.026c2.66,2.305,2.787,6.068-0.593,9.967
              c-1.546,1.783-3.357,3.019-4.905,3.583L288.489,325.045z"/>
            <path d="M310.479,307.815l-1.764-1.287l-0.084,0.085c0.241,1.921-0.42,4.133-2.188,5.929c-2.75,2.793-6.317,2.369-8.426,0.292
              c-3.506-3.451-2.215-8.298,2.865-13.4l-0.228-0.225c-0.912-0.898-2.788-2.015-5.201,0.437c-1.347,1.368-2.322,3.214-2.65,4.744
              l-2.954-1.561c0.383-1.644,1.552-4.029,3.713-6.224c4.377-4.446,8.462-2.951,11.483,0.023l5.017,4.939
              c1.254,1.234,2.536,2.384,3.531,3.083L310.479,307.815z M303.247,301.593c-2.499,2.424-4.286,5.323-2.177,7.399
              c1.368,1.347,2.865,1.081,3.959-0.031c1.375-1.396,1.473-3.321,0.784-4.617c-0.172-0.338-0.429-0.647-0.657-0.872L303.247,301.593
              z"/>
            <path d="M317.389,301.246l-10.566-11.747l-1.963,1.765l-2.461-2.736l1.963-1.766l-0.562-0.625
              c-1.686-1.874-2.865-4.202-2.686-6.515c0.161-1.974,1.271-3.671,2.52-4.795c0.981-0.883,1.888-1.43,2.67-1.756l2.273,3.066
              c-0.521,0.199-1.133,0.535-1.787,1.124c-1.814,1.631-1.075,3.71,0.53,5.494l0.669,0.744l3.182-2.862l2.46,2.736l-3.152,2.835
              l10.566,11.747L317.389,301.246z"/>
            <path d="M323.375,284.927c2.292,2.675,5.397,2.139,7.835,0.191c1.781-1.424,2.863-2.698,3.669-4.06l2.686,2.207
              c-0.888,1.58-2.375,3.435-4.563,5.184c-4.938,3.947-10.267,3.239-13.989-1.417c-3.372-4.219-3.982-10.236,0.893-14.133
              c4.938-3.946,9.81-1.183,12.482,2.161c0.575,0.719,0.962,1.331,1.174,1.725L323.375,284.927z M327.914,276.895
              c-1.093-1.432-3.566-3.244-6.129-1.196c-2.375,1.898-1.651,4.854-0.559,6.542L327.914,276.895z"/>
            <path d="M357.559,270.62l-1.436-1.645l-0.101,0.065c-0.189,1.927-1.322,3.938-3.443,5.299c-3.299,2.118-6.684,0.916-8.283-1.575
              c-2.657-4.14-0.328-8.582,5.752-12.438l-0.173-0.27c-0.691-1.077-2.274-2.58-5.169-0.722c-1.616,1.037-2.975,2.623-3.631,4.042
              l-2.537-2.174c0.736-1.519,2.403-3.586,4.995-5.25c5.251-3.371,8.905-1.01,11.195,2.558l3.803,5.924
              c0.951,1.481,1.947,2.885,2.764,3.787L357.559,270.62z M351.878,262.954c-2.972,1.813-5.355,4.245-3.756,6.736
              c1.037,1.616,2.556,1.687,3.869,0.844c1.649-1.059,2.169-2.914,1.784-4.33c-0.093-0.368-0.276-0.726-0.449-0.995L351.878,262.954z
              "/>
            <path d="M358.608,253.907c-1.058-1.975-1.981-3.613-2.88-5.038l3.808-2.04l1.591,2.46l0.106-0.057
              c0.022-1.736,1.022-4.36,3.879-5.89c2.997-1.605,7.139-1.328,10.048,4.102l5.459,10.191l-4.337,2.324l-5.195-9.697
              c-1.322-2.468-3.24-3.846-5.603-2.581c-1.728,0.926-2.266,2.802-2.025,4.353c0.066,0.463,0.314,1.011,0.579,1.505l5.403,10.085
              l-4.373,2.343L358.608,253.907z"/>
            <path d="M390.101,222.399l9.076,21.045c0.792,1.836,1.721,3.788,2.317,4.968l-4.041,1.742l-1.403-2.749l-0.074,0.032
              c-0.209,2.443-1.838,4.626-4.446,5.75c-4.26,1.837-9.208-0.342-11.583-5.851c-2.619-5.971-0.409-11.149,3.888-13.002
              c2.461-1.061,4.667-0.793,6.045,0.181l0.074-0.031l-4.372-10.137L390.101,222.399z M392.33,239.993
              c-0.158-0.367-0.385-0.792-0.617-1.127c-1.165-1.589-3.214-2.403-5.271-1.517c-2.902,1.251-3.409,4.519-1.968,7.861
              c1.41,3.269,4.055,4.959,6.92,3.724c1.836-0.792,2.914-2.737,2.515-4.874c-0.064-0.452-0.217-0.908-0.423-1.386L392.33,239.993z"
              />
            <path d="M411.409,218.666c0.445,1.369-0.172,2.832-1.846,3.376c-1.56,0.508-2.92-0.312-3.365-1.681
              c-0.47-1.446,0.198-2.883,1.795-3.403C409.591,216.438,410.939,217.22,411.409,218.666z M410.855,249.806
              c1.484-0.609,2.565-1.424,3.066-2.47c0.565-1.235,0.438-2.792-0.874-6.825l-4.9-15.064l4.679-1.522l5.334,16.396
              c1.25,3.842,1.307,6.474,0.177,8.692c-1.141,2.054-3.517,3.542-5.837,4.297L410.855,249.806z"/>
            <path d="M438.854,230.9c0.582,2.246,1.14,4.085,1.619,5.614l-4.183,1.083l-0.974-2.806l-0.077,0.02
              c-0.462,1.566-1.82,3.983-5.189,4.855c-3.447,0.892-7.115-0.348-8.709-6.506l-2.867-11.076l4.764-1.233l2.657,10.263
              c0.812,3.137,2.34,4.89,4.857,4.238c1.897-0.491,2.786-2.167,2.979-3.498c0.044-0.466,0.03-1-0.11-1.542l-2.947-11.386
              l4.763-1.233L438.854,230.9z"/>
            <path d="M444.035,231.21c1.225,0.466,3.439,0.792,5.17,0.475c2.124-0.39,2.91-1.429,2.679-2.688
              c-0.238-1.298-1.147-1.822-3.66-2.215c-3.975-0.613-5.924-2.37-6.296-4.619c-0.621-3.383,1.688-6.531,6.134-7.347
              c2.124-0.39,4.074-0.178,5.284,0.209l-0.316,3.596c-0.913-0.32-2.563-0.668-4.254-0.358c-1.731,0.317-2.523,1.317-2.307,2.497
              c0.224,1.219,1.237,1.644,3.834,2.061c3.693,0.624,5.63,2.098,6.189,4.923c0.635,3.461-1.617,6.477-6.692,7.408
              c-2.321,0.426-4.507,0.258-6.067-0.27L444.035,231.21z"/>
            <path d="M463.589,207.284l0.7,5.517l4.644-0.589l0.463,3.651l-4.644,0.589l1.083,8.533c0.297,2.341,1.088,3.491,2.954,3.254
              c0.833-0.105,1.453-0.305,1.875-0.479l0.553,3.72c-0.679,0.369-1.924,0.728-3.472,0.925c-1.786,0.227-3.37-0.178-4.408-1.054
              c-1.173-0.98-1.871-2.666-2.193-5.206l-1.153-9.088l-2.778,0.353l-0.463-3.651l2.778-0.353l-0.554-4.366L463.589,207.284z"/>
            <path d="M482.38,226.086c1.147,0.633,3.295,1.264,5.053,1.191c2.158-0.09,3.081-1.009,3.027-2.288
              c-0.055-1.319-0.882-1.965-3.315-2.705c-3.852-1.161-5.536-3.172-5.591-5.452c-0.143-3.437,2.583-6.232,7.099-6.42
              c2.158-0.09,4.06,0.392,5.204,0.944l-0.814,3.517c-0.859-0.444-2.444-1.019-4.163-0.947c-1.758,0.073-2.683,0.952-2.633,2.151
              c0.052,1.239,0.996,1.8,3.51,2.576c3.57,1.132,5.284,2.863,5.443,5.738c0.146,3.517-2.505,6.189-7.66,6.404
              c-2.357,0.098-4.499-0.374-5.971-1.113L482.38,226.086z"/>
            <path d="M499.379,216.977c0.074-2.559,0.054-4.64,0.028-6.481l4.317,0.125l0.153,3.005l0.08,0.002
              c1.503-2.157,3.817-3.33,6.775-3.244c4.478,0.13,8.085,4.076,7.908,10.152c-0.204,7.036-4.74,10.306-9.178,10.177
              c-2.438-0.071-4.447-1.169-5.401-2.758l-0.08-0.002l-0.292,10.074l-4.918-0.143L499.379,216.977z M504.156,221.957
              c-0.014,0.48,0.014,0.921,0.12,1.364c0.422,2.013,2.14,3.503,4.218,3.563c3.119,0.091,5.033-2.455,5.145-6.292
              c0.1-3.438-1.498-6.285-4.656-6.377c-2.039-0.059-3.961,1.366-4.504,3.51c-0.131,0.396-0.224,0.834-0.235,1.234L504.156,221.957z"
              />
            <path d="M532.763,232.05l-0.087-2.182l-0.119-0.013c-1.355,1.383-3.499,2.239-6.004,1.97c-3.897-0.419-5.781-3.477-5.465-6.42
              c0.525-4.891,5.125-6.891,12.278-6.082l0.034-0.318c0.137-1.272-0.154-3.436-3.573-3.803c-1.909-0.205-3.961,0.178-5.363,0.872
              l-0.612-3.284c1.525-0.721,4.12-1.287,7.182-0.958c6.204,0.667,7.57,4.795,7.117,9.011l-0.752,6.999
              c-0.188,1.75-0.292,3.468-0.222,4.682L532.763,232.05z M533.144,222.518c-3.451-0.451-6.832-0.05-7.148,2.892
              c-0.205,1.909,0.934,2.916,2.484,3.083c1.948,0.209,3.517-0.909,4.104-2.254c0.158-0.345,0.24-0.738,0.274-1.057L533.144,222.518z
              "/>
            <path d="M555.82,235.125c-1.108,0.291-3.208,0.445-5.61,0.018c-5.986-1.064-9.206-5.578-8.148-11.525
              c1.022-5.75,5.76-9.539,12.494-8.342c1.772,0.315,3.514,1.031,4.525,1.739l-1.511,3.469c-0.725-0.495-1.797-1.091-3.529-1.399
              c-3.702-0.659-6.391,1.667-6.975,5.179c-0.7,3.938,1.46,6.639,4.769,7.227c1.693,0.301,2.938,0.157,3.953-0.069L555.82,235.125z"
              />
            <path d="M563.89,229.055c-0.737,3.444,1.571,5.588,4.598,6.345c2.212,0.553,3.881,0.641,5.452,0.457l-0.126,3.473
              c-1.795,0.252-4.172,0.193-6.888-0.486c-6.131-1.533-8.799-6.2-7.353-11.981c1.311-5.238,5.725-9.371,11.777-7.857
              c6.131,1.534,6.888,7.083,5.849,11.234c-0.223,0.893-0.476,1.572-0.659,1.979L563.89,229.055z M573.028,227.795
              c0.476-1.736,0.418-4.802-2.765-5.598c-2.948-0.738-4.86,1.629-5.539,3.521L573.028,227.795z"/>
            <path d="M586.279,244.113l5.213-14.917L589,228.325l1.215-3.475l2.492,0.871l0.277-0.793c0.831-2.379,2.305-4.534,4.419-5.49
              c1.809-0.809,3.83-0.653,5.416-0.099c1.247,0.436,2.163,0.968,2.825,1.496l-1.594,3.469c-0.425-0.36-1.014-0.736-1.845-1.026
              c-2.304-0.805-3.77,0.844-4.562,3.109l-0.33,0.944l4.041,1.412l-1.215,3.475l-4.003-1.399l-5.214,14.917L586.279,244.113z"/>
            <path d="M617.901,245.898c-2.783,6.596-8.67,7.585-13.239,5.657c-5.086-2.146-7.515-7.295-5.089-13.043
              c2.487-5.896,7.881-7.875,13.298-5.589C618.214,235.178,620.203,240.445,617.901,245.898z M604.3,240.376
              c-1.462,3.464-0.87,6.795,1.894,7.961c2.579,1.088,5.458-0.692,6.981-4.304c1.182-2.8,1.281-6.535-1.813-7.841
              C608.155,234.84,605.56,237.392,604.3,240.376z"/>
            <path d="M623.167,244.92c1.188-2.357,1.997-4.054,2.682-5.68l3.822,1.925l-1.53,3.394l0.144,0.072
              c2.099-2.033,4.764-2.258,6.621-1.322c0.429,0.216,0.661,0.377,0.982,0.629l-2.087,4.144c-0.321-0.251-0.679-0.521-1.214-0.791
              c-2.107-1.062-4.221-0.424-5.603,1.343c-0.27,0.357-0.575,0.785-0.826,1.285l-4.534,9.001l-4.394-2.212L623.167,244.92z"/>
            <path d="M651.798,242.942l4.19,2.655l-6.208,9.801l0.067,0.043c1-0.456,2.082-0.67,3.261-0.587
              c1.111,0.041,2.263,0.391,3.31,1.055c2.806,1.777,4.568,5.499,1.229,10.771l-6.145,9.7l-4.157-2.633l5.845-9.227
              c1.52-2.399,1.775-4.748-0.522-6.203c-1.623-1.028-3.457-0.674-4.688,0.298c-0.349,0.252-0.661,0.67-0.961,1.144l-6.229,9.835
              l-4.191-2.654L651.798,242.942z"/>
            <path d="M672.781,284.914c-1.399,1.851-2.493,3.43-3.395,4.755l-3.446-2.605l1.594-2.506l-0.063-0.048
              c-1.515,0.61-4.252,1.047-7.028-1.051c-2.839-2.147-4.145-5.792-0.31-10.865l6.899-9.125l3.925,2.967l-6.393,8.455
              c-1.954,2.584-2.379,4.871-0.306,6.438c1.563,1.182,3.429,0.837,4.592,0.162c0.393-0.254,0.802-0.597,1.14-1.043l7.093-9.38
              l3.925,2.967L672.781,284.914z"/>
            <path d="M682.338,282.344c1.524-1.641,2.774-3.044,3.83-4.356l3.105,2.885l-1.813,2.246l0.088,0.082
              c1.737-0.46,4.252-0.581,6.654,1.652c1.875,1.742,2.359,4.158,1.505,6.312l0.059,0.054c1.233-0.328,2.363-0.37,3.418-0.154
              c1.309,0.179,2.489,0.729,3.661,1.818c2.373,2.205,3.278,6.048-0.969,10.619l-7.813,8.408l-3.516-3.267l7.322-7.881
              c2.205-2.373,2.691-4.542,0.963-6.147c-1.23-1.144-2.955-1.108-4.289-0.437c-0.414,0.27-0.91,0.628-1.292,1.038l-7.948,8.555
              l-3.516-3.267l7.677-8.262c1.851-1.992,2.366-4.134,0.696-5.685c-1.348-1.252-3.235-1.042-4.483-0.345
              c-0.473,0.215-0.913,0.571-1.294,0.981l-7.84,8.438l-3.516-3.267L682.338,282.344z"/>
            <path d="M704.3,323.593l1.402-1.674l-0.079-0.09c-1.934,0.112-4.096-0.696-5.77-2.58c-2.604-2.931-1.941-6.462,0.271-8.428
              c3.679-3.268,8.429-1.655,13.181,3.755l0.239-0.212c0.957-0.85,2.196-2.647-0.088-5.219c-1.275-1.436-3.052-2.533-4.557-2.962
              l1.755-2.843c1.614,0.492,3.916,1.818,5.962,4.121c4.144,4.666,2.379,8.642-0.792,11.458l-5.264,4.675
              c-1.315,1.169-2.549,2.371-3.313,3.318L704.3,323.593z M710.993,316.792c-2.252-2.655-5.025-4.632-7.238-2.667
              c-1.436,1.275-1.27,2.787-0.233,3.953c1.302,1.465,3.215,1.692,4.554,1.091c0.349-0.149,0.675-0.385,0.914-0.597L710.993,316.792z
              "/>
            <path d="M720.993,322.05c1.784-1.354,3.259-2.521,4.524-3.632l2.61,3.442l-2.182,1.956l0.072,0.096
              c1.72-0.249,4.467,0.329,6.425,2.911c2.054,2.709,2.428,6.844-2.481,10.566l-9.212,6.985l-2.973-3.921l8.766-6.646
              c2.231-1.692,3.292-3.802,1.673-5.938c-1.185-1.562-3.121-1.8-4.615-1.32c-0.447,0.138-0.949,0.469-1.396,0.807l-9.117,6.913
              l-2.996-3.952L720.993,322.05z"/>
            <path d="M723.4,348.416l16.199-10.819l2.755,4.125l-16.2,10.82L723.4,348.416z M747.034,338.933
              c-1.231,0.822-2.796,0.568-3.751-0.862c-0.911-1.364-0.546-2.906,0.685-3.728c1.265-0.844,2.851-0.557,3.762,0.807
              C748.663,336.546,748.275,338.055,747.034,338.933z"/>
            <path d="M753.215,348.576l-4.755,2.882l2.426,4.002l-3.147,1.908l-2.426-4.002l-7.355,4.458c-2.019,1.223-2.747,2.414-1.772,4.021
              c0.436,0.718,0.87,1.204,1.201,1.518l-3.175,2.018c-0.612-0.471-1.447-1.461-2.256-2.796c-0.934-1.54-1.208-3.15-0.83-4.456
              c0.419-1.47,1.676-2.793,3.865-4.12l7.834-4.749l-1.452-2.395l3.147-1.908l1.452,2.395l3.763-2.281L753.215,348.576z"/>
            <path d="M754.713,362.37l-7.621,7.954c-0.878,0.91-2.005,1.95-2.867,2.717l0.056,0.106c1.141-0.235,2.583-0.583,3.884-0.856
              l10.624-2.123l2.445,4.679l-14.028,1.826c-7.692,0.997-11.396,0.902-14.243-0.228c-2.414-0.905-3.907-2.381-4.623-3.406
              l3.168-2.919c0.566,0.652,1.438,1.37,2.539,1.922c0.957,0.537,2.485,0.957,3.97,0.948c0.412,0.011,0.716-0.013,0.929-0.124
              c0.177-0.093,0.406-0.258,0.721-0.693l12.547-14.59L754.713,362.37z"/>
          </g>
          <g>
            <path d="M217.811,562.231c-0.318,0.979-0.487,2.729-0.17,4.075c0.389,1.65,1.234,2.227,2.213,1.996
              c1.009-0.238,1.385-0.972,1.595-2.958c0.325-3.143,1.627-4.74,3.377-5.12c2.63-0.619,5.19,1.069,6.004,4.524
              c0.389,1.651,0.299,3.189,0.042,4.153l-2.833-0.107c0.216-0.729,0.424-2.036,0.114-3.351c-0.316-1.346-1.132-1.929-2.049-1.712
              c-0.948,0.223-1.241,1.034-1.467,3.089c-0.345,2.921-1.425,4.499-3.62,5.048c-2.691,0.634-5.145-1.015-6.074-4.96
              c-0.425-1.804-0.379-3.525-0.026-4.771L217.811,562.231z"/>
            <path d="M225.914,588.514c-1.748,0.517-3.177,1.004-4.364,1.42l-0.961-3.255l2.177-0.839l-0.018-0.061
              c-1.241-0.321-3.175-1.323-3.949-3.945c-0.792-2.682,0.085-5.595,4.876-7.009l8.619-2.544l1.094,3.706l-7.986,2.357
              c-2.441,0.721-3.776,1.967-3.198,3.926c0.436,1.477,1.775,2.13,2.825,2.246c0.367,0.022,0.786-0.003,1.208-0.127l8.86-2.616
              l1.094,3.707L225.914,588.514z"/>
            <path d="M226.386,593.102c-0.207,1.009-0.179,2.767,0.287,4.068c0.571,1.598,1.476,2.075,2.422,1.737
              c0.977-0.35,1.268-1.121,1.255-3.118c-0.028-3.159,1.087-4.893,2.784-5.466c2.544-0.909,5.277,0.482,6.473,3.826
              c0.571,1.598,0.654,3.136,0.506,4.123l-2.828,0.21c0.133-0.748,0.194-2.071-0.261-3.344c-0.465-1.302-1.34-1.79-2.228-1.473
              c-0.917,0.328-1.118,1.167-1.113,3.234c-0.016,2.941-0.914,4.631-3.034,5.422c-2.604,0.931-5.227-0.435-6.591-4.251
              c-0.624-1.746-0.77-3.462-0.559-4.738L226.386,593.102z"/>
            <path d="M247.715,603.983l-4.045,1.647l1.387,3.405l-2.677,1.091l-1.387-3.405l-6.256,2.55c-1.717,0.699-2.429,1.532-1.872,2.9
              c0.249,0.61,0.526,1.041,0.744,1.325l-2.711,1.173c-0.417-0.441-0.942-1.313-1.404-2.448c-0.534-1.309-0.547-2.593-0.093-3.559
              c0.506-1.088,1.645-1.959,3.507-2.718l6.664-2.715l-0.83-2.037l2.677-1.091l0.83,2.037l3.201-1.305L247.715,603.983z"/>
            <path d="M235.924,625.887l1.431-0.944l-0.04-0.086c-1.48-0.35-2.926-1.443-3.763-3.237c-1.301-2.79-0.009-5.299,2.098-6.281
              c3.502-1.633,6.714,0.648,9.076,5.786l0.228-0.105c0.911-0.425,2.248-1.499,1.106-3.947c-0.637-1.367-1.728-2.592-2.764-3.252
              l1.959-1.746c1.104,0.733,2.539,2.248,3.561,4.44c2.071,4.442-0.15,7.037-3.168,8.444l-5.011,2.337
              c-1.253,0.584-2.451,1.212-3.238,1.752L235.924,625.887z M242.488,622.271c-1.098-2.503-2.741-4.614-4.848-3.632
              c-1.367,0.637-1.582,1.812-1.064,2.922c0.65,1.396,2.04,1.996,3.182,1.845c0.296-0.034,0.594-0.139,0.822-0.245L242.488,622.271z"
              />
            <path d="M239.447,633l13.577-7.06l1.797,3.457l-13.576,7.06L239.447,633z M258.714,627.621c-1.032,0.536-2.231,0.204-2.854-0.995
              c-0.595-1.143-0.177-2.315,0.854-2.852c1.06-0.551,2.273-0.191,2.868,0.952C260.191,625.897,259.759,627.042,258.714,627.621z"/>
            <path d="M252.724,635.271c1.521-0.885,2.782-1.654,3.875-2.398l1.706,2.933l-1.888,1.316l0.047,0.081
              c1.364-0.029,3.45,0.684,4.729,2.884c1.342,2.309,1.238,5.567-2.944,7.999l-7.85,4.564l-1.942-3.341l7.469-4.343
              c1.901-1.105,2.93-2.649,1.872-4.469c-0.774-1.331-2.262-1.701-3.472-1.471c-0.362,0.065-0.785,0.275-1.166,0.496l-7.768,4.517
              l-1.958-3.367L252.724,635.271z"/>
            <path d="M257.812,663.979l1.272-1.149l-0.052-0.079c-1.516-0.122-3.11-0.984-4.208-2.631c-1.708-2.562-0.81-5.236,1.124-6.526
              c3.215-2.143,6.735-0.374,9.846,4.348l0.209-0.139c0.836-0.558,1.995-1.821,0.497-4.069c-0.836-1.255-2.1-2.3-3.224-2.797
              l1.673-2.021c1.202,0.558,2.849,1.839,4.191,3.852c2.718,4.077,0.915,6.979-1.856,8.826l-4.601,3.066
              c-1.15,0.767-2.239,1.568-2.936,2.222L257.812,663.979z M263.753,659.413c-1.464-2.309-3.407-4.147-5.341-2.857
              c-1.255,0.836-1.29,2.03-0.61,3.05c0.854,1.28,2.317,1.664,3.424,1.342c0.288-0.079,0.566-0.227,0.776-0.366L263.753,659.413z"/>
            <path d="M262.356,670.602c0.864-0.577,2.253-1.553,3.504-2.503l14.339-10.885l2.337,3.077l-7.257,5.51l0.038,0.05
              c1.746-0.142,3.529,0.596,4.916,2.422c2.261,2.979,1.398,6.986-2.325,9.772c-4.379,3.324-8.665,2.199-10.755-0.553
              c-1.197-1.577-1.66-3.435-0.823-5.45l-0.038-0.05l-1.941,1.236L262.356,670.602z M269.799,669.961
              c-0.25,0.19-0.482,0.405-0.669,0.626c-0.979,1.256-1.088,3.035-0.082,4.361c1.463,1.927,3.907,1.926,6.36,0.063
              c2.152-1.634,3.125-3.989,1.567-6.041c-0.95-1.252-2.706-1.693-4.323-1.019c-0.263,0.121-0.577,0.28-0.877,0.508L269.799,669.961z
              "/>
            <path d="M291.227,671.393l2.499,2.987l-17.106,14.312l-2.499-2.988L291.227,671.393z"/>
            <path d="M286.356,689.907c-1.979,1.932-1.401,4.337,0.25,6.147c1.207,1.323,2.26,2.105,3.368,2.668l-1.592,2.217
              c-1.283-0.615-2.813-1.687-4.294-3.311c-3.344-3.667-3.061-7.879,0.397-11.032c3.133-2.856,7.816-3.642,11.118-0.021
              c3.344,3.667,1.428,7.626-1.056,9.89c-0.534,0.487-0.994,0.821-1.292,1.008L286.356,689.907z M292.881,693.054
              c1.065-0.93,2.36-2.96,0.624-4.862c-1.608-1.765-3.887-1.047-5.153-0.104L292.881,693.054z"/>
            <path d="M302.449,706.581c-1.821,2.083-1.054,4.436,0.735,6.11c1.307,1.224,2.419,1.921,3.568,2.394l-1.412,2.336
              c-1.328-0.512-2.938-1.459-4.543-2.962c-3.624-3.392-3.674-7.613-0.475-11.03c2.898-3.096,7.505-4.248,11.083-0.898
              c3.624,3.392,2.025,7.489-0.272,9.943c-0.494,0.527-0.926,0.897-1.208,1.106L302.449,706.581z M309.203,709.203
              c0.989-1.01,2.119-3.137,0.239-4.897c-1.743-1.632-3.958-0.736-5.146,0.304L309.203,709.203z"/>
            <path d="M317.329,727.823c-0.868-0.237-2.357-0.956-3.824-2.189c-3.655-3.073-4.075-7.407-1.022-11.038
              c2.952-3.511,7.662-4.23,11.774-0.773c1.082,0.91,1.986,2.08,2.396,2.959l-2.389,1.768c-0.299-0.621-0.794-1.447-1.852-2.337
              c-2.261-1.9-5.002-1.374-6.778,0.787c-2.021,2.404-1.611,5.09,0.409,6.788c1.034,0.869,1.938,1.259,2.717,1.504L317.329,727.823z"
              />
            <path d="M335.926,732.754c-3.373,4.498-8.047,4.135-11.164,1.798c-3.468-2.601-4.342-6.985-1.402-10.906
              c3.015-4.021,7.5-4.505,11.195-1.735C338.2,724.644,338.715,729.034,335.926,732.754z M326.609,725.964
              c-1.771,2.362-1.953,5.013-0.068,6.427c1.759,1.319,4.291,0.508,6.138-1.955c1.432-1.909,2.217-4.736,0.106-6.319
              C330.598,722.477,328.135,723.928,326.609,725.964z"/>
            <path d="M340.426,732.267c0.964-1.473,1.747-2.726,2.391-3.881l2.839,1.858l-1.099,2.022l0.079,0.052
              c1.206-0.638,3.391-0.935,5.521,0.46c2.235,1.463,3.602,4.423,0.951,8.472l-4.974,7.599l-3.234-2.117l4.733-7.23
              c1.205-1.84,1.434-3.681-0.328-4.834c-1.288-0.844-2.785-0.509-3.763,0.24c-0.294,0.221-0.579,0.598-0.82,0.966l-4.923,7.52
              l-3.26-2.135L340.426,732.267z"/>
            <path d="M366.869,751.806c-2.77,4.895-7.453,5.133-10.843,3.214c-3.773-2.135-5.202-6.372-2.788-10.638
              c2.476-4.375,6.863-5.431,10.882-3.156C368.085,743.469,369.159,747.759,366.869,751.806z M356.757,746.265
              c-1.455,2.57-1.295,5.224,0.756,6.384c1.914,1.083,4.321-0.046,5.837-2.726c1.176-2.078,1.592-4.983-0.705-6.282
              C360.267,742.294,358.01,744.05,356.757,746.265z"/>
            <path d="M371.264,750.914c0.741-1.595,1.334-2.947,1.804-4.184l3.02,1.402l-0.81,2.117l0.085,0.04
              c1.187-0.765,3.036-1.465,5.372-0.38c1.823,0.847,2.771,2.533,2.655,4.35l0.057,0.026c0.842-0.544,1.676-0.85,2.517-0.944
              c1.021-0.184,2.036-0.059,3.175,0.471c2.308,1.071,3.916,3.724,1.852,8.167l-3.796,8.177l-3.419-1.588l3.559-7.663
              c1.072-2.308,0.909-4.046-0.771-4.826c-1.197-0.556-2.477-0.111-3.31,0.714c-0.244,0.303-0.528,0.69-0.713,1.089l-3.863,8.318
              l-3.419-1.587l3.731-8.034c0.899-1.938,0.766-3.662-0.858-4.416c-1.311-0.608-2.67,0.007-3.433,0.83
              c-0.301,0.276-0.543,0.648-0.729,1.047l-3.81,8.205l-3.418-1.588L371.264,750.914z"/>
            <path d="M391.826,771.812l5.558-14.252l3.629,1.415l-5.559,14.253L391.826,771.812z M402.77,755.074
              c-0.422,1.083-1.543,1.623-2.802,1.133c-1.2-0.468-1.659-1.625-1.237-2.708c0.434-1.112,1.584-1.642,2.784-1.173
              C402.745,752.805,403.175,753.95,402.77,755.074z"/>
            <path d="M410.62,778.111c-0.895,0.091-2.543-0.044-4.355-0.668c-4.516-1.554-6.465-5.447-4.921-9.934
              c1.493-4.338,5.629-6.702,10.709-4.954c1.337,0.46,2.601,1.228,3.3,1.9l-1.594,2.508c-0.502-0.472-1.261-1.065-2.569-1.515
              c-2.792-0.961-5.162,0.517-6.042,3.171c-1.022,2.971,0.326,5.328,2.821,6.188c1.277,0.439,2.261,0.479,3.076,0.427L410.62,778.111
              z"/>
            <path d="M439.633,763.448l-4.441,17.442c-0.388,1.522-0.745,3.182-0.94,4.202l-3.348-0.854l0.444-2.382l-0.061-0.016
              c-1.302,1.419-3.361,1.996-5.522,1.446c-3.531-0.899-5.564-4.626-4.402-9.192c1.233-4.97,5.09-7.132,8.652-6.225
              c2.04,0.519,3.284,1.743,3.674,3.01l0.061,0.016l2.14-8.402L439.633,763.448z M432.586,775.463
              c0.078-0.305,0.14-0.678,0.157-0.998c0.037-1.546-0.848-3.035-2.552-3.47c-2.405-0.612-4.287,1.178-4.992,3.947
              c-0.69,2.71,0.146,5.029,2.52,5.634c1.522,0.388,3.125-0.306,3.901-1.826c0.176-0.312,0.3-0.67,0.4-1.065L432.586,775.463z"/>
            <path d="M442.889,780.165c-0.423,2.733,1.484,4.311,3.892,4.768c1.76,0.334,3.072,0.328,4.296,0.112l0.058,2.729
              c-1.396,0.278-3.263,0.34-5.423-0.07c-4.877-0.926-7.18-4.465-6.307-9.064c0.791-4.167,4.066-7.606,8.882-6.692
              c4.877,0.926,5.721,5.243,5.094,8.546c-0.135,0.71-0.302,1.254-0.428,1.582L442.889,780.165z M449.999,778.764
              c0.294-1.383,0.111-3.784-2.42-4.265c-2.346-0.445-3.738,1.497-4.186,3.011L449.999,778.764z"/>
            <path d="M460,774.255l1.456,8.019c0.261,1.396,0.414,2.652,0.531,3.936l0.093,0.013c0.443-1.21,0.941-2.35,1.525-3.667
              l3.405-7.383l4.051,0.529l-7.78,14.417l-3.77-0.493l-3.656-15.911L460,774.255z"/>
            <path d="M474.972,784.761c-0.111,2.764,1.961,4.115,4.405,4.298c1.786,0.133,3.089-0.021,4.28-0.374l0.366,2.705
              c-1.355,0.434-3.203,0.706-5.396,0.542c-4.95-0.368-7.637-3.624-7.289-8.292c0.315-4.229,3.18-8.016,8.067-7.651
              c4.949,0.368,6.275,4.563,6.025,7.914c-0.054,0.721-0.158,1.28-0.247,1.62L474.972,784.761z M481.877,782.566
              c0.136-1.407-0.317-3.771-2.886-3.963c-2.381-0.178-3.544,1.909-3.818,3.464L481.877,782.566z"/>
            <path d="M489.196,769.872l3.894,0.125l-0.715,22.296l-3.894-0.125L489.196,769.872z"/>
            <path d="M511.574,784.462c0.073,5.624-3.853,8.189-7.748,8.24c-4.335,0.057-7.705-2.884-7.769-7.785
              c-0.066-5.027,3.192-8.149,7.811-8.21C508.424,776.647,511.513,779.813,511.574,784.462z M500.046,784.771
              c0.039,2.953,1.513,5.165,3.869,5.134c2.199-0.028,3.711-2.217,3.67-5.296c-0.031-2.388-1.135-5.106-3.774-5.072
              C501.077,779.572,500.012,782.226,500.046,784.771z"/>
            <path d="M514.891,781.877c-0.153-2.005-0.341-3.629-0.514-5.065l3.384-0.259l0.367,2.335l0.063-0.005
              c0.996-1.809,2.708-2.917,5.025-3.094c3.509-0.269,6.652,2.516,7.017,7.277c0.421,5.514-2.853,8.442-6.329,8.708
              c-1.911,0.146-3.571-0.546-4.448-1.708l-0.063,0.005l0.604,7.895l-3.853,0.295L514.891,781.877z M519.033,785.372
              c0.029,0.376,0.087,0.719,0.207,1.056c0.496,1.538,1.96,2.561,3.589,2.436c2.444-0.187,3.729-2.333,3.499-5.341
              c-0.206-2.693-1.689-4.786-4.164-4.597c-1.598,0.122-2.981,1.394-3.228,3.114c-0.07,0.32-0.106,0.67-0.083,0.983L519.033,785.372z
              "/>
            <path d="M533.394,779.917c-0.274-1.738-0.535-3.192-0.834-4.48l3.29-0.52l0.508,2.21l0.093-0.015
              c0.559-1.297,1.704-2.909,4.249-3.311c1.986-0.313,3.715,0.559,4.631,2.132l0.062-0.01c0.396-0.921,0.919-1.64,1.563-2.188
              c0.745-0.722,1.658-1.184,2.899-1.38c2.515-0.396,5.329,0.909,6.093,5.751l1.406,8.907l-3.725,0.588l-1.317-8.35
              c-0.397-2.514-1.501-3.866-3.332-3.577c-1.304,0.206-2.119,1.289-2.352,2.438c-0.034,0.388-0.054,0.867,0.015,1.302l1.431,9.063
              l-3.725,0.588l-1.381-8.752c-0.333-2.11-1.406-3.468-3.176-3.188c-1.428,0.225-2.213,1.494-2.388,2.604
              c-0.097,0.396-0.09,0.841-0.021,1.275l1.41,8.938l-3.724,0.588L533.394,779.917z"/>
            <path d="M563.457,778.812c0.73,2.668,3.115,3.328,5.5,2.762c1.742-0.414,2.937-0.956,3.966-1.653l1.168,2.468
              c-1.16,0.824-2.839,1.643-4.979,2.151c-4.829,1.147-8.376-1.142-9.458-5.695c-0.98-4.126,0.604-8.604,5.371-9.736
              c4.829-1.147,7.362,2.447,8.14,5.718c0.167,0.703,0.237,1.268,0.256,1.618L563.457,778.812z M569.373,774.629
              c-0.297-1.383-1.445-3.499-3.951-2.903c-2.323,0.552-2.8,2.893-2.59,4.457L569.373,774.629z"/>
            <path d="M575.704,771.495c-0.512-1.684-0.972-3.087-1.446-4.322l3.247-0.987l0.848,2.14l0.09-0.027
              c0.284-1.334,1.457-3.201,3.892-3.941c2.555-0.777,5.703,0.072,7.11,4.701l2.642,8.688l-3.697,1.124l-2.514-8.267
              c-0.64-2.104-1.905-3.46-3.92-2.848c-1.473,0.448-2.175,1.812-2.228,3.042c-0.02,0.367,0.088,0.827,0.216,1.248l2.613,8.598
              l-3.727,1.133L575.704,771.495z"/>
            <path d="M593.94,756.189l1.487,4.106l3.456-1.252l0.984,2.718l-3.457,1.252l2.3,6.352c0.631,1.742,1.436,2.487,2.824,1.984
              c0.62-0.225,1.061-0.484,1.354-0.69l1.064,2.755c-0.457,0.399-1.349,0.89-2.501,1.307c-1.329,0.481-2.612,0.445-3.559-0.048
              c-1.068-0.549-1.893-1.72-2.577-3.61l-2.45-6.766l-2.067,0.749l-0.984-2.718l2.068-0.749l-1.177-3.249L593.94,756.189z"/>
            <path d="M609.62,754.753l4.372,5.629c1,1.338,2.013,2.704,2.969,4.098l0.058-0.026c-0.414-1.644-0.671-3.396-0.932-5.006
              l-1.134-7.163l2.853-1.319l4.59,5.354c1.181,1.428,2.251,2.768,3.264,4.134l0.058-0.026c-0.472-1.617-0.857-3.273-1.239-5.07
              l-1.311-6.873l3.45-1.597l2.064,15.907l-3.251,1.505l-4.268-4.881c-1.101-1.257-2.021-2.423-3.114-3.961l-0.057,0.026
              c0.507,1.844,0.77,3.384,1.004,4.938l0.845,6.465l-3.279,1.519l-10.534-11.988L609.62,754.753z"/>
            <path d="M630.238,739.876c0.548,1.024,0.229,2.229-0.962,2.865c-1.136,0.607-2.314,0.204-2.862-0.821
              c-0.563-1.053-0.217-2.271,0.919-2.879C628.497,738.419,629.647,738.837,630.238,739.876z M635.839,759.08l-7.216-13.495
              l3.437-1.837l7.215,13.494L635.839,759.08z"/>
            <path d="M637.259,735.808l2.189,3.777l3.179-1.843l1.449,2.5l-3.18,1.843l3.386,5.843c0.929,1.604,1.852,2.194,3.129,1.454
              c0.571-0.331,0.959-0.664,1.21-0.919l1.535,2.522c-0.379,0.474-1.17,1.114-2.229,1.728c-1.224,0.709-2.492,0.899-3.511,0.583
              c-1.147-0.352-2.167-1.358-3.175-3.098l-3.605-6.224l-1.902,1.103l-1.449-2.5l1.902-1.103l-1.732-2.989L637.259,735.808z"/>
            <path d="M641.221,730.45l3.264-2.127l4.974,7.634l0.053-0.034c0-0.863,0.2-1.706,0.645-2.521c0.392-0.78,1.019-1.488,1.834-2.02
              c2.185-1.424,5.418-1.468,8.094,2.638l4.922,7.555l-3.237,2.109l-4.683-7.186c-1.218-1.869-2.811-2.818-4.601-1.652
              c-1.264,0.823-1.609,2.249-1.317,3.446c0.066,0.331,0.264,0.69,0.503,1.06l4.991,7.659l-3.264,2.127L641.221,730.45z"/>
            <path d="M672.16,732.035c1.022-0.114,2.687-0.683,3.782-1.526c1.344-1.036,1.519-2.044,0.905-2.84
              c-0.633-0.821-1.457-0.861-3.353-0.233c-2.998,1.001-4.99,0.475-6.059-0.963c-1.649-2.141-1.168-5.17,1.645-7.337
              c1.344-1.036,2.782-1.589,3.767-1.753l1.071,2.625c-0.753,0.104-2.03,0.454-3.101,1.279c-1.095,0.844-1.289,1.827-0.714,2.573
              c0.595,0.771,1.455,0.704,3.42,0.063c2.804-0.892,4.687-0.559,6.093,1.214c1.688,2.19,1.198,5.106-2.012,7.581
              c-1.469,1.132-3.057,1.8-4.336,1.992L672.16,732.035z"/>
            <path d="M691.304,710.852c3.671,4.261,2.313,8.749-0.639,11.292c-3.285,2.83-7.757,2.743-10.956-0.971
              c-3.281-3.809-2.791-8.295,0.708-11.31C683.868,706.89,688.269,707.328,691.304,710.852z M682.671,718.497
              c1.928,2.237,4.479,2.984,6.264,1.446c1.666-1.436,1.418-4.083-0.592-6.416c-1.559-1.809-4.152-3.183-6.151-1.46
              C680.12,713.852,681.01,716.568,682.671,718.497z"/>
            <path d="M706.995,705.918c-0.326,0.838-1.196,2.244-2.575,3.574c-3.438,3.314-7.791,3.279-11.084-0.136
              c-3.184-3.301-3.407-8.06,0.459-11.788c1.018-0.981,2.276-1.759,3.193-2.075l1.508,2.561c-0.648,0.232-1.522,0.639-2.517,1.598
              c-2.126,2.05-1.888,4.831,0.075,6.822c2.181,2.262,4.894,2.133,6.793,0.302c0.973-0.938,1.454-1.796,1.779-2.545L706.995,705.918z
              "/>
            <path d="M698.331,687.333c0.838,0.806,0.907,2.049-0.028,3.023c-0.894,0.929-2.139,0.909-2.977,0.104
              c-0.861-0.827-0.908-2.094-0.016-3.022C696.225,686.486,697.448,686.528,698.331,687.333z M709.596,703.867l-11.035-10.604
              l2.699-2.81l11.035,10.604L709.596,703.867z"/>
            <path d="M721.248,691.197l-1.438-0.935l-0.063,0.07c0.271,1.497-0.154,3.259-1.465,4.742c-2.038,2.308-4.854,2.126-6.596,0.587
              c-2.896-2.559-2.089-6.414,1.679-10.632l-0.188-0.166c-0.754-0.665-2.272-1.462-4.061,0.563c-0.998,1.13-1.686,2.619-1.877,3.833
              l-2.383-1.099c0.23-1.306,1.047-3.226,2.648-5.038c3.244-3.673,6.511-2.674,9.007-0.47l4.144,3.661
              c1.036,0.915,2.09,1.762,2.9,2.269L721.248,691.197z M715.312,686.624c-1.856,2.007-3.135,4.356-1.393,5.896
              c1.13,0.998,2.292,0.726,3.104-0.192c1.02-1.154,1.015-2.667,0.419-3.654c-0.149-0.258-0.364-0.489-0.553-0.655L715.312,686.624z"
              />
            <path d="M708.996,671.119l2.451-3.027l17.334,14.041l-2.451,3.027L708.996,671.119z"/>
            <path d="M741.966,663.569c-0.166,0.884-0.762,2.427-1.872,3.988c-2.767,3.892-7.053,4.661-10.919,1.912
              c-3.738-2.658-4.836-7.294-1.724-11.672c0.819-1.152,1.913-2.147,2.755-2.628l1.955,2.237c-0.594,0.349-1.378,0.909-2.179,2.036
              c-1.712,2.406-0.964,5.097,1.333,6.69c2.561,1.821,5.203,1.194,6.732-0.957c0.782-1.101,1.098-2.032,1.279-2.829L741.966,663.569z
              "/>
            <path d="M745.363,644.639c4.758,2.997,4.775,7.685,2.7,10.981c-2.311,3.668-6.609,4.896-10.757,2.284
              c-4.253-2.679-5.1-7.11-2.64-11.018C737.095,643.032,741.43,642.162,745.363,644.639z M739.354,654.478
              c2.498,1.574,5.155,1.54,6.411-0.454c1.172-1.86,0.157-4.318-2.447-5.958c-2.021-1.272-4.901-1.825-6.308,0.407
              C735.553,650.786,737.2,653.122,739.354,654.478z"/>
            <path d="M734.358,634.656l1.858-3.424l8.009,4.345l0.03-0.055c-0.41-0.759-0.636-1.597-0.633-2.524
              c-0.027-0.872,0.187-1.793,0.651-2.649c1.243-2.292,4.066-3.871,8.375-1.533l7.926,4.3l-1.843,3.397l-7.54-4.091
              c-1.961-1.063-3.814-1.14-4.833,0.738c-0.719,1.325-0.345,2.744,0.482,3.657c0.216,0.261,0.561,0.483,0.946,0.693l8.037,4.36
              l-1.857,3.424L734.358,634.656z"/>
            <path d="M757.967,623.46c2.547,1.08,4.543-0.382,5.576-2.604c0.755-1.624,1.07-2.897,1.161-4.137l2.66,0.612
              c-0.072,1.422-0.47,3.246-1.396,5.24c-2.093,4.502-6.088,5.866-10.333,3.893c-3.846-1.788-6.378-5.806-4.312-10.25
              c2.093-4.501,6.484-4.261,9.533-2.844c0.655,0.305,1.142,0.601,1.429,0.803L757.967,623.46z M758.352,616.225
              c-1.269-0.625-3.642-1.035-4.729,1.301c-1.006,2.165,0.536,3.991,1.894,4.796L758.352,616.225z"/>
            <path d="M766.385,612.153c0.823-0.616,1.969-1.95,2.484-3.232c0.633-1.574,0.273-2.531-0.659-2.906
              c-0.962-0.387-1.692-0.003-3.007,1.5c-2.075,2.382-4.06,2.938-5.709,2.241c-2.507-1.008-3.627-3.862-2.303-7.156
              c0.633-1.573,1.592-2.779,2.356-3.419l2.255,1.719c-0.596,0.472-1.52,1.42-2.023,2.674c-0.516,1.282-0.185,2.228,0.689,2.579
              c0.903,0.363,1.61-0.131,2.978-1.681c1.964-2.189,3.756-2.856,5.866-2.042c2.564,1.031,3.621,3.792,2.109,7.552
              c-0.691,1.72-1.72,3.1-2.725,3.915L766.385,612.153z"/>
            <path d="M757.658,590.156c1.094,0.392,1.666,1.497,1.211,2.769c-0.435,1.213-1.578,1.704-2.672,1.313
              c-1.124-0.402-1.686-1.538-1.251-2.75C755.391,590.245,756.523,589.783,757.658,590.156z M774.694,600.627l-14.401-5.156
              l1.313-3.667l14.401,5.157L774.694,600.627z"/>
            <path d="M774.419,576.296c5.378,1.647,6.622,6.169,5.481,9.895c-1.27,4.146-5.1,6.457-9.786,5.021
              c-4.807-1.473-6.785-5.528-5.433-9.945C766.016,576.91,769.973,574.935,774.419,576.296z M771.192,587.368
              c2.824,0.865,5.381,0.137,6.071-2.117c0.644-2.103-0.979-4.21-3.923-5.111c-2.283-0.699-5.21-0.479-5.983,2.045
              C766.558,584.799,768.759,586.623,771.192,587.368z"/>
            <path d="M772.437,572.213c-1.713-0.406-3.157-0.716-4.463-0.929l0.783-3.303l2.275,0.346l0.021-0.092
              c-1.01-0.917-2.032-2.871-1.445-5.348c0.616-2.6,2.936-4.892,7.646-3.775l8.837,2.095l-0.892,3.761l-8.409-1.993
              c-2.141-0.508-3.949-0.097-4.436,1.952c-0.354,1.498,0.469,2.792,1.506,3.458c0.308,0.201,0.759,0.341,1.188,0.442l8.745,2.073
              l-0.898,3.791L772.437,572.213z"/>
          </g>
        </g>
      </svg>
      <p class="caption-dark">
        Figure 4: Doughnut model of Sustainable Development after Raworth (2017). <em>Hover over the doughnut diagram to explore.</em>
      </p>
    </b-col>
    <b-col cols="1">&nbsp;</b-col>
    <b-col cols="4 align-self-center" id="donut-svg-text">
      <!-- Nicola: Nur den Paragraph anzeigen, der in Grafik angeklickt -->
      <p id="donut-outer-glow-text" v-show="activeDonutText === 'donut-outer-glow-text'">
        <strong>Overshoot</strong>
        <br>
        If earth system boundaries are transgressed, the use of these resources overshoots what would be sustainable use of ecological resources. Overshoot may trigger large and unforeseen consequences within the earth system.
      </p>
      <p id="donut-outer-border-text" v-show="activeDonutText === 'donut-outer-border-text'">
        <strong>Ecological Ceiling</strong>
        <br>
        The ecological ceiling is defined by several earth system boundaries such as biodiversity loss or climate change.
      </p>
      <p id="donut-ring-fill-text" v-show="activeDonutText === 'donut-ring-fill-text'">
        <strong>The safe and just space for humanity</strong>
        <br>
        A balance of meeting all social needs for human well-being while not crossing ecological boundaries ensures a sustainable coexistence of humans and nature.
      </p>
      <p id="donut-inner-border-text" v-show="activeDonutText === 'donut-inner-border-text'">
        <strong>Social Foundation</strong>
        <br>
        In ensuring that the social foundations for all are met, a just society with food security, education for all and social equity can prosper.
      </p>
      <p id="donut-inner-glow-text" v-show="activeDonutText === 'donut-inner-glow-text'">
        <strong>Shortfall</strong>
        <br>
        A failure to ensure a social foundation for all results in a society which may not necessarily cross ecological boundaries but at the same time also not ensure well-being for all.
      </p>
    </b-col>
  </b-row>
</template>
<script>

export default {
  components: {
  },
  name: 'donut-diagram',
  data() {
    return {
      activeDonutText: '',
    };
  },
  methods: {
    setDonutText(textId) {
      this.activeDonutText = textId;
    },
  },
};
</script>
<style lang="scss" scoped>
/* SVG Styles */
.donut-svg {
  width: 600px;
  height: 600px;
}
.donutOuterBorder {
  filter: grayscale(0.2);
}
.donutOuterBorder:hover {
  filter: none;
    stroke: black;
    stroke-width: 2;
}
.donutInnerBorder1 {
  filter: grayscale(0.2);
}
.donutInnerBorder1:hover {
  filter: none;
  stroke: black;
  stroke-width: 2;
}
.donutRingFill {
  filter: grayscale(0.2);
}
.donutRingFill:hover {
  filter: none;
  stroke: black;
  stroke-width: 2;
}
.donutInnerGlow {
  filter: grayscale(1);
}
.donutInnerGlow:hover {
  filter: none;
}
.donutOuterGlow {
  filter: grayscale(1);
}
.donutOuterGlow:hover {
  filter: none;
}
.caption-dark {
  color: #111;
  font-size: 10pt;
}
</style>
