import axiosBase from './AxiosBase';

const resource = '/accounts';

export default {
  register(payload) {
    return axiosBase.post(`${resource}/register/`, payload);
  },
  verifyRegistration(payload) {
    return axiosBase.post(`${resource}/verify-registration/`, payload);
  },
  sendResetPasswordLink(payload) {
    return axiosBase.post(`${resource}/send-reset-password-link/`, payload);
  },
  resetPassword(payload) {
    return axiosBase.post(`${resource}/reset-password/`, payload);
  },
  login(payload) {
    return axiosBase.post(`${resource}/login/`, payload);
  },
  logout() {
    return axiosBase.post(`${resource}/logout/`, { revoke_token: true });
  },
  getProfile() {
    return axiosBase.get(`${resource}/profile/`);
  },
  changePassword(payload) {
    return axiosBase.post(`${resource}/change-password/`, payload);
  },
  registerEmail(payload) {
    return axiosBase.post(`${resource}/register-email/`, payload);
  },
  verifyEmail(payload) {
    return axiosBase.post(`${resource}/verify-email/`, payload);
  },
};
