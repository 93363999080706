<template>
  <b-container fluid>
    <b-row no-gutters cols="1" cols-lg="2">
      <b-col cols="12" lg="8" xl="9">
        <b-container fluid>
          <!-- creator -->
          <b-row cols="2" v-if="metaData.creator" no-gutters>
            <b-col cols="3">
              <h5>Creator</h5>
            </b-col>
            <b-col cols="9">
              {{ metaData.creator }}
            </b-col>
          </b-row>

          <!-- contributor -->
          <b-row cols="2" v-if="metaData.contributor" no-gutters>
            <b-col cols="3">
              <h5>Contributor</h5>
            </b-col>
            <b-col cols="9">
              {{ metaData.contributor }}
            </b-col>
          </b-row>

          <!-- publisher -->
          <b-row cols="2" v-if="metaData.publisher" no-gutters>
            <b-col cols="3">
              <h5>Publisher</h5>
            </b-col>
            <b-col cols="9">
              {{ metaData.publisher }}
            </b-col>
          </b-row>

          <!-- Category -->
          <b-row cols="2" no-gutters>
            <b-col cols="3">
              <h5>Category</h5>
            </b-col>
            <b-col cols="9">
              <b-badge
                variant="primary"
                pill
                class="m-1"
              >
                {{ metaData.category || 'other' }}
              </b-badge>
            </b-col>
          </b-row>

          <!-- SDGs -->
          <b-row cols="2" v-if="metaData.sdg" no-gutters>
            <b-col cols="3">
              <h5>SDG Goals</h5>
            </b-col>
            <b-col cols="9">
              <b-badge
                v-for="(elem, index) in metaData.sdg"
                :key="`SDG-${index}`"
                variant="secondary"
                class="m-1"
                pill
                v-b-tooltip.hover
                :title="elem.description"
              >{{ elem.abbrev }}</b-badge>
            </b-col>
          </b-row>

          <!-- SDG Targets -->
          <b-row cols="2" v-if="metaData.sdg_targets" no-gutters>
            <b-col cols="3">
              <h5>SDG Targets</h5>
            </b-col>
            <b-col cols="9">
              <b-badge
                v-for="(elem, index) in metaData.sdg_targets"
                :key="`sgd-target-${index}`"
                variant="info"
                class="m-1"
                v-b-tooltip.hover
                :title="elem.description"
              >{{ elem.abbrev }}</b-badge>
            </b-col>
          </b-row>

          <!-- SDG Indocators -->
          <b-row cols="2" v-if="metaData.sdg_indicators" no-gutters>
            <b-col cols="3">
              <h5>SDG Indicators</h5>
            </b-col>
            <b-col cols="9">
              <b-badge
                v-for="(elem, index) in metaData.sdg_indicators"
                :key="`sgd-indicator-${index}`"
                variant="info"
                class="m-1"
                v-b-tooltip.hover
                :title="elem.description"
              >{{ elem.abbrev }}</b-badge>
            </b-col>
          </b-row>

          <!-- TAGS -->
          <b-row cols="2" v-if="metaData.subject" no-gutters>
            <b-col cols="3">
              <h5>Keywords</h5>
            </b-col>
            <b-col cols="9">
              <b-badge
                v-for="(elem, index) in asSeparatedList(metaData.subject)"
                :key="`tag-${index}`"
                variant="light"
                pill
                class="m-1"
              >{{ elem.tag }}</b-badge>
            </b-col>
          </b-row>

          <!-- WEB-Link -->
          <b-row cols="2" v-if="metaData.web_link" no-gutters>
            <b-col cols="3">
              <h5>Web-Link</h5>
            </b-col>
            <b-col cols="9">
              <b-link :href="metaData.web_link" target="_blank">
                {{ metaData.web_link }} (opens in new window/tab)
              </b-link>
            </b-col>
          </b-row>

          <!-- Data type -->
          <b-row cols="2" v-if="isRaster || isVector || isPDF" no-gutters>
            <b-col cols="3">
              <h5>Source Data Type</h5>
            </b-col>
            <b-col cols="9">
              <p>
                <span v-if="isPDF">
                  PDF
                  <b-icon
                    v-if="isPDF"
                    icon="file-text"
                    title="PDF/Document"
                  ></b-icon>
                </span>
                <span v-if="isVector">
                    Vector
                  <b-icon
                    v-if="isVector"
                    icon="bezier2"
                    title="Vector data"
                  ></b-icon>
                </span>
                <span v-if="isRaster">
                  Raster/Image
                  <b-icon
                    v-if="isRaster"
                    icon="grid3x3"
                    title="Raster (image) data"
                  ></b-icon>
                </span>
                <small class="pr-2">
                  (See below for raw data download if available for this dataset.)
                </small>
              </p>
            </b-col>
          </b-row>

        </b-container>
      </b-col>

      <b-col cols="12" lg="4" xl="3">
        <div
          class="map"
          v-if="readyToLoad"
        >
          <l-map
            class="w-100 mw-100 h-100"
            :zoom="zoomMap"
            ref="meta-view-map"
            @ready="mapReady"
          >
            <l-tile-layer
              :name="baseLayer.name"
              :url="baseLayer.url"
              :attribution="baseLayer.attribution"
              layer-type="base"
              :options="{
                maxNativeZoom: 17,
              }"
            ></l-tile-layer>
            <l-geo-json
              v-if="dataLocationGeoJSON"
              :geojson="dataLocationGeoJSON"
            />
          </l-map>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="metaData.description" class="mt-2">
      <b-col cols="12">
        <h5>Description</h5>
      </b-col>
      <b-col cols="12">
        <p v-html="metaData.description"></p>
      </b-col>
    </b-row>
    <b-row v-if="metaData.dataset">
      <b-col cols="12">
        <h5>Downloads</h5>
      </b-col>
      <b-col cols="12">
        <ul>
          <li>
            <b-link
              :href="metaData.dataset"
              target="_blank"
              title="download raw dataset"
            >
              <b-icon
                v-if="isRaster"
                icon="grid3x3"
                title="Raster (image) data"
              ></b-icon>
              <b-icon
                v-if="isVector"
                icon="bezier2"
                title="Vector data"
              ></b-icon>
              <b-icon
                v-if="isPDF"
                icon="file-text"
                title="PDF/Document"
              ></b-icon>
              &nbsp;
              <b-icon icon="box-arrow-in-down" />
              raw dataset
              <small v-if="isPDF">
                (PDF)
              </small>
              <small v-if="isVector">
                (Vector)
              </small>
              <small v-if="isRaster">
                (Raster/Image)
              </small>
            </b-link>
          </li>
          <li v-if="metaData.style_file">
            <b-link
              :href="metaData.style_file"
              target="_blank"
              title="download existing style to datatset"
            >
              <b-icon icon="box-arrow-in-down" />
              style file
              <small>(sld or zip packaged sld)</small>
            </b-link>
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {
  distinct, asComparator,
} from '@/common';
import {
  LMap, LTileLayer, LGeoJson,
} from 'vue2-leaflet';
import L from 'leaflet';

const DATE_FORMAT = new Intl.DateTimeFormat(
  'default',
  { year: 'numeric', month: 'long', day: 'numeric' },
);

const SELECTED_METADATA_ATTRIBUTES = [
  {
    title: 'Description',
    key: 'description',
  },
];

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  props: {
    metaData: {
      required: false,
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      map: null,
      zoomMap: 7,
      dataLocationGeoJSON: null,
      readyToLoad: false,
      geojsonOptions: {},
      baseLayer: {
        id: 'Default OSM',
        name: 'Default OSM',
        visible: true,
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '© <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.readyToLoad = true;
      });
    }, 10);
  },
  methods: {
    mapReady() {
      this.map = this.$refs['meta-view-map'].mapObject;
      // eslint-disable-next-line no-underscore-dangle
      this.map._handlers.forEach((handler) => {
        handler.disable();
      });
      this.$nextTick(() => {
        this.centerMapOnPoint();
      });
    },
    centerMapOnPoint() {
      const hasCenter = this.metaData && this.metaData.center;
      if (hasCenter && this.map) {
        this.map.invalidateSize();
        const bounds = L.geoJson(this.metaData.center).getBounds();
        this.map.fitBounds(bounds);
        this.map.setZoom(9);
        this.dataLocationGeoJSON = this.metaData.center;
      }
    },
    asDate(toFormat) {
      return DATE_FORMAT.format(new Date(String(toFormat)));
    },
    asSeparatedList(toFormat) {
      return distinct([...toFormat.split(', ')].map((s) => s.trim().toLowerCase()))
        .sort()
        .map((tag) => ({ tag, isCategory: false }));
    },
    asPrefixedSdgs(prefix, arr) {
      return [...arr].sort(asComparator((sdg) => sdg.name)).map((s) => prefix + s.name);
    },
  },
  computed: {
    metaDataAttrs() {
      return SELECTED_METADATA_ATTRIBUTES;
    },
    isRaster() {
      return this.metaData.data_type.toLowerCase() === 'raster';
    },
    isVector() {
      return this.metaData.data_type.toLowerCase() === 'vector';
    },
    isPDF() {
      return this.metaData.data_type.toLowerCase() === 'pdf';
    },
  },
};

</script>
<style lang="scss" scoped>
.map {
  width: 250px;
  height: 250px;
}
</style>
