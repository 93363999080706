<template>
  <b-container class="host">
    <b-card no-body class="playground">
      <b-tabs
        pills
        nav-wrapper-class="w-100 navbar-dark bg-primary navbar-expand-lg"
        nav-class="navbar-nav"
      >
        <b-tab :active="activeTab === '#memory'" :title="accordionTitle1">
          <b-card-text><Memory /></b-card-text>
        </b-tab>
        <b-tab :active="activeTab === '#quiz'" :title="accordionTitle2">
          <b-card-text><Quiz /></b-card-text>
        </b-tab>
        <b-tab :active="activeTab === '#exercises'" :title="accordionTitle3">
          <b-card-text><interactive-exercises></interactive-exercises></b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>
<script>
import Quiz from '@/components/playground/Quiz.vue';
import Memory from '@/components/playground/Memory.vue';
import InteractiveExercises from '@/components/playground/InteractiveExercises.vue';

export default {
  components: {
    Memory,
    InteractiveExercises,
    Quiz,
  },
  data() {
    return {
      accordionTitle1: 'Play the Sustainable Development Memory',
      accordionTitle2: 'Take the Case Study Quiz',
      accordionContent2: '',
      accordionTitle3: 'Explore interactive exercises',
      accordionContent3: '',
    };
  },
  computed: {
    activeTab() {
      return this.$route.hash;
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.playground {
  padding: 1.25rem;
}

.host /deep/ .nav-item {
  padding: 1.25rem;
}

.host /deep/ .nav-item:not(:last-child) {
  border-right: 1px white solid;
}
</style>
