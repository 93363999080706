<template>
  <b-container>
    <b-card
      v-if="registrationResult"
      bg-variant="success"
      text-variant="white"
      header="Registration successful"
      class="text-center"
    >
      <b-card-text>
        Registration successfull. Please check your Inbox to validate your
        email-address. After validation, you can login and use our site.
        We sent an email to {{ registrationResult.email }}.
        If this is not correct, please contact us at
        <a href="mailto:support@academy-2030.org">support@academy-2030.org</a>.
      </b-card-text>
    </b-card>

    <b-form v-else @submit.stop.prevent>

      <h2>Registration</h2>

      <p>
        Upon completion, you will get an email with a confirmation link
        to validate your email address. After confirming your email address
        your login is enabled and you can use our services.
      </p>

      <b-form-group label="First Name" for="first-name">
        <b-form-input
          id="first-name"
          maxlength="30"
          v-model="$v.form.first_name.$model"
          :state="validateState('first_name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Last Name" for="last-name">
        <b-form-input
          id="last-name"
          maxlength="30"
          v-model="$v.form.last_name.$model"
          :state="validateState('last_name')"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Username" for="username">
        <b-form-input
          id="username"
          maxlength="30"
          aria-describedby="input-username-live-help input-username-live-feedback"
          placeholder="Enter a username"
          trim
          v-model="$v.form.username.$model"
          :state="validateState('username')"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-username-live-feedback"
        >
          This is a required field and must be at least 6 characters.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Email" for="email">
        <b-form-input
          id="email"
          type="email"
          aria-describedby="input-live-help input-email-live-feedback"
          placeholder="your.email@example.com"
          trim
          v-model="$v.form.email.$model"
          :state="validateState('email')"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-email-live-feedback"
        >
          This is a required field and must be at valid email address.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Password" for="password">
        <b-form-input
          id="password"
          type="password"
          aria-describedby="input-live-help input-password-live-feedback"
          v-model="$v.form.password.$model"
          :state="validateState('password')"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-password-live-feedback"
        >
          The password must be at least 10 characters.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Confirm Password" for="password-confirm">
        <b-form-input
          id="password-confirm"
          type="password"
          aria-describedby="input-live-help input-password-confirm-live-feedback"
          v-model="$v.form.password_confirm.$model"
          :state="validateState('password_confirm')"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-password-confirm-live-feedback"
        >
          The confirmation must match the password.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button @click="register">submit</b-button>
    </b-form>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import {
  validationMixin,
} from 'vuelidate';
import {
  required, minLength, sameAs, email,
} from 'vuelidate/lib/validators';
import { paths } from '@/router';

export default {
  mixins: [validationMixin],
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      username: {
        required,
        minLength: minLength(6),
      },
      password: {
        required,
        minLength: minLength(10),
      },
      password_confirm: {
        sameAsPassword: sameAs('password'),
      },
      email: {
        required,
        email,
      },
    },
  },
  data() {
    return {
      registrationResult: null,
      form: {
        username: null,
        password: null,
        password_confirm: null,
        first_name: null,
        last_name: null,
        email: null,
      },
      fieldNames: [
        'username',
        'password',
        'password_confirm',
        'first_name',
        'last_name',
        'email',
      ],
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push(paths.profile);
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    ...mapActions({
      submitRegistration: 'auth/submitRegistration',
      showSuccessToast: 'toasts/addSuccessToast',
    }),
    async register() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const payload = {};
      this.fieldNames.forEach((fieldName) => { payload[fieldName] = this.form[fieldName]; });

      const [responseData, errors] = await this.submitRegistration(
        { payload, errorFields: this.fieldNames },
      );

      if (!errors && responseData) {
        this.showSuccessToast({
          title: 'Registration Successful',
          message: 'Please check your inbox to validate your email.',
        });
        this.registrationResult = responseData;
      }
    },
    resetForm() {
      this.form = {
        username: null,
        password: null,
        password_confirm: null,
        first_name: null,
        last_name: null,
        email: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
};
</script>
