<template>
  <b-container>
    <h2>Login</h2>

    <b-form inline>

      <label class="sr-only" for="login-username">
        Username
      </label>
      <b-form-input
        id="login-username"
        maxlength="30"
        v-model="username"
        v-on:keyup.enter="authenticate"
        required
        placeholder="Username"
      ></b-form-input>

      <label class="sr-only" for="login-password">
        Password
      </label>
      <b-form-input
        type="password"
        v-model="password"
        required
        v-on:keyup.enter="authenticate"
        placeholder="password"
      ></b-form-input>

      <b-button @click="authenticate">login</b-button>
    </b-form>

    <p>
      You don't have a login yet?
      <b-link :to="routerPaths.register">Register</b-link>
      a new account.
    </p>

  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import { paths } from '@/router';

export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push(paths.profile);
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/logIn',
    }),
    async authenticate() {
      const payload = {
        login: this.username,
        password: this.password,
      };
      const [, errors] = await this.login({ payload, errorFields: ['login', 'password'] });
      if (!errors) {
        this.$router.push(this.$route.query.redirect || '/');
      }
    },
  },
  computed: {
    routerPaths() {
      return paths;
    },
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
};
</script>
