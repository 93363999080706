<template>
  <!-- eslint-disable max-len -->
  <div v-b-visible="visibleHandler">
    <b-container>
      <b-row>
        <!-- See https://bootstrap-vue.org/docs/components/layout for correct usage -->
        <b-col cols="align-self-center sm-12 md-6 lg-6">
        </b-col>
      </b-row>
    </b-container>
    <b-container class="q-container mt-4 mb-4 pb-4">
      <b-row cols="2">
        <b-col>
          <h4>Land cover changes</h4>
          <p class= "q-text">
            Compare the two <a href="https://en.wikipedia.org/wiki/Land_cover" target="_blank" rel="noopener noreferrer">land cover</a> maps from the years 2009 and 2019 by using the image slider.
            Describe land cover changes you observe around the LAPSSET (Isiolo-Moyale).
            Discuss land use activities that could lead to your observed changes.</p>
        </b-col>
        <b-col>
          <TwentyTwenty
            v-if="pageLoaded"
            offset="0.5"
            :before="beforeImageLC"
            beforeLabel="2009"
            :after="afterImageLC"
            afterLabel="2019" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="answer">
            <FlipCard reactOnClick>
              <template #front>
                <b-card
                  class="m-1 bg-gradient-secondary quiz-card text-light"
                  :style="`height: 400px;`"
                  no-body
                >
                  <header></header>
                  <div class="card-front">Click to reveal answers</div>
                </b-card>
              </template>
              <b-card
                class="m-1 bg-gradient-secondary quiz-card text-light"
                :style="`height: 400px;`"
                no-body
              >
                <div class="card-back">
                  Land cover changes:
                  <ul>
                    <li>
                      Along the LAPSSET there is a considerable increase in grassland at the expense of shrubland.
                    </li>
                    <li>
                      A significant increase in settlement area can be observed near the regional centres of Isiolo, Marsabit and Meru.
                    </li>
                  </ul>
                  Changes in land use that could lead to the observed changes in land cover:
                  <ul>
                    <li>
                      Degradation of shrublands and the ensuing conversion to grasslands may be due to overgrazing.
                      Small ruminants feeding of bushes and leaves may impede vegetation growth for long periods.
                    </li>
                    <li>
                      The construction of the LAPSSET road may increase pressures on natural resources.
                      Some linked causes include an increase in people and livestock, uptick in immigration, or expanding economic activities.
                    </li>
                    <li>
                      A higher variability in land cover dynamics may be exacerbated by climate change.
                    </li>
                  </ul>
                </div>
              </b-card>
            </FlipCard>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="q-container mb-4 pb-4">
      <b-row cols="2">
        <b-col>
          <h4>Mining livelihoods</h4>
          <p class= "q-text">
            On the right you can see a map of southern Ethiopia. The northern part of the map, with its high population density, is located in the highlands, while the southern areas extend into the lowlands.
          </p>
          <p class= "q-text">
            Compare population density and livelihood zones (cropping systems) in the highlighted area of high mineral occurrence (green oval).
            <br>Discuss potential implications of mining activities on people, land use, livelihoods (e.g. farming or livestock keeping).
          </p>
        </b-col>
        <b-col>
          <TwentyTwenty
            v-if="pageLoaded"
            offset="0.5"
            :before="beforeImageMining"
            beforeLabel="Population Density"
            :after="afterImageMining"
            afterLabel="Cropping System" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="answer">
            <FlipCard reactOnClick>
              <template #front>
                <b-card
                  class="m-1 bg-gradient-secondary quiz-card text-light"
                  :style="`height: 400px;`"
                  no-body
                >
                  <header></header>
                  <div class="card-front">Click to reveal answers</div>
                </b-card>
              </template>
              <b-card
                class="m-1 bg-gradient-secondary quiz-card text-light"
                :style="`height: 400px;`"
                no-body
              >
                <div class="card-back">
                  <ul>
                    <li>
                      The area where minerals occur in the South transitions from highlands to the lowlands – from high population densities to low population densities, from cash-crop/cereal production to mixed cropping and pastoral activities (livestock). In the drier lowlands dependence on dry-season river flow can be substantial.
                    </li>
                    <li>
                      In the highlands, mining can affect a large number of people as well as crop production. Economic benefits can have a positive effect on the income of livelihoods directly or indirectly involved in mining, but potential negative effects need to be governed to reduce effects on health and e.g. food production due to the effects of mining on river water.
                    </li>
                    <li>
                      In the lowlands, a lower number of people are potentially affected (and could benefit from mining activities). But due to the stronger dependence on river water during the dry season (households and livestock), upstream mining activities may have stronger effects on health and livelihoods.
                    </li>
                  </ul>
                </div>
              </b-card>
            </FlipCard>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="q-container mb-4 pb-4">
      <b-row cols="2">
        <b-col>
          <h4>Land cover data sources</h4>
          <p class= "q-text">
            Information on land cover can be obtained from various data sources. These data usually differ in geographical scope (regional, national, global) and image resolution.
            <br>In the image slider on the right you can find two data sets: One from the Water and Land Resource Centre, Addis Ababa University and the other from the International Geosphere-Biosphere Programme.
          </p>
          <p class= "q-text">
            Compare the two different land cover data sources.
            Discuss differences between the two sources and potential implications when using for analyses.
          </p>
        </b-col>
        <b-col>
          <TwentyTwenty
            v-if="pageLoaded"
            offset="0.5"
            :before="beforeImageLCDatasets"
            beforeLabel="WLRC Dataset"
            :after="afterImageLCDatasets"
            afterLabel="IGBP Dataset" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="answer">
            <FlipCard reactOnClick>
              <template #front>
                <b-card
                  class="m-1 bg-gradient-secondary quiz-card text-light"
                  :style="`height: ${height}px;`"
                  no-body
                >
                  <header></header>
                  <div class="card-front">Click to reveal answers</div>
                </b-card>
              </template>
              <b-card
                class="m-1 bg-gradient-secondary quiz-card text-light"
                :style="`height: ${height}px;`"
                no-body
              >
                <div class="card-back">
                  <p>
                    Land cover datasets from different sources may vary in the representation of land cover types depending on geographic and thematic scope of the data, scale or resolution, as well as number, types, and definition of land cover categories. Available land cover datasets therefore need to be evaluated and the most suitable selected to fit the thematic (e.g. farming, grazing, or nature protection), geographic (from local to global), and temporal scope of your analysis.
                  </p>
                </div>
              </b-card>
            </FlipCard>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="q-container mb-4 pb-4">
      <b-row cols="2">
        <b-col>
          <h4>Data aggregation</h4>
          <p class="q-text">
            Data aggregation can support the detection of patterns and trends, but it can also mask finer nuances that exist in the data. Aggregation can thus enable or inhibit the detection of additional factors that may contribute to a certain trend or pattern. Experimenting with different levels of aggregation when analyzing data can thus be key to understanding interactions.
          </p>
          <p class="q-text">
            Compare the two bubble charts below. The one of the left is featured in the Kenya case study, the right shows the same indicators but not aggregated to the LAPSSET corridor.
            Explore how aggregation of data on the sub-county level changes implications of the same data. Discuss notable changes and reflect which aggregation level is suited to which application.
          </p>
        </b-col>
        <b-col>
          &nbsp;
        </b-col>
      </b-row>
      <b-row  cols="2" class="no-gutters">
        <b-col>
          <BubbleChart
            title="Access to Infrastructure (2009 to 2019)"
            :group-by="'ID'"
            :x-axes-range="'Year'"
            :data-src="`${assetsURL}blog/kenya_story/data/census_data_LAPSSET_agg.csv`"
            :header-rows="1"
            :data-mappings="mappings"
            :marker="marker"
            v-on:selectedMappingChange="changeSelectedMapping($event)"
          />
        </b-col>
        <b-col>
          <BubbleChart
            title="Access to Infrastructure (2009 to 2019)"
            :group-by="'ID'"
            :x-axes-range="'Year'"
            :data-src="`${assetsURL}blog/kenya_story/data/census_data_subloc.csv`"
            :header-rows="1"
            :data-mappings="mappings"
            :marker="marker"
            v-on:selectedMappingChange="changeSelectedMapping($event)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="answer">
            <FlipCard reactOnClick>
              <template #front>
                <b-card
                  class="m-1 bg-gradient-secondary quiz-card text-light"
                  :style="`height: 650px;`"
                  no-body
                >
                  <header></header>
                  <div class="card-front">Click to reveal answers</div>
                </b-card>
              </template>
              <b-card
                class="m-1 bg-gradient-secondary quiz-card text-light"
                :style="`height: 650px;`"
                no-body
              >
                <div class="card-back">
                  <ul>
                    <li>
                      At aggregated level, access to clean water and sanitation increased inside and outside, but more strongly inside the LAPSSET corridor. At sub-county level, the changes are more nuanced, while access to sanitation facilities increased for most sub-counties, access to clean water did not improve for all. Outside the LAPSSET it got worse for Samburu West and Garbatulla, both also showing strong increases in population. For counties intersected by the highway, access to clean water slightly decreased in Moyale, which also shows population growth. Sololo shows a strong decrease in access to safe water sources, but without population growth. Most likely other factors that are not visible in this data have an influence on access to clean water.
                    </li>
                    <li>
                      Access to electricity and use of clean fuels for cooking also shows a clear pattern at aggregated level – inside the LAPSSET it was already higher than outside the LAPSSET in 2009 and increased more strongly until 2019. At sub-county level access to electricity and clean fuels for cooking increased for all counties and for many sub-counties inside the LAPSSET it also increased more strongly. However, also sub-counties with larger settlements or urban areas outside the LAPSSET (e.g. Samburu West) can show higher access to electricity and clean fuels. In addition to the LAPSSET corridor, the urban-rural gradient seems to also be a determinant of access to electricity and clean fuels.
                    </li>
                    <li>
                      At aggregated level, population density and household size increase inside and outside the LAPSSET from 2009 to 2019 and show a similar pattern. At sub-county level it becomes clear that rather sub-counties with urban areas (Isiolo, Samburu West, Marsabit Central, Moyale) show substantial increases in population densities while household sizes increased only slightly (decrease in Moyale). The other, mostly rural sub-counties, do not show such substantial increases in population densities, but some of them show increases in the household size from 4 or 5 people in 2009 to more than 7 in 2019. In this data the effects of the LAPSSET corridor seem less pronounced than the urban-rural gradient.
                    </li>
                  </ul>
                </div>
              </b-card>
            </FlipCard>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="q-container mb-4 pb-4">
      <b-row cols="2">
        <b-col>
          <h4>Gold trade income and price</h4>
          <p class= "q-text">
            Compare Ethiopia's income from gold exports with the development of the gold market price in the same time frame. Discuss possible links and their implications.
          </p>
        </b-col>
        <b-col>
          <TwentyTwenty
            v-if="pageLoaded"
            offset="0.5"
            :before="beforeImageGold"
            beforeLabel="Export volume"
            :after="afterImageGold"
            afterLabel="Gold market price" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="answer">
            <FlipCard reactOnClick>
              <template #front>
                <b-card
                  class="m-1 bg-gradient-secondary quiz-card text-light"
                  :style="`height: ${height}px;`"
                  no-body
                >
                  <header></header>
                  <div class="card-front">Click to reveal answers</div>
                </b-card>
              </template>
              <b-card
                class="m-1 bg-gradient-secondary quiz-card text-light"
                :style="`height: ${height}px;`"
                no-body
              >
                <div class="card-back">
                  <p>
                    Over the last two decades the gold price varied heavily, e.g. in 2005 a kilogram of gold cost approximately 6,000 US$, in 2012 it reached a peak of 42,000 US$, and fell to around 34,000 US$ in 2013 where it more or less stabilized. During this price peak, gold exports of Ethiopia also seem to have increased, but a large amount of the additional income from 2007 to 2012 occurred due to the tremendous increase in gold prices. These fluctuations affect the income of small-scale miners directly. A strong decrease of the gold price as from 2012 to 2013 means a substantial reduction of the income for miners, their families, and other livelihoods that indirectly depend on mining.
                  </p>
                </div>
              </b-card>
            </FlipCard>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TwentyTwenty from 'vue-twentytwenty';
import environment from '@/environment';
import FlipCard from '@/components/base/FlipCard.vue';
import beforeImageLC from '@/assets/playground/exercises/images/KEN_IGBP2009.jpg';
import afterImageLC from '@/assets/playground/exercises/images/KEN_IGBP2019.jpg';
import beforeImageMining from '@/assets/playground/exercises/images/PopDenseMiningCroppingSystem_1.jpg';
import afterImageMining from '@/assets/playground/exercises/images/PopDenseMiningCroppingSystem_2.jpg';
import beforeImageLCDatasets from '@/assets/playground/exercises/images/LandCoverComparison_WLRC-LC_1.jpg';
import afterImageLCDatasets from '@/assets/playground/exercises/images/LandCoverComparison_IGBP-LC_2.jpg';
import beforeImageGold from '@/assets/playground/exercises/images/A2030_eth-gold-exports.jpg';
import afterImageGold from '@/assets/playground/exercises/images/A2030_gold-rate.jpg';
import BubbleChart from '../../widgets/BubbleChart.vue';

export default {
  components: {
    TwentyTwenty,
    FlipCard,
    BubbleChart,
  },
  name: 'interactive-exercises',
  data() {
    return {
      height: 250,
      beforeImageLC,
      afterImageLC,
      beforeImageMining,
      afterImageMining,
      beforeImageLCDatasets,
      afterImageLCDatasets,
      beforeImageGold,
      afterImageGold,
      question: {
        item: {
          heading: 'Question Head',
          question: 'actual question',
        },
      },
      selectedMapping: 0,
      mappings: [
        {
          title: 'Clean water and improved sanitation',
          x: { column: 'Perc_san', title: 'Access to improved sanitation facilities (% of People)' },
          y: { column: 'Perc_wat', title: 'Access to safe water sources (% of People)' },
        },
        {
          title: 'Gain access to electricity',
          x: { column: 'Perc_fuel', title: 'Use of clean fuels for cooking (% of People)' },
          y: { column: 'Perc_elec', title: 'Access to electricity for lighting (% of People)' },
        },
        {
          title: 'Growth of settlements',
          x: { title: 'Population/Household', calc: { lColumn: 'Population', op: '/', rColumn: 'HH' } },
          y: { column: 'Pop_dens', title: 'Population Density (people/km2)' },
        },
      ],
      marker: {
        column: 'FIRST_sc_n',
        size: {
          legend: 'Population',
          column: 'Population',
          maxSize: 20,
          minSize: 5,
          maxValue: 160000,
        },
        color: {
          column: 'LAPSSET',
          legend: 'LAPSSET Corridor',
          indicator: {
            0: {
              style: '#ff747a',
              title: 'Outside LAPSSET',
            },
            1: {
              style: '#17bac1',
              title: 'Within LAPSSET corridor',
            },
          },
        },
      },
      assetsURL: environment.assetsURL,
      pageLoaded: false,
    };
  },
  methods: {
    changeSelectedMapping(newMapping) {
      this.selectedMapping = newMapping;
    },
    visibleHandler(isVisible) {
      this.pageLoaded = isVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-front {
  font-size: 20pt;
  font-weight: bold;
  text-align: center;
  margin: auto;
}
.card-back {
  margin: auto;
  width: 70%;
}
.q-container {
  border-bottom: 2px dotted;
}
</style>
