/**
 * Function provided by MDN see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 * @param string String which RegExp special chars should be escaped.
 * @returns {String} Returns the escaped string.
 */
export function escapeRegExp(text) {
  return String(text).replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function createRegExpFrom(text) {
  return new RegExp(`(${escapeRegExp(text)})`, 'gi');
}
