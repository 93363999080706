<template>
  <b-container fluid class="bg-light mt-0 py-5">
    <b-row cols="1" cols-md="3" class="text-center">
      <b-col>
        <h3>Content</h3>

        <h5>Learning</h5>
        <b-button-group vertical>
          <b-button
            variant="outline-primary"
            :to="paths.understandingSD"
          >
            Education for SD
          </b-button>
          <b-button
            variant="outline-primary"
            :to="paths.storyKenya"
          >
            Highway to Health?
          </b-button>
          <b-button
            variant="outline-primary"
            :to="paths.storyEthiopia"
          >
            Streams of Opportunity?
          </b-button>
        </b-button-group>

        <h5>Understanding</h5>
        <b-button-group vertical>
          <b-button
            variant="outline-secondary"
            :to="paths.sdgInteractions"
          >
            SDG Interactions
          </b-button>
          <b-button
            variant="outline-secondary"
            :to="paths.playground"
          >
            Playground
          </b-button>
        </b-button-group>

        <h5>Applying</h5>
        <b-button-group vertical>
          <b-button
            variant="outline-success"
            :to="paths.mapViewer"
          >
            Map Viewer
          </b-button>
          <b-button
            variant="outline-success"
            :to="paths.metaData"
          >
            Metadata Search
          </b-button>
          <b-button
            variant="outline-success"
            :to="paths.resources"
          >
            Resources
          </b-button>
        </b-button-group>
      </b-col>
      <b-col>
        <b-img
          src="@/assets/academy-2030-logo.png"
          alt="Academy 2030 Logo"
          fluid
          width="350%"
          class="mt-4"
        />
        <p class="mt-4">
          Knowledge, data, and education on sustainable development and the 2030 Agenda
          in Ethiopia and Kenya.
        </p>
      </b-col>
      <b-col>
        <b-dropdown
          v-if="isLoggedIn && username"
          id="user-actions"
          right
          variant="light"
          class="m-md-2"
        >
          <template #button-content>
            <b-icon-person-fill />
            {{ username }}
          </template>
          <b-dropdown-item :to="{ name: 'profile' }">
            Profile
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'logout' }"> Logout </b-dropdown-item>
        </b-dropdown>
        <router-link
          v-else
          class="btn btn-light"
          :to="{ name: 'login' }"
          custom
          v-slot="{ navigate }"
        >
          <span @click="navigate" @keypress.enter="navigate" role="link"
            >Login</span
          >
        </router-link>

        <h3>About</h3>
        <b-link :to="paths.about">About us</b-link>
        <br>
        <b-link :to="paths.terms">Terms and disclaimer</b-link>

        <h3>Contact</h3>
        <b-icon-envelope-fill></b-icon-envelope-fill>
        <a href="mailto:info.cde@unibe.ch">info.cde@unibe.ch</a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { BIconEnvelopeFill } from 'bootstrap-vue';
import { paths } from '@/router';

export default {
  data() {
    return {
      paths,
    };
  },
  computed: {
    ...mapGetters({ isLoggedIn: 'auth/isAuthenticated', username: 'auth/userName' }),
  },
  components: {
    BIconEnvelopeFill,
  },
  name: 'footer-site',
};
</script>
