<template>
  <div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('toasts');

const typeToVariant = {
  default: null,
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'danger',
};

export default {
  name: 'base-toast',
  props: {
    showDisplayActions: {
      type: Boolean,
      // TODO: switch to default false when finished with design
      default: false,
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.toasts.toasts': async function () {
      if (this.hasToasts) {
        const toast = await this.$store.dispatch('toasts/showNextToast');
        if (toast) {
          this.showToast(toast);
        }
      }
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    showToast({ title, message, toastType }) {
      this.count += 1;
      const id = `global-toast-${this.count}`;

      this.$bvToast.toast([message], {
        id,
        title,
        solid: true,
        variant: typeToVariant[toastType],
        autoHideDelay: 5000,
      });
    },
  },
  computed: {
    ...mapGetters({
      hasToasts: 'hasToasts',
    }),
  },
};
</script>
