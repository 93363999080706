const environment = {
  get baseURL() {
    const baseUrl = process.env.BASE_URL;
    return `${baseUrl}${baseUrl.endsWith('/') ? '' : '/'}`;
  },
  get apiBasePort() {
    return process.env.VUE_APP_API_BASE_PORT || 8000;
  },
  get apiBaseURL() {
    return process.env.VUE_APP_API_BASE_URL || `${window.location.protocol}//${window.location.hostname}:${this.apiBasePort}/api/v1`;
  },
  get assetsURL() {
    return `${this.baseURL}assets/`;
  },
};

export default environment;
