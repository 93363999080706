export default {
  namespaced: true,
  state: {
    wrappedGisData: [],
    loading: false,
    loaded: true,
  },
  actions: {
    async loadData({ dispatch }, payload) {
      await dispatch('metadata/loadData', payload, { root: true });
      await dispatch('wrapGisData');
    },
    async wrapGisData({ state, rootGetters }) {
      const gisData = rootGetters['metadata/gisData'];
      state.wrappedGisData = gisData.map((i) => {
        const wrappedItem = { ...i };
        wrappedItem.showDataOnMap = false;
        wrappedItem.selected = false;
        wrappedItem.queryingModeEnabled = false;
        return wrappedItem;
      });
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.wrappedGisData = (loading) ? null : state.wrappedGisData;
      state.loading = loading;
    },
    disableQueryMode(state, { exceptItem }) {
      state.wrappedGisData.forEach((i) => {
        if (exceptItem) {
          if (i.id === exceptItem.id) {
            // eslint-disable-next-line no-param-reassign
            i.queryingModeEnabled = true;
          }
          if (i.id !== exceptItem.id && i.queryingModeEnabled) {
            // eslint-disable-next-line no-param-reassign
            i.queryingModeEnabled = false;
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          i.queryingModeEnabled = false;
        }
      });
    },
  },
  getters: {
    wrappedGisData: (state) => state.wrappedGisData,
    loading: (state) => state.loading,
    loaded: (state) => state.gisData !== null,
  },
};
