import Papa from 'papaparse';

async function papaParseAsync(src, config) {
  return new Promise((resolve, reject) => {
    Papa.parse(src, {
      ...config,
      complete(results) {
        resolve(results);
      },
      error(error) {
        reject(error);
      },
    });
  });
}

class CvsContent {
  #dataRows = [];

  #headerRows = [];

  get data() {
    return this.#dataRows;
  }

  constructor(data = [], headerData = []) {
    this.#dataRows = data;
    this.#headerRows = headerData;
  }

  getMinMaxByName(colName) {
    const colIdx = this.findColIndex(colName);
    const col = this.#getNumbersByColIndex(colIdx, [0]);
    return {
      min: Math.min(...col),
      max: Math.max(...col),
    };
  }

  findColIndex(colName) {
    const rows = this.#headerRows;
    return rows.reduce(
      (prev, row) => ((prev === -1) ? row.findIndex((h) => h === colName) : prev),
      -1,
    );
  }

  #getDataByColIndex(colIdx = -1, defaultValue = []) {
    return (colIdx !== -1)
      ? this.#dataRows.reduce((arr, row) => [...arr, row[colIdx]], [])
      : defaultValue;
  }

  /* eslint-disable no-dupe-class-members */
  #getNumbersByColIndex(colIdx = -1, defaultValue = []) {
    return this.#getDataByColIndex(colIdx, defaultValue)
      .map((value) => Number(value))
      .filter((value) => !Number.isNaN(value));
  }
}

export async function parseCSV(srcPath, headerRows = 0, removeEmpty = true) {
  const readData = (await papaParseAsync(srcPath, {
    download: true, header: false,
  })) || { data: [] };

  let headerData = [];
  let { data } = readData;

  if (removeEmpty) {
    data = data.filter((row) => row.every((value) => value));
  }

  if (!Number.isNaN(headerRows) && headerRows < data.length) {
    headerData = data.slice(0, headerRows);
    data = data.slice(headerRows);
  }
  return new CvsContent(data, headerData);
}
