<template>
  <!-- eslint-disable max-len -->
  <b-row>
    <b-col cols="4  align-self-center">
      <h2 class="section-header">Bringing care closer</h2>
      <p>
        The changing landscape along the highway corridor – including construction of secondary roads – is making it much easier to travel to and within the region. This is leading to direct improvements in the provision of vital health services for local communities. The proportion of the study area from which a
        <strong v-bind:style="{ color: hfStyleOptions.color }">health facility &bull;</strong> can be reached within 60 minutes has increased from 22% in 2015 to 32% in 2019.
      </p>
    </b-col>
    <b-col cols="8">
      <l-map
        :zoom="zoomMap"
        :center="centerMap"
        :options="mapOptions"
        :max-bounds="maxBounds"
        @ready="enableZoomOnClick"
        ref="healthimplicationmap"
        style="height:500px; width: 100%"
      >
        <template v-if="geojsonHF">
          <l-circle-marker
            v-for="(markers, index) in geojsonHF.features"
            :key="'marker-' + index"
            :lat-lng="[markers.geometry.coordinates[1], markers.geometry.coordinates[0]]"
            :radius="hfStyleOptions.radius"
            :color="hfStyleOptions.color"
          />
        </template>
        <template v-if="geojsonTt2015 && toggleTT">
          <l-geo-json
            v-for="(polygon, index) in geojsonTt2015.features"
            :key="'shape-' + index"
            :geojson="polygon"
            :options="geojsonOptions"
            :options-style="styleFunctionTravelTime(polygon.properties.gridcode)"
          />
        </template>
        <template v-if="geojsonTt2019 && !(toggleTT)">
          <l-geo-json

              v-for="(polygon, index) in geojsonTt2019.features"
              :key="'shape-' + index"
              :geojson="polygon"
              :options="geojsonOptions"
              :options-style="styleFunctionTravelTime(polygon.properties.gridcode)"
          />
        </template>
        <l-tile-layer
          v-for="tileProvider in baseLayers"
          :key="tileProvider.id"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"
          :options="{
            maxNativeZoom: 17,
          }"
        ></l-tile-layer>
        <l-control
          class="accessibility-map-control"
          position="topright"
        >
          <button
            class="accessibility-map-button"
            @click="toggleTT = !(toggleTT)"
          >
            Click to switch the dataset
          </button>
        </l-control>
        <l-control
          class="accessibility-year-label"
          position="topright"
        >
          <div v-bind:class="{active: toggleTT}">displaying 2015</div>
          <div v-bind:class="{active: !(toggleTT)}">displaying 2019</div>
        </l-control>
        <l-control
          class="accessibility-map-legend"
          position="bottomright"
        >
          <div>
            <div class="legend-table">
              <div class="legend-table-row">
                <div class="legend-table-col" style="font-weight: bold; text-align: center">&nbsp;</div>
                <div  class="legend-table-col" style="font-weight: bold; text-align: center">Travel time (minutes)</div>
              </div>
              <div class="legend-table-row">
                <div class="legend-table-col" v-bind:style="{ backgroundColor: colorBrewerArray[0] }">&nbsp;</div>
                <div class="legend-table-col">&lt; 60</div>
              </div>
              <div class="legend-table-row">
                <div class="legend-table-col" v-bind:style="{ backgroundColor: colorBrewerArray[1] }">&nbsp;</div>
                <div class="legend-table-col">61 - 120</div>
              </div>
              <div class="legend-table-row">
                <div class="legend-table-col" v-bind:style="{ backgroundColor: colorBrewerArray[2] }">&nbsp;</div>
                <div class="legend-table-col">121 - 180</div>
              </div>
              <div class="legend-table-row">
                <div class="legend-table-col" v-bind:style="{ backgroundColor: colorBrewerArray[3] }">&nbsp;</div>
                <div class="legend-table-col">181 - 360</div>
              </div>
              <div class="legend-table-row">
                <div class="legend-table-col" v-bind:style="{ backgroundColor: colorBrewerArray[4] }">&nbsp;</div>
                <div class="legend-table-col">361 - 540</div>
              </div>
              <div class="legend-table-row">
                <div class="legend-table-col" v-bind:style="{ backgroundColor: colorBrewerArray[5] }">&nbsp;</div>
                <div class="legend-table-col">&gt; 540</div>
              </div>
            </div>
          </div>
        </l-control>
      </l-map>
    </b-col>
  </b-row>
</template>
<script>
import {
  LMap, LTileLayer, LGeoJson, LControl, LCircleMarker,
} from 'vue2-leaflet';
import { latLng, latLngBounds } from './map/leaflet-utils';
import baseLayers from './map/osm-layer-settings';
import environment from '../../../environment';

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LControl,
    LCircleMarker,
  },
  name: 'health-implications',
  data() {
    return {
      geojsonOptions: {},
      baseLayers,
      zoomMap: 7,
      mapOptions: {
        minZoom: 6,
        maxZoom: 12,
        scrollWheelZoom: false,
      },
      maxBounds: latLngBounds([
        [0, 30.5],
        [4.5, 44.5],
      ]),
      centerMap: latLng(2.2, 38),
      geojsonHF: null,
      geojsonTt2015: null,
      geojsonTt2019: null,
      hfStyleOptions: {
        color: '#232323',
        radius: 1,
      },
      toggleTT: true,
      colorBrewerArray: ['#ffffd4', '#fee391', '#fec44f', '#fe9929', '#d95f0e', '#993404'],
    };
  },
  async mounted() {
    const responseTt2015 = await fetch(`${environment.assetsURL}blog/kenya_story/data/KEN_traveltime2015.geojson`);
    if (responseTt2015.ok) {
      this.geojsonTt2015 = await responseTt2015.json();
    } else {
      console.error(`HTTP-Error: ${responseTt2015.status}`);
    }
    const responseTt2019 = await fetch(`${environment.assetsURL}blog/kenya_story/data/KEN_traveltime2019.geojson`);
    if (responseTt2019.ok) {
      this.geojsonTt2019 = await responseTt2019.json();
    } else {
      console.error(`HTTP-Error: ${responseTt2019.status}`);
    }

    const responseHF = await fetch(`${environment.assetsURL}blog/kenya_story/data/KEN_health_facilities.geojson`);
    if (responseHF.ok) {
      this.geojsonHF = await responseHF.json();
    } else {
      console.error(`HTTP-Error: ${responseHF.status}`);
    }
  },
  methods: {
    enableZoomOnClick() {
      this.map = this.$refs.healthimplicationmap.mapObject;
      this.map.on('focus', () => this.map.scrollWheelZoom.enable());
      this.map.on('blur', () => this.map.scrollWheelZoom.disable());
    },
    styleFunctionHealthFacilities() {
      return {
        color: '#000000',
        weight: 3,
        opacity: 0.65,
      };
    },
    styleFunctionTravelTime(attribute) {
      let colorCode;
      const [brew1, brew2, brew3, brew4, brew5, brew6] = this.colorBrewerArray;
      if (attribute === 1) {
        colorCode = brew1;
      } else if (attribute === 2) {
        colorCode = brew2;
      } else if (attribute === 3) {
        colorCode = brew3;
      } else if (attribute === 4) {
        colorCode = brew4;
      } else if (attribute === 5) {
        colorCode = brew5;
      } else if (attribute === 6) {
        colorCode = brew6;
      } else {
        colorCode = '#FFEDA0';
      }
      return {
        fillColor: colorCode,
        weight: 0,
        fillOpacity: 0.7,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.section-header {
  font-style: italic;
  font-weight: bold;
  font-size: 16pt;
  color: #505c66;
}

/* Leaflet */

.leaflet-fade-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: auto !important;
}

.accessibility-map-control {
  margin-bottom: 25px;
}

.accessibility-map-button {
  transition-duration: 0.2s;
  background-color: white;
  color: black;
  border: 2px solid #dccfb4;
  height: 30px;
  border-radius: 5px;
  font-weight: bold;
}

.accessibility-map-button:hover {
  background-color: #dccfb4;
  text-decoration: underline;
}

.legend-table{
  display: table;
  width: auto;
  background-color: #fff;
  border: 1px solid #666666;
  border-spacing: 5px;
  border-radius: 5px;
  line-height: 18px;
}

.legend-table-row{
  display: table-row;
  width: auto;
  clear: both;
}

.legend-table-col{
  float: left;
  display: table-column;
  width: auto;
  min-width: 30px;
  min-height: 18px;
  margin-right: 5px;
}

.accessibility-year-label div {
  display: none;
}

.accessibility-year-label .active {
  display: block;
  font-size: 16pt;
  font-weight: bold;
  margin-top: -2rem;
}
</style>
