<template>
  <div>
    <b-container>
      <div class="video-container">
        <VideoPlayer
          ref="videoPlayer"
          :sources="sources"
          @metaLoaded="playerReady"
        />
      </div>

      <Scrollama
        :offset="0.9"
        @step-progress="stepProgressHandler"
        class="slides"
      >
        <div
          class="slide"
          v-for="(slide, index) in slides"
          :key="`${slide.title}_${index}`"
          :data-title="slide.title"
          :debug="true"
        >
          <span v-if="slide.empty" />
          <span v-else>
            <p v-html="slide.content"></p>
          </span>
        </div>
      </Scrollama>
    </b-container>
  </div>
</template>

<script>
import Scrollama from 'vue-scrollama';
import environment from '../../../environment';
import VideoPlayer from '../../base/VideoPlayer.vue';

export default {
  components: {
    Scrollama,
    VideoPlayer,
  },
  name: 'artisanal-video',
  data() {
    return {
      videoDuration: null,
      sources: [
        // {
        // src: `${environment.assetsURL}/blog/
        // scrolly_telling_example/ETH_overviewMap_animation25fps.webm`,
        // type: 'video/webm',
        // },
        {
          src: `${environment.assetsURL}/blog/ethiopia_story/ETH_shed-animation12.webm`,
          type: 'video/webm',
        },
      ],
      slides: [
        {
          content: 'In areas with a high concentration of gold ore, small deposits of the precious metal are scattered over the landscape.',
        },
        {
          content: 'Over time, the soil containing trace amounts of gold <span style="color: #f1761d; font-weight: bold;">erodes</span> into rivers.',
        },
        { empty: true },
        {
          content: 'The river carries the eroded material…',
        },
        {
          content: '…until it finally accumulates as <span style="color: yellow; font-weight: bold;">alluvium</span>, where the total concentration of gold rises to a level high enough to warrant new mining.',
        },
        {
          content: 'Once someone strikes gold, prospecting miners gather in the area to begin operations.',
        },
        {
          empty: true,
        },
        {
          content: 'The search is not limited to the riverbed alone. To follow suspected veins of mineral deposits, miners also dig up farm and grazing land, and <span style="color:orange; font-weight: bold;">deforest woodlands</span>.',
        },
        {
          empty: true,
        },
        {
          content: 'This ad hoc approach puts considerable stress on the surrounding landscape and can jeopardize crop cultivation and livestock keeping for years.',
        },
        {
          empty: true,
        },
        {
          content: 'Artisanal miners mostly use simple gravity-based methods to separate heavier gold ores from other, lighter rocks. This form of mining is called alluvial mining and requires – and contaminates – a great amount of water.',
        },
        {
          empty: true,
        },
        {
          empty: true,
        },
        {
          content: 'The resulting pollution can negatively affect the health and well-being of downstream communities who may rely on this water to drink, cook, and wash – as well as to water their livestock and irrigate their fields.',
        },
        {
          content: 'Miners’ use of water also accelerates evaporation, reducing water flow. This has the knock-on effect of limiting the production of crops, which many communities rely on for income and their own consumption.',
        },
      ],
    };
  },
  methods: {
    playerReady({ duration }) {
      this.player = this.$refs.videoPlayer;
      this.videoDuration = duration;
    },
    stepProgressHandler({ index, progress }) {
      this.changeFrame(index, progress);
    },
    changeFrame(index, progress) {
      if (!this.player) {
        return;
      }
      this.playbackStepFactor = this.videoDuration / this.slides.length;
      const newFrameNumber = (index + progress) * this.playbackStepFactor;
      if (this.videoDuration < this.frameNumber) {
        this.frameNumber = this.videoDuration;
      } else {
        this.frameNumber = newFrameNumber;
      }
      this.player.playTo(this.frameNumber);
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.video-container {
  position: sticky;
  top: 10px;
}
figure {
  position: absolute;
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity 800ms;
}
figure img {
  min-width: 100%;
  min-height: 100%;
}
video.active {
  visibility: visible;
  opacity: 1;
}
.slides {
  z-index: 10;
  position: relative;
}
.slide {
  height: 100vh;
  margin: 0 auto;
  max-width: 300px;
  color: white;
}
.slide p {
  background-color: rgba(0, 0, 0, .7);
  padding: 0.7rem;
}
</style>
