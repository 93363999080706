<template>
  <div class="circle" :style="circleStyle">&nbsp;</div>
</template>
<script>

export default {
  name: 'legend-circle',
  components: {
  },
  props: {
    size: {
      required: false,
      default: 1,
      type: Number,
    },
    color: {
      required: false,
      default: 'black',
      type: String,
    },
  },
  data() {
    return {
      circleStyle: { },
    };
  },
  async mounted() {
    this.circleStyle = {
      width: `${this.size}px`,
      height: `${this.size}px`,
      background: this.color,
    };
  },
  methods: {
  },
};

</script>
<style lang="scss" scoped>
.circle {
  border-radius: 50%;
}
</style>
