<template>
  <b-container fluid class="m-0 p-0" v-bind="extraProps">
    <l-map
      class="w-100 mw-100 h-100"
      :ref="mapRef"
      :zoom="zoom"
      :center="center"
      :bounds="bounds"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @update:bounds="boundsUpdate"
      @ready="mapReady"
      @click="(event) => {
        if (clickHandler) clickHandler({ ...event, map })
      }"
    >
      <slot></slot>
    </l-map>
  </b-container>
</template>

<script>
import { latLng, DomUtil } from 'leaflet';
import { LMap } from 'vue2-leaflet';

export default {
  name: 'MapView',
  props: {
    center: {
      required: false,
      default: () => latLng(3.6214007, 39.0408266),
      type: Object,
    },
    zoom: {
      required: false,
      default: 9,
      type: Number,
    },
    bounds: {
      required: false,
    },
    extraProps: {
      required: false,
      type: Object,
      default: () => latLng(3.6214007, 39.0408266),
    },
    mapRef: {
      default: 'map',
      required: false,
      type: String,
    },
    querying: {
      default: false,
      type: Boolean,
    },
    clickHandler: {
      default: null,
      required: false,
      type: Function,
    },
  },
  watch: {
    querying() {
      if (!this.map) {
        return;
      }
      if (this.querying) {
        // eslint-disable-next-line no-underscore-dangle
        DomUtil.addClass(this.map._container, 'query-cursor-enabled');
      }
      if (!this.querying) {
        // eslint-disable-next-line no-underscore-dangle
        DomUtil.removeClass(this.map._container, 'query-cursor-enabled');
      }
    },
  },
  components: {
    LMap,
  },
  data() {
    return {
      currentCenter: latLng(0, 0),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 1,
      },
      window: {
        width: 0,
        height: 0,
      },
      map: null,
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    zoomUpdate(zoom) {
      this.$emit('zoomUpdated', zoom);
    },
    boundsUpdate(bounds) {
      this.$emit('boundsUpdated', bounds);
    },
    mapReady() {
      this.map = this.$refs[this.mapRef].mapObject;
      this.$emit('mapReady', this.map);
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>
<style scoped lang='scss'>
.leaflet-fade-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: auto !important;
}

.leaflet-container.query-cursor-enabled {
    cursor: help;
}
</style>
