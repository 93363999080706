<template>
  <b-container>
    <h2>Verification</h2>
    <p>
      You should be logged in automatically when
      your verification is successfull.
    </p>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';

import { paths } from '@/router';

const errorFields = [
  'user_id', 'timestamp', 'signature',
];

export default {
  props: [
    'user_id', 'timestamp', 'signature',
  ],
  mounted() {
    if (this.$store.getters.isAuthenticated) {
      this.$router.push(paths.profile);
    }
    this.$nextTick(() => {
      this.verifyRegistration();
    });
  },
  methods: {
    ...mapActions({ verify: 'auth/verifyRegistration', showSuccessToast: 'toasts/addSuccessToast' }),
    async verifyRegistration() {
      const payload = {
        user_id: this.user_id,
        timestamp: this.timestamp,
        signature: this.signature,
      };
      const [responseData, errors] = await this.verify({ payload, errorFields });

      if (!errors && responseData) {
        this.showSuccessToast({
          title: 'Verification Successful',
          message: 'You may now login using your username and password.',
        });
        this.$router.push(paths.login);
      }
    },
  },
};
</script>
