<template>
  <!-- eslint-disable max-len -->
  <div>
    <TwentyTwenty
      offset="0.85"
      :before="beforeImage"
      beforeLabel="2017"
      :after="afterImage"
      afterLabel="2020" />
    <p style="font-size: 10pt; margin-bottom: 0; margin-top: 0.5rem">
      Compare satellite imagery from 2017 and 2020. Vegetation is exaggerated in bright green and the dotted lines indicate areas with significant conversion to agricultural land.
      <br>Imagery: Sentinel-2
    </p>
    </div>
</template>
<script>
import TwentyTwenty from 'vue-twentytwenty';
import beforeImage from '@/assets/blog/kenya_story/images/KEN_agriExpansionGreenChg_2017.jpg';
import afterImage from '@/assets/blog/kenya_story/images/KEN_agriExpansionGreenChg_2020.jpg';

export default {
  name: 'agriculture-comparison',
  components: {
    TwentyTwenty,
  },
  data() {
    return {
      beforeImage,
      afterImage,
    };
  },
};
</script>
