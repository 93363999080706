export default {
  namespaced: true,
  state: {
    toasts: [],
    currentIndex: 0,
  },
  mutations: {
    addToast(state, { title, message, toastType }) {
      state.toasts.push({
        title, message, toastType,
      });
    },
    removeToast(state) {
      state.toasts.shift();
    },
  },
  actions: {
    addDefaultToast({ commit }, message) {
      commit('addToast', { ...message, toastType: 'default' });
    },
    addPrimaryToast({ commit }, message) {
      commit('addToast', { ...message, toastType: 'primary' });
    },
    addSecondaryToast({ commit }, message) {
      commit('addToast', { ...message, toastType: 'secondary' });
    },
    addSuccessToast({ commit }, message) {
      commit('addToast', { ...message, toastType: 'success' });
    },
    addInfoToast({ commit }, message) {
      commit('addToast', { ...message, toastType: 'info' });
    },
    addWarningToast({ commit }, message) {
      commit('addToast', { ...message, toastType: 'warning' });
    },
    addErrorToast({ commit }, message) {
      commit('addToast', { ...message, toastType: 'error' });
    },
    showNextToast({ commit, state }) {
      const toastCount = state.toasts.length;
      if (toastCount > 0) {
        const { title, message, toastType } = state.toasts[0];
        commit('removeToast');
        return { title, message, toastType };
      }
      return undefined;
    },
  },
  getters: {
    getToasts(state) {
      return state.toasts;
    },
    hasToasts(state) {
      return (state.toasts.length) > 0;
    },
  },
};
