<template>
  <div>
    <b-container>
      <div class="video-container">
        <VideoPlayer
          ref="videoPlayer"
          :sources="sources"
          @metaLoaded="playerReady"
        />
      </div>

      <Scrollama
        :offset="0.9"
        @step-progress="stepProgressHandler"
        class="slides"
      >
        <div
          class="slide"
          v-for="(slide, index) in slides"
          :key="`${slide.title}_${index}`"
          :data-title="slide.title"
          :debug="true"
        >
          <span v-if="slide.empty" />
          <span v-else>
            <p>
              {{ slide.content }}
            </p>
          </span>
        </div>
      </Scrollama>
    </b-container>
  </div>
</template>

<script>
import Scrollama from 'vue-scrollama';
import environment from '../../../environment';
import VideoPlayer from '../../base/VideoPlayer.vue';

export default {
  components: {
    Scrollama,
    VideoPlayer,
  },
  name: 'overview-video',
  data() {
    return {
      videoDuration: null,
      sources: [
        // {
        // src: `${environment.assetsURL}/blog/
        // scrolly_telling_example/ETH_overviewMap_animation25fps.webm`,
        // type: 'video/webm',
        // },
        {
          src: `${environment.assetsURL}/blog/ethiopia_story/ETH_overviewMap_animation12.webm`,
          type: 'video/webm',
        },
      ],
      slides: [
        {
          empty: true,
        },
        {
          content: 'Ethiopia is well-endowed with a broad range of mineral resources and most extraction occurs in the following three regions.',
        },
        {
          empty: true,
        },
        {
          content: 'In the northern belt, gold is the predominant metallic mineral. Potash and salt deposits also exist, and have been exploited by several industrial operations. ',
        },
        {
          empty: true,
        },
        {
          content: 'In the western and southwestern belt, there are gold deposits, but also platinum, iron, and base metals.',
        },
        {
          empty: true,
        },
        {
          content: 'In the southern belt, the main known mineral deposits are gold, nickel, and tantalum.',
        },
      ],
    };
  },
  methods: {
    playerReady({ duration }) {
      this.player = this.$refs.videoPlayer;
      this.videoDuration = duration;
    },
    stepProgressHandler({ index, progress }) {
      this.changeFrame(index, progress);
    },
    changeFrame(index, progress) {
      if (!this.player) {
        return;
      }
      this.playbackStepFactor = this.videoDuration / this.slides.length;
      const newFrameNumber = (index + progress) * this.playbackStepFactor;
      if (this.videoDuration < this.frameNumber) {
        this.frameNumber = this.videoDuration;
      } else {
        this.frameNumber = newFrameNumber;
      }
      this.player.playTo(this.frameNumber);
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.video-container {
  position: sticky;
  top: 10px;
}
figure {
  position: absolute;
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity 800ms;
}
figure img {
  min-width: 100%;
  min-height: 100%;
}
video.active {
  visibility: visible;
  opacity: 1;
}
.slides {
  z-index: 10;
  position: relative;
}
.slide {
  height: 100vh;
  margin: 0 auto;
  max-width: 300px;
  color: white;
}
.slide p {
  background-color: rgba(0, 0, 0, .7);
  padding: 0.7rem;
}
</style>
