<template>
  <span></span>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  mounted() {
    this.loadProfile();
  },
  methods: {
    ...mapActions({
      getProfile: 'auth/getProfile',
    }),
    async loadProfile() {
      await this.getProfile({ ignoreErrors: true });
    },
  },
};
</script>
