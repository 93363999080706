// https://www.django-rest-framework.org/api-guide/exceptions/#exception-handling-in-rest-framework-views

const errorFields = ['detail', 'description', 'status_code', 'non_field_errors'];

const handleErrors = (errorResponse, fieldNames) => {
  const errorMessage = errorResponse.response.data;

  let errorNames = [...errorFields];
  if (fieldNames !== undefined) {
    errorNames = [...fieldNames];
  }

  const errors = [];
  errorNames.forEach((err) => {
    if (errorMessage[err]) {
      errors.push({ title: err, message: errorMessage[err] });
    }
  });
  if (errors.length === 0) {
    errorFields.forEach((err) => {
      if (errorMessage[err]) {
        errors.push({ title: err, message: errorMessage[err] });
      }
    });
  }
  if (errors.length === 0) {
    errors.push({ title: 'Unknown Error', message: 'Unknown Error occurred. Please retry or reload page and try again.' });
  }
  return errors;
};

const handleFieldErrors = (response, fieldNames) => handleErrors(response, fieldNames);

export { handleErrors, handleFieldErrors };
