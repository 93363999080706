<template>
  <div id="app" class="min-vh-100">
    <b-container>
      <b-breadcrumb
        class="bg-light"
        :items="breadcrumbItems"
      ></b-breadcrumb>
    </b-container>
    <base-toast />
    <base-data-loader />
    <transition name="fade">
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
    <OmnipresentFooter />
  </div>
</template>

<script>
import BaseToast from '@/components/base/BaseToast.vue';
import BaseDataLoader from '@/components/base/BaseDataLoader.vue';
import OmnipresentFooter from '@/components/base/OmnipresentFooter.vue';

export default {
  name: 'App',
  components: {
    BaseToast,
    BaseDataLoader,
    OmnipresentFooter,
  },
  computed: {
    // warning: this only works for navigation depth <= 2
    breadcrumbItems() {
      const { path, meta } = this.$route;
      if (path.length === 1) {
        return [
          {
            text: meta.verbose,
          },
        ];
      }
      return [
        {
          text: 'Home',
          to: '/',
        },
        {
          text: meta.verbose,
        },
      ];
    },
  },
};
</script>

<style lang="scss">

/* needed for map sizing */
.main-content-height {
  height: calc(100vh - 7rem);
}

#app {
  min-width: 320px;

  > footer {
    min-height: 2rem;
  }
}
</style>
<style lang="scss" scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
